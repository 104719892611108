import { SVGProps } from 'react'
function Revert(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={13}
      height={13}
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M6.553.436a6.244 6.244 0 00-6.24 6.248c0 2 .94 3.782 2.403 4.926l-.586.75a.125.125 0 00.098.201l2.61-.012c.081 0 .14-.077.12-.155l-.617-2.536a.125.125 0 00-.22-.047l-.636.814a4.98 4.98 0 01-1.922-3.94A4.979 4.979 0 013.027 3.15a4.979 4.979 0 013.536-1.465 4.979 4.979 0 013.534 1.464 4.98 4.98 0 011.464 3.534 4.979 4.979 0 01-1.83 3.866.124.124 0 00-.018.173l.615.79a.127.127 0 00.179.02A6.249 6.249 0 006.554.436z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Revert
