import { SVGProps } from 'react'
function SquaredPlay(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={13}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M27.9375 0.9375H2.0625C1.44023 0.9375 0.9375 1.44023 0.9375 2.0625V27.9375C0.9375 28.5598 1.44023 29.0625 2.0625 29.0625H27.9375C28.5598 29.0625 29.0625 28.5598 29.0625 27.9375V2.0625C29.0625 1.44023 28.5598 0.9375 27.9375 0.9375ZM19.5598 15.3094L12.5496 20.8219C12.2895 21.0258 11.9062 20.843 11.9062 20.5125V9.49102C11.9062 9.16055 12.2895 8.97422 12.5496 9.18164L19.5598 14.6906C19.6066 14.7275 19.6444 14.7745 19.6704 14.828C19.6964 14.8816 19.7099 14.9404 19.7099 15C19.7099 15.0596 19.6964 15.1184 19.6704 15.1719C19.6444 15.2255 19.6066 15.2725 19.5598 15.3094Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SquaredPlay
