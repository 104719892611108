import Rox from './instance'

if (!Rox.containerCache) {
  Rox.containerCache = {
    enableNewReports: new Rox.Flag(false),
    enableOldReports: new Rox.Flag(false),
    enableNewReportsDashboard: new Rox.Flag(false),
  }
}
export default Rox.containerCache
