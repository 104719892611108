import { SVGProps } from 'react'
function ParagraphMode(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={16}
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M1.667 2.965h8.857a.143.143 0 00.143-.142v-1a.143.143 0 00-.143-.143H1.667a.143.143 0 00-.143.143v1c0 .078.064.142.143.142zm0 7.572h8.857a.143.143 0 00.143-.143v-1a.143.143 0 00-.143-.143H1.667a.143.143 0 00-.143.143v1c0 .079.064.143.143.143zm14 2.5h-14a.143.143 0 00-.143.143v1c0 .078.064.143.143.143h14a.143.143 0 00.143-.143v-1a.143.143 0 00-.143-.143zm0-7.572h-14a.143.143 0 00-.143.143v1c0 .079.064.143.143.143h14a.143.143 0 00.143-.143v-1a.143.143 0 00-.143-.143z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ParagraphMode
