import { styled } from '@invisible/ui/themes'
import { FC } from 'react'
import { Flex, FlexProps } from 'rebass'

import { Logo } from './logo'
import { DropMenu, Left, Menu, MenuItem, Right } from './Menu'
import { ProfileMenu } from './profile-menu'
import { StatusBox, StatusBoxes, StatusBoxProps } from './StatusBox'

interface ITopbar {
  fixed?: boolean
}

const _Topbar = styled(Flex as FC<FlexProps>).attrs({
  'data-cy': 'topbar',
})<ITopbar>`
  background-color: black;
  display: flex;
  height: 50px;
  line-height: 1;
  & {
    color: white;
  }

  ${(props) =>
    props.fixed &&
    `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  `}
`

const Topbar = Object.assign(_Topbar, {
  DropMenu,
  Left,
  Right,
  Menu,
  MenuItem,
  ProfileMenu,
  Logo,
  StatusBoxes,
  StatusBox,
})

export type { StatusBoxProps }

export { Topbar }
