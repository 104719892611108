import { SVGProps } from 'react'
function RegularStar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={58}
      height={56}
      viewBox='0 0 58 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M26.795 2.364c.599-1.843 3.206-1.843 3.804 0L36 18.98a2 2 0 001.901 1.382h17.471c1.938 0 2.743 2.479 1.176 3.618L42.413 34.249a2 2 0 00-.727 2.236L47.085 53.1c.598 1.842-1.51 3.375-3.078 2.236l-14.134-10.27a2 2 0 00-2.351 0l-14.135 10.27c-1.567 1.139-3.676-.394-3.077-2.236l5.399-16.616a2 2 0 00-.727-2.236L.848 23.979c-1.568-1.138-.762-3.617 1.175-3.617h17.471a2 2 0 001.902-1.382l5.4-16.616z'
        fill='currentColor'
      />
    </svg>
  )
}

export default RegularStar
