import { SVGProps } from 'react'
function CheckCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M32.7655 16.5454H30.567C30.0889 16.5454 29.6342 16.7751 29.353 17.1688L21.9842 27.3876L18.6467 22.7563C18.3655 22.3673 17.9155 22.1329 17.4327 22.1329H15.2342C14.9295 22.1329 14.7514 22.4798 14.9295 22.7282L20.7702 30.8282C20.9082 31.0208 21.09 31.1777 21.3008 31.286C21.5115 31.3942 21.745 31.4507 21.9819 31.4507C22.2188 31.4507 22.4523 31.3942 22.663 31.286C22.8737 31.1777 23.0556 31.0208 23.1936 30.8282L33.0655 17.1407C33.2483 16.8923 33.0702 16.5454 32.7655 16.5454V16.5454Z'
        fill='currentColor'
      />
      <path
        d='M24 3C12.4031 3 3 12.4031 3 24C3 35.5969 12.4031 45 24 45C35.5969 45 45 35.5969 45 24C45 12.4031 35.5969 3 24 3ZM24 41.4375C14.3719 41.4375 6.5625 33.6281 6.5625 24C6.5625 14.3719 14.3719 6.5625 24 6.5625C33.6281 6.5625 41.4375 14.3719 41.4375 24C41.4375 33.6281 33.6281 41.4375 24 41.4375Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CheckCircle
