import { ForwardedRef, forwardRef, RefObject, SVGProps } from 'react'

const Hamburger = forwardRef((props: SVGProps<SVGSVGElement>, ref: ForwardedRef<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}>
    <path
      d='M7 2.984a1 1 0 102 0 1 1 0 00-2 0zm0 5a1 1 0 102 0 1 1 0 00-2 0zm0 5a1 1 0 102 0 1 1 0 00-2 0z'
      fill='currentColor'
    />
  </svg>
))

export default Hamburger
