// When adding an icon, make sure to add it to import, the Icons constant, and export
// We have to do it this way to preserve types
//
// If we do just `export { default as AccessRecordIcon } from '../assets/AccessRecord' etc
// it breaks because we can no longer get an icon by its string name when importing

import { keys } from 'lodash/fp'

import { default as AccessRecordIcon } from '../assets/AccessRecord'
import { default as ActivitiesIcon } from '../assets/Activities'
import { default as AddRowIcon } from '../assets/AddRow'
import { default as AimIcon } from '../assets/Aim'
import { default as AndIcon } from '../assets/And'
import { default as ArrowBackIcon } from '../assets/ArrowBack'
import { default as ArrowDownIcon } from '../assets/ArrowDown'
import { default as ArrowIndicatorIcon } from '../assets/ArrowIndicator'
import { default as ArrowLeftIcon } from '../assets/ArrowLeft'
import { default as ArrowRightIcon } from '../assets/ArrowRight'
import { default as ArrowUndoIcon } from '../assets/ArrowUndo'
import { default as AttachFileIcon } from '../assets/AttachFile'
import { default as BankIcon } from '../assets/Bank'
import { default as BellIcon } from '../assets/Bell'
import { default as BellNoFillIcon } from '../assets/BellNoFill'
import { default as BranchIcon } from '../assets/Branch'
import { default as BuildIcon } from '../assets/Build'
import { default as CalendarIcon } from '../assets/Calendar'
import { default as CalendarFullIcon } from '../assets/CalendarFull'
import { default as CaretDownIcon } from '../assets/CaretDown'
import { default as CaretDown2Icon } from '../assets/CaretDown2'
import { default as CaretUpIcon } from '../assets/CaretUp'
import { default as CategoryIcon } from '../assets/Category'
import { default as CheckIcon } from '../assets/Check'
import { default as CheckCircleIcon } from '../assets/CheckCircle'
import { default as CheckMarkIcon } from '../assets/CheckMark'
import { default as CheckSquareIcon } from '../assets/CheckSquare'
import { default as CircledDollarIcon } from '../assets/CircledDollar'
import { default as CircledExclamationIcon } from '../assets/CircledExclamation'
import { default as CircledPauseIcon } from '../assets/CircledPause'
import { default as CircledPlayIcon } from '../assets/CircledPlay'
import { default as CleanDataIcon } from '../assets/CleanData'
import { default as ClickOnIcon } from '../assets/ClickOn'
import { default as ClockIcon } from '../assets/Clock'
import { default as ClockFilledIcon } from '../assets/ClockFilled'
import { default as CloseIcon } from '../assets/Close'
import { default as CloseCircleIcon } from '../assets/CloseCircle'
import { default as CloseEjectIcon } from '../assets/CloseEject'
import { default as CloseSquareIcon } from '../assets/CloseSquare'
import { default as CloseXIcon } from '../assets/CloseX'
import { default as CoffeeMugIcon } from '../assets/CoffeeMug'
import { default as CommentIcon } from '../assets/Comment'
import { default as ConditionalIcon } from '../assets/Conditional'
import { default as CopyIcon } from '../assets/Copy'
import { default as CopyAllTextIcon } from '../assets/CopyAllText'
import { default as CopyPasteIcon } from '../assets/CopyPaste'
import { default as CreateIcon } from '../assets/Create'
import { default as CrownIcon } from '../assets/Crown'
import { default as CycleIcon } from '../assets/Cycle'
import { default as DangerOutlineIcon } from '../assets/DangerOutline'
import { default as DataSourceIcon } from '../assets/DataSource'
import { default as DataTransformIcon } from '../assets/DataTransform'
import { default as DateSeparatorIcon } from '../assets/DateSeparator'
import { default as DeleteIcon } from '../assets/Delete'
import { default as Delete2Icon } from '../assets/Delete2'
import { default as DiamondIcon } from '../assets/Diamond'
import { default as DirectionIconDown } from '../assets/DirectionIconDown'
import { default as DirectionIconUp } from '../assets/DirectionIconUp'
import { default as DiscoveryIcon } from '../assets/Discovery'
import { default as DividerIcon } from '../assets/Divider'
import { default as DocCheckIcon } from '../assets/DocCheck'
import { default as DocCSVIcon } from '../assets/DocCSV'
import { default as DocEditIcon } from '../assets/DocEdit'
import { default as DocInputIcon } from '../assets/DocInput'
import { default as DocLinkIcon } from '../assets/DocLink'
import { default as DocQAIcon } from '../assets/DocQA'
import { default as DocumentIcon } from '../assets/Document'
import { default as DocUploadIcon } from '../assets/DocUpload'
import { default as DogIcon } from '../assets/Dog'
import { default as DownloadIcon } from '../assets/Download'
import { default as DownSquareIcon } from '../assets/DownSquare'
import { default as DropdownIcon } from '../assets/Dropdown'
import { default as EditIcon } from '../assets/Edit'
import { default as EditDataIcon } from '../assets/EditData'
import { default as EmailComIcon } from '../assets/EmailCom'
import { default as EmojiIcon } from '../assets/Emoji'
import { default as EnvelopeIcon } from '../assets/Envelope'
import { default as EnvelopeEditIcon } from '../assets/EnvelopeEdit'
import { default as EnvelopePlusIcon } from '../assets/EnvelopePlus'
import { default as EnvelopeSearchIcon } from '../assets/EnvelopeSearch'
import { default as ExportIcon } from '../assets/Export'
import { default as ExportToFileIcon } from '../assets/ExportToFile'
import { default as EyeCancelledIcon } from '../assets/EyeCancelled'
import { default as EyeClosedIcon } from '../assets/EyeClosed'
import { default as EyeOpenedIcon } from '../assets/EyeOpened'
import { default as FieldNumberIcon } from '../assets/FieldNumber'
import { default as FileIcon } from '../assets/File'
import { default as FileImageIcon } from '../assets/FileImage'
import { default as FilledCheckIcon } from '../assets/FilledCheck'
import { default as FilledCircledDashIcon } from '../assets/FilledCircledDash'
import { default as FilledFilterIcon } from '../assets/FilledFilter'
import { default as FilterIcon } from '../assets/Filter'
import { default as FiltersIcon } from '../assets/Filters'
import { default as FireIcon } from '../assets/Fire'
import { default as FireFullIcon } from '../assets/FireFull'
import { default as FlagIcon } from '../assets/Flag'
import { default as FlowIcon } from '../assets/Flow'
import { default as FolderIcon } from '../assets/Folder'
import { default as FontColorIcon } from '../assets/FontColor'
import { default as FormatIcon } from '../assets/Format'
import { default as FormatSheetIcon } from '../assets/FormatSheet'
import { default as FrameIcon } from '../assets/Frame'
import { default as FullScreenIcon } from '../assets/FullScreen'
import { default as GiftBoxIcon } from '../assets/GiftBox'
import { default as GlobalIcon } from '../assets/Global'
import { default as GoogleIcon } from '../assets/Google'
import { default as GoogleMonoColorIcon } from '../assets/GoogleMonoColor'
import { default as GoogleSheetIcon } from '../assets/GoogleSheet'
import { default as GoogleSheetEditIcon } from '../assets/GoogleSheetEdit'
import { default as GridIcon } from '../assets/Grid'
import { default as HamburgerIcon } from '../assets/Hamburger'
import { default as HashtagIcon } from '../assets/Hashtag'
import { default as HeartOffIcon } from '../assets/HeartOff'
import { default as HeartOnIcon } from '../assets/HeartOn'
import { default as HeartSkeleton } from '../assets/HeartVector'
import { default as HelpIcon } from '../assets/Help'
import { default as HistoryIcon } from '../assets/History'
import { default as HomeIcon } from '../assets/Home'
import { default as HouseIcon } from '../assets/House'
import { default as IDCardIcon } from '../assets/IDCard'
import { default as IncompleteIcon } from '../assets/Incomplete'
import { default as InfoIcon } from '../assets/Info'
import { default as InfoOutlineIcon } from '../assets/InfoOutline'
import { default as InputSheetIcon } from '../assets/InputSheet'
import { default as InputVarIcon } from '../assets/InputVar'
import { default as InstallAppIcon } from '../assets/InstallApp'
import { default as InstructionsIcon } from '../assets/Instructions'
import { default as InvisibleLogoWithTextIcon } from '../assets/InvisibleLogoWithText'
import { default as JoinDataIcon } from '../assets/JoinData'
import { default as LaunchIcon } from '../assets/Launch'
import { default as LeftArrowIcon } from '../assets/LeftArrow'
import { default as LibraryIcon } from '../assets/Library'
import { default as LinkIcon } from '../assets/Link'
import { default as LinkedInIcon } from '../assets/LinkedIn'
import { default as ListIcon } from '../assets/List'
import { default as LoadMenuIcon } from '../assets/LoadMenu'
import { default as LockIcon } from '../assets/Lock'
import { default as LockPlusIcon } from '../assets/LockPlus'
import { default as LogicFlowIcon } from '../assets/LogicFlow'
import { default as LoginNormalIcon } from '../assets/LoginNormal'
import { default as LoginSecureIcon } from '../assets/LoginSecure'
import { default as InvisibleIcon } from '../assets/Logo'
import { default as LogoIcon } from '../assets/Logo'
import { default as LogoutIcon } from '../assets/Logout'
import { default as LottyIcon } from '../assets/Lotty'
import { default as ManticoreInvisibleLogoIcon } from '../assets/ManticoreInvisibleLogo'
import { default as MeetingIcon } from '../assets/Meeting'
import { default as MegaphoneIcon } from '../assets/Megaphone'
import { default as MenuIcon } from '../assets/Menu'
import { default as MessageIcon } from '../assets/Message'
import { default as MessageFilledIcon } from '../assets/MessageFilled'
import { default as MessagesIcon } from '../assets/Messages'
import { default as NoFillArrowDownIcon } from '../assets/NoFillArrowDown'
import { default as OutputVarIcon } from '../assets/OutputVar'
import { default as PanelIcon } from '../assets/Panel'
import { default as ParagraphModeIcon } from '../assets/ParagraphMode'
import { default as ParseCsvIcon } from '../assets/ParseCsv'
import { default as PauseIcon } from '../assets/Pause'
import { default as PeopleIcon } from '../assets/People'
import { default as PersonIcon } from '../assets/Person'
import { default as PersonSearchIcon } from '../assets/PersonSearch'
import { default as PlayIcon } from '../assets/Play'
import { default as PlusIcon } from '../assets/Plus'
import { default as PlusCircleIcon } from '../assets/PlusCircle'
import { default as PlusPersonIcon } from '../assets/PlusPerson'
import { default as PortalDashboardIcon } from '../assets/PortalDashboard'
import { default as PortalLaunchpadIcon } from '../assets/PortalLaunchpad'
import { default as PortalProcessesIcon } from '../assets/PortalProcesses'
import { default as ProcessStoreIcon } from '../assets/ProcessStore'
import { default as ProductRefIcon } from '../assets/ProductRef'
import { default as ProgressIcon } from '../assets/Progress'
import { default as RadioButtonIcon } from '../assets/RadioButton'
import { default as RechargeDividerIcon } from '../assets/RechargeDivider'
import { default as RegularStarIcon } from '../assets/RegularStar'
import { default as RenameColumnIcon } from '../assets/RenameColumn'
import { default as ReportsIcon } from '../assets/Reports'
import { default as RevertIcon } from '../assets/Revert'
import { default as RichTextIcon } from '../assets/RichText'
import { default as RightArrowIcon } from '../assets/RightArrow'
import { default as RobinhoodIcon } from '../assets/Robinhood'
import { default as RobotIcon } from '../assets/Robot'
import { default as RobotHeadIcon } from '../assets/RobotHead'
import { default as RotateLeftIcon } from '../assets/RotateLeft'
import { default as RotateRightIcon } from '../assets/RotateRight'
import { default as SaveIcon } from '../assets/Save'
import { default as SavingIcon } from '../assets/Saving'
import { default as ScissorsIcon } from '../assets/Scissors'
import { default as ScrapingIcon } from '../assets/Scraping'
import { default as ScreenshotIcon } from '../assets/Screenshot'
import { default as SearchIcon } from '../assets/Search'
import { default as SearchInboxIcon } from '../assets/SearchInbox'
import { default as SecurityIcon } from '../assets/Security'
import { default as SendIcon } from '../assets/SendIcon'
import { default as SettingsIcon } from '../assets/Settings'
import { default as SettingsFullIcon } from '../assets/SettingsFull'
import { default as SidebarRetractIcon } from '../assets/SidebarRetract'
import { default as SignOutIcon } from '../assets/SignOut'
import { default as SlackIcon } from '../assets/Slack'
import { default as SmallBackArrowIcon } from '../assets/SmallBackArrow'
import { default as SmallRobotIcon } from '../assets/SmallRobot'
import { default as SmileyStarIcon } from '../assets/SmileyStar'
import { default as SnoozeIcon } from '../assets/Snooze'
import { default as SortArrowsIcon } from '../assets/SortArrows'
import { default as SortDataIcon } from '../assets/SortData'
import { default as SortIconDown } from '../assets/SortIconDown'
import { default as SortIconUp } from '../assets/SortIconUp'
import { default as SortRemovedIcon } from '../assets/SortRemovedIcon'
import { default as SourceInfoIcon } from '../assets/SourceInfo'
import { default as SquaredPlayIcon } from '../assets/SquaredPlay'
import { default as SquaredUpArrowIcon } from '../assets/SquaredUpArrow'
import { default as StarIcon } from '../assets/Star'
import { default as StepTemplateIcon } from '../assets/StepTemplate'
import { default as SumIcon } from '../assets/Sum'
import { default as TableModeIcon } from '../assets/TableMode'
import { default as TagsOutlinedIcon } from '../assets/TagsOutlined'
import { default as TaskTrainingIcon } from '../assets/TaskTraining'
import { default as TeamsIcon } from '../assets/Teams'
import { default as TelephoneIcon } from '../assets/Telephone'
import { default as TextModeIcon } from '../assets/TextMode'
import { default as ToastCancelIcon } from '../assets/ToastCancel'
import { default as ToastErrorIcon } from '../assets/ToastError'
import { default as ToastInfoIcon } from '../assets/ToastInfo'
import { default as ToastSuccessIcon } from '../assets/ToastSuccess'
import { default as ToastTriangleIcon } from '../assets/ToastTriangle'
import { default as ToggleAnnotationsIcon } from '../assets/ToggleAnnotations'
import { default as TorguardIcon } from '../assets/Torguard'
import { default as TrainingIcon } from '../assets/Training'
import { default as TrashIcon } from '../assets/Trash'
import { default as TrophyIcon } from '../assets/Trophy'
import { default as UploadIcon } from '../assets/Upload'
import { default as UrlIcon } from '../assets/Url'
import { default as UserSwitchIcon } from '../assets/UserSwitch'
import { default as ValidateIcon } from '../assets/Validate'
import { default as VideoIcon } from '../assets/Video'
import { default as VideoPlainIcon } from '../assets/VideoPlain'
import { default as VPNIcon } from '../assets/Vpn'
import { default as WebIcon } from '../assets/Web'
import { default as WordModeIcon } from '../assets/WordMode'
import { default as WorkIcon } from '../assets/Work'
import { default as ZoomInIcon } from '../assets/ZoomIn'
import { default as ZoomOutIcon } from '../assets/ZoomOut'

const Icons = {
  AccessRecordIcon,
  ActivitiesIcon,
  AddRowIcon,
  AimIcon,
  AndIcon,
  ArrowBackIcon,
  ArrowDownIcon,
  ArrowIndicatorIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  AttachFileIcon,
  ArrowUndoIcon,
  BankIcon,
  BellIcon,
  BellNoFillIcon,
  BranchIcon,
  BuildIcon,
  CalendarIcon,
  CalendarFullIcon,
  CaretDownIcon,
  CaretDown2Icon,
  CaretUpIcon,
  CategoryIcon,
  CheckIcon,
  CheckCircleIcon,
  CheckMarkIcon,
  CheckSquareIcon,
  CircledDollarIcon,
  CircledExclamationIcon,
  CircledPauseIcon,
  CircledPlayIcon,
  CleanDataIcon,
  ClickOnIcon,
  ClockIcon,
  ClockFilledIcon,
  CloseIcon,
  CloseCircleIcon,
  CloseEjectIcon,
  CloseSquareIcon,
  CloseXIcon,
  CoffeeMugIcon,
  CommentIcon,
  ConditionalIcon,
  CopyIcon,
  CopyAllTextIcon,
  CopyPasteIcon,
  CreateIcon,
  CrownIcon,
  CycleIcon,
  DangerOutlineIcon,
  DataSourceIcon,
  DataTransformIcon,
  DateSeparatorIcon,
  DeleteIcon,
  Delete2Icon,
  DiamondIcon,
  DirectionIconUp,
  DirectionIconDown,
  DividerIcon,
  DiscoveryIcon,
  DocCheckIcon,
  DocCSVIcon,
  DocEditIcon,
  NoFillArrowDownIcon,
  DocInputIcon,
  DocLinkIcon,
  DocQAIcon,
  DocumentIcon,
  DocUploadIcon,
  DogIcon,
  DownloadIcon,
  DownSquareIcon,
  DropdownIcon,
  EditIcon,
  EditDataIcon,
  EmailComIcon,
  EmojiIcon,
  EnvelopeIcon,
  EnvelopeEditIcon,
  EnvelopePlusIcon,
  EnvelopeSearchIcon,
  ExportIcon,
  ExportToFileIcon,
  EyeCancelledIcon,
  EyeClosedIcon,
  EyeOpenedIcon,
  FieldNumberIcon,
  FileIcon,
  FileImageIcon,
  FilledCheckIcon,
  FilledCircledDashIcon,
  FilledFilterIcon,
  FilterIcon,
  FiltersIcon,
  FireIcon,
  FireFullIcon,
  FlagIcon,
  FlowIcon,
  FolderIcon,
  FontColorIcon,
  FormatIcon,
  FormatSheetIcon,
  FrameIcon,
  FullScreenIcon,
  GiftBoxIcon,
  GlobalIcon,
  GoogleMonoColorIcon,
  GoogleIcon,
  GoogleSheetIcon,
  GoogleSheetEditIcon,
  GridIcon,
  HamburgerIcon,
  HashtagIcon,
  HeartOffIcon,
  HeartOnIcon,
  HelpIcon,
  HistoryIcon,
  HomeIcon,
  HouseIcon,
  IDCardIcon,
  IncompleteIcon,
  InfoIcon,
  HeartSkeleton,
  InfoOutlineIcon,
  InputSheetIcon,
  InputVarIcon,
  InstallAppIcon,
  InstructionsIcon,
  InvisibleLogoWithTextIcon,
  JoinDataIcon,
  LeftArrowIcon,
  LibraryIcon,
  LinkedInIcon,
  LinkIcon,
  ListIcon,
  LaunchIcon,
  LoadMenuIcon,
  LockIcon,
  LockPlusIcon,
  LogicFlowIcon,
  LoginNormalIcon,
  LoginSecureIcon,
  InvisibleIcon,
  LogoIcon,
  LogoutIcon,
  LottyIcon,
  ManticoreInvisibleLogoIcon,
  MeetingIcon,
  MegaphoneIcon,
  MenuIcon,
  MessageIcon,
  MessagesIcon,
  MessageFilledIcon,
  OutputVarIcon,
  PanelIcon,
  ParagraphModeIcon,
  ParseCsvIcon,
  PauseIcon,
  PeopleIcon,
  PersonIcon,
  PersonSearchIcon,
  PlayIcon,
  PlusIcon,
  PlusCircleIcon,
  PlusPersonIcon,
  PortalDashboardIcon,
  PortalLaunchpadIcon,
  PortalProcessesIcon,
  ProcessStoreIcon,
  ProductRefIcon,
  ProgressIcon,
  RadioButtonIcon,
  RechargeDividerIcon,
  RegularStarIcon,
  RenameColumnIcon,
  ReportsIcon,
  RevertIcon,
  RichTextIcon,
  RightArrowIcon,
  RobinhoodIcon,
  RobotIcon,
  RobotHeadIcon,
  RotateLeftIcon,
  RotateRightIcon,
  SaveIcon,
  SavingIcon,
  ScissorsIcon,
  ScrapingIcon,
  ScreenshotIcon,
  SearchIcon,
  SearchInboxIcon,
  SecurityIcon,
  SendIcon,
  SettingsIcon,
  SettingsFullIcon,
  SidebarRetractIcon,
  SignOutIcon,
  SlackIcon,
  SmallBackArrowIcon,
  SmallRobotIcon,
  SmileyStarIcon,
  SnoozeIcon,
  SortArrowsIcon,
  SortIconDown,
  SortIconUp,
  SortRemovedIcon,
  SortDataIcon,
  SquaredPlayIcon,
  SourceInfoIcon,
  SquaredUpArrowIcon,
  StarIcon,
  StepTemplateIcon,
  SumIcon,
  TableModeIcon,
  TagsOutlinedIcon,
  TelephoneIcon,
  TextModeIcon,
  ToastCancelIcon,
  ToastErrorIcon,
  ToastInfoIcon,
  ToastSuccessIcon,
  ToastTriangleIcon,
  ToggleAnnotationsIcon,
  TorguardIcon,
  TaskTrainingIcon,
  TeamsIcon,
  TrainingIcon,
  TrashIcon,
  TrophyIcon,
  UploadIcon,
  UrlIcon,
  UserSwitchIcon,
  ValidateIcon,
  VideoIcon,
  VideoPlainIcon,
  VPNIcon,
  WebIcon,
  WordModeIcon,
  WorkIcon,
  ZoomInIcon,
  ZoomOutIcon,
} as const

type TIconName = keyof typeof Icons
type TIcon = typeof Icons[TIconName]

const IconNames = keys(Icons) as TIconName[]
const isIconName = (s: unknown): s is TIconName =>
  typeof s === 'string' && IconNames.includes(s as TIconName)
const getIcon = (s: TIconName): TIcon => Icons[s]

export type { TIcon, TIconName }

export {
  AccessRecordIcon,
  ActivitiesIcon,
  AddRowIcon,
  AimIcon,
  AndIcon,
  ArrowBackIcon,
  ArrowDownIcon,
  ArrowIndicatorIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUndoIcon,
  AttachFileIcon,
  BankIcon,
  BellIcon,
  BellNoFillIcon,
  BranchIcon,
  BuildIcon,
  CalendarFullIcon,
  CalendarIcon,
  CaretDown2Icon,
  CaretDownIcon,
  CaretUpIcon,
  CategoryIcon,
  CheckCircleIcon,
  CheckIcon,
  CheckMarkIcon,
  CheckSquareIcon,
  CircledDollarIcon,
  CircledExclamationIcon,
  CircledPauseIcon,
  CircledPlayIcon,
  CleanDataIcon,
  ClickOnIcon,
  ClockFilledIcon,
  ClockIcon,
  CloseCircleIcon,
  CloseEjectIcon,
  CloseIcon,
  CloseSquareIcon,
  CloseXIcon,
  CoffeeMugIcon,
  CommentIcon,
  ConditionalIcon,
  CopyAllTextIcon,
  CopyIcon,
  CopyPasteIcon,
  CreateIcon,
  CrownIcon,
  CycleIcon,
  DangerOutlineIcon,
  DataSourceIcon,
  DataTransformIcon,
  DateSeparatorIcon,
  Delete2Icon,
  DeleteIcon,
  DiamondIcon,
  DirectionIconDown,
  DirectionIconUp,
  DiscoveryIcon,
  DividerIcon,
  DocCheckIcon,
  DocCSVIcon,
  DocEditIcon,
  DocInputIcon,
  DocLinkIcon,
  DocQAIcon,
  DocumentIcon,
  DocUploadIcon,
  DogIcon,
  DownloadIcon,
  DownSquareIcon,
  DropdownIcon,
  EditDataIcon,
  EditIcon,
  EmailComIcon,
  EmojiIcon,
  EnvelopeEditIcon,
  EnvelopeIcon,
  EnvelopePlusIcon,
  EnvelopeSearchIcon,
  ExportIcon,
  ExportToFileIcon,
  EyeCancelledIcon,
  EyeClosedIcon,
  EyeOpenedIcon,
  FieldNumberIcon,
  FileIcon,
  FileImageIcon,
  FilledCheckIcon,
  FilledCircledDashIcon,
  FilledFilterIcon,
  FilterIcon,
  FiltersIcon,
  FireFullIcon,
  FireIcon,
  FlagIcon,
  FlowIcon,
  FolderIcon,
  FontColorIcon,
  FormatIcon,
  FormatSheetIcon,
  FrameIcon,
  FullScreenIcon,
  getIcon,
  GiftBoxIcon,
  GlobalIcon,
  GoogleIcon,
  GoogleMonoColorIcon,
  GoogleSheetEditIcon,
  GoogleSheetIcon,
  GridIcon,
  HamburgerIcon,
  HashtagIcon,
  HeartOffIcon,
  HeartOnIcon,
  HeartSkeleton,
  HelpIcon,
  HistoryIcon,
  HomeIcon,
  HouseIcon,
  IconNames,
  Icons,
  IDCardIcon,
  IncompleteIcon,
  InfoIcon,
  InfoOutlineIcon,
  InputSheetIcon,
  InputVarIcon,
  InstallAppIcon,
  InstructionsIcon,
  InvisibleIcon,
  InvisibleLogoWithTextIcon,
  isIconName,
  JoinDataIcon,
  LaunchIcon,
  LeftArrowIcon,
  LibraryIcon,
  LinkedInIcon,
  LinkIcon,
  ListIcon,
  LoadMenuIcon,
  LockIcon,
  LockPlusIcon,
  LogicFlowIcon,
  LoginNormalIcon,
  LoginSecureIcon,
  LogoIcon,
  LogoutIcon,
  LottyIcon,
  ManticoreInvisibleLogoIcon,
  MeetingIcon,
  MegaphoneIcon,
  MenuIcon,
  MessageFilledIcon,
  MessageIcon,
  MessagesIcon,
  NoFillArrowDownIcon,
  OutputVarIcon,
  PanelIcon,
  ParagraphModeIcon,
  ParseCsvIcon,
  PauseIcon,
  PeopleIcon,
  PersonIcon,
  PersonSearchIcon,
  PlayIcon,
  PlusCircleIcon,
  PlusIcon,
  PlusPersonIcon,
  PortalDashboardIcon,
  PortalLaunchpadIcon,
  PortalProcessesIcon,
  ProcessStoreIcon,
  ProductRefIcon,
  ProgressIcon,
  RadioButtonIcon,
  RechargeDividerIcon,
  RegularStarIcon,
  RenameColumnIcon,
  ReportsIcon,
  RevertIcon,
  RichTextIcon,
  RightArrowIcon,
  RobinhoodIcon,
  RobotHeadIcon,
  RobotIcon,
  RotateLeftIcon,
  RotateRightIcon,
  SaveIcon,
  SavingIcon,
  ScissorsIcon,
  ScrapingIcon,
  ScreenshotIcon,
  SearchIcon,
  SearchInboxIcon,
  SecurityIcon,
  SendIcon,
  SettingsFullIcon,
  SettingsIcon,
  SidebarRetractIcon,
  SignOutIcon,
  SlackIcon,
  SmallBackArrowIcon,
  SmallRobotIcon,
  SmileyStarIcon,
  SnoozeIcon,
  SortArrowsIcon,
  SortDataIcon,
  SortIconDown,
  SortIconUp,
  SortRemovedIcon,
  SourceInfoIcon,
  SquaredPlayIcon,
  SquaredUpArrowIcon,
  StarIcon,
  StepTemplateIcon,
  SumIcon,
  TableModeIcon,
  TagsOutlinedIcon,
  TaskTrainingIcon,
  TeamsIcon,
  TelephoneIcon,
  TextModeIcon,
  ToastCancelIcon,
  ToastErrorIcon,
  ToastInfoIcon,
  ToastSuccessIcon,
  ToastTriangleIcon,
  ToggleAnnotationsIcon,
  TorguardIcon,
  TrainingIcon,
  TrashIcon,
  TrophyIcon,
  UploadIcon,
  UrlIcon,
  UserSwitchIcon,
  ValidateIcon,
  VideoIcon,
  VideoPlainIcon,
  VPNIcon,
  WebIcon,
  WordModeIcon,
  WorkIcon,
  ZoomInIcon,
  ZoomOutIcon,
}
