import { SVGProps } from 'react'
function DocEdit(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M3.31394 3.31394H17.7059V13.767C17.7059 15.1826 18.8563 16.333 20.2718 16.333H30.7249V31.009L34.0389 27.9602V11.7787L21.2139 0H2.60381C1.16935 0 0 1.16935 0 2.60381V41.642C0 43.0764 1.16935 44.2458 2.60381 44.2458H22.7525L23.1029 40.9318H3.31394V3.31394ZM30.4835 13.019H21.0198V4.32232L30.4835 13.019Z'
        fill='currentColor'
      />
      <path
        d='M40.7898 24.026L25.2427 39.5731L24.6841 44.7476C24.6178 45.3678 24.8214 45.9832 25.2475 46.433C25.6499 46.8591 26.2038 47.0958 26.7908 47.0958C26.824 47.0958 26.8618 47.0958 26.895 47.091L32.4151 46.8164L48 31.2315L40.7898 24.026ZM30.9711 43.5688L28.1259 43.7108L28.4099 41.0881L40.7851 28.7129L43.3037 31.2315L30.9711 43.5688Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DocEdit
