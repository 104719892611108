import { SVGProps } from 'react'

function RadioButton(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.3333 12C21.3333 17.1547 17.1546 21.3333 12 21.3333C6.84533 21.3333 2.66666 17.1547 2.66666 12C2.66666 6.84534 6.84533 2.66667 12 2.66667C17.1546 2.66667 21.3333 6.84534 21.3333 12ZM19.1795 12.0001C19.1795 15.9652 15.9651 19.1796 12 19.1796C8.03485 19.1796 4.82048 15.9652 4.82048 12.0001C4.82048 8.03494 8.03485 4.82058 12 4.82058C15.9651 4.82058 19.1795 8.03494 19.1795 12.0001ZM9.46323 9.46322C10.1423 8.78117 11.0397 8.41023 12 8.41023C12.4716 8.40829 12.9388 8.50042 13.3744 8.68122C13.81 8.86202 14.2051 9.12788 14.5367 9.46322C15.2158 10.1423 15.5897 11.0427 15.5897 12C15.5897 12.9572 15.2128 13.8576 14.5367 14.5367C13.8577 15.2158 12.9572 15.5897 12 15.5897C11.0427 15.5897 10.1423 15.2128 9.46323 14.5367C8.78417 13.8576 8.41024 12.9602 8.41024 12C8.41024 11.0397 8.78417 10.1423 9.46323 9.46322Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default RadioButton
