import { SVGProps } from 'react'
function SortIconUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width ?? '30'}
      height={props.width ?? '30'}
      viewBox='0 0 30 30'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 20.1027H14.5C14.7761 20.1027 15 20.3265 15 20.6027V23.6027C15 23.8788 14.7761 24.1027 14.5 24.1027H1.5C1.22386 24.1027 1 23.8788 1 23.6027V20.6027C1 20.3265 1.22386 20.1027 1.5 20.1027ZM1.5 3H24.5C24.7761 3 25 3.22386 25 3.5V6.5C25 6.77614 24.7761 7 24.5 7H1.5C1.22386 7 1 6.77614 1 6.5V3.5C1 3.22386 1.22386 3 1.5 3ZM1.5 11.5513H17.5C17.7761 11.5513 18 11.7752 18 12.0513V15.0513C18 15.3275 17.7761 15.5513 17.5 15.5513H1.5C1.22386 15.5513 1 15.3275 1 15.0513V12.0513C1 11.7752 1.22386 11.5513 1.5 11.5513ZM23.4751 27.0024H24.7275C25.0036 27.0024 25.2275 26.7786 25.2275 26.5024V15.6471L27.2565 17.6315C27.4514 17.8221 27.7631 17.8215 27.9572 17.63L28.8533 16.7463C29.0499 16.5524 29.0521 16.2358 28.8582 16.0392C28.8565 16.0376 28.8549 16.0359 28.8533 16.0343L24.4524 11.694C24.2577 11.502 23.9449 11.502 23.7502 11.694L19.3506 16.0331C19.1543 16.2266 19.1517 16.5425 19.3448 16.7392L20.2387 17.6502C20.4321 17.8473 20.7487 17.8503 20.9458 17.6569L20.9467 17.656L22.9751 15.6555V21.079V26.5024C22.9751 26.7786 23.1989 27.0024 23.4751 27.0024Z'
        fill={props.fill ?? '#604CA5'}
      />
    </svg>
  )
}

export default SortIconUp
