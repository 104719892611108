import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

const useTheme = () => {
  const theme = useContext(ThemeContext)
  if (theme === undefined) {
    throw new Error('error with useTheme hook')
  }
  return theme
}

export { useTheme }
