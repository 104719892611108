import { SVGProps } from 'react'
function DocCheck(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M4.54 43.952V3.765h15.38v11.167a2.743 2.743 0 002.74 2.741h11.163V38.01l3.54-3.54V12.81L23.663.224H3.781A2.786 2.786 0 001 3.007V44.71a2.787 2.787 0 002.782 2.782H27.88l-3.54-3.54H4.54zm29.025-29.819H23.46v-9.29l10.105 9.29z'
        fill='currentColor'
      />
      <path
        d='M46.148 33.17a1.767 1.767 0 00-2.504 0L33.62 43.217l-5.148-5.168a1.767 1.767 0 00-2.504 0 1.767 1.767 0 000 2.503l7.652 7.672 12.533-12.547a1.778 1.778 0 00-.005-2.509zM28.497 22.422H9.547c-.976 0-1.77.794-1.77 1.77 0 .977.794 1.77 1.77 1.77h18.95c.976 0 1.77-.793 1.77-1.77 0-.976-.794-1.77-1.77-1.77zM28.497 30.372H9.547c-.976 0-1.77.795-1.77 1.77 0 .977.794 1.77 1.77 1.77h18.95c.976 0 1.77-.793 1.77-1.77 0-.975-.794-1.77-1.77-1.77z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DocCheck
