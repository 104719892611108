import { signIn, signOut } from 'next-auth/client'

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, NEXTAUTH_URL } from './config/env'

const LOGOUT_URL = `https://${AUTH0_DOMAIN}/v2/logout?client_id=${AUTH0_CLIENT_ID}&returnTo=${NEXTAUTH_URL}/api/auth/signin`

export const logout = () => {
  signOut({ callbackUrl: LOGOUT_URL })
}

export const login = (redirectTo = '/') => {
  signIn('auth0', { callbackUrl: `${NEXTAUTH_URL}${redirectTo}` })
}
