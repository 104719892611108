import { SVGProps } from 'react'
function Scraping(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={58}
      height={46}
      viewBox='0 0 58 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M53.671 31.414H7.775v-8.736l2.758-.154v6.119h40.381V2.767H10.533v6.238l-2.758-.158V2.569A2.57 2.57 0 0110.335 0h40.776a2.57 2.57 0 012.56 2.57v28.844z'
        fill='currentColor'
      />
      <path
        d='M55.444 46H6.003c-.82 0-1.595-.4-2.076-1.063a2.58 2.58 0 01-.358-2.309l4.584-13.981H53.29l4.585 13.981a2.58 2.58 0 01-.358 2.309A2.56 2.56 0 0155.444 46zm-49.17-2.767h48.894l-3.876-11.82H10.15l-3.875 11.82z'
        fill='currentColor'
      />
      <path
        d='M41.41 46H20.036a1.84 1.84 0 01-1.485-.755 2.045 2.045 0 01-.323-1.787l2.131-7.49h20.726l2.131 7.49c.174.613.055 1.281-.323 1.787A1.84 1.84 0 0141.41 46zm-20.25-2.767h19.127l-1.28-4.498H22.44l-1.28 4.498zM30.16 26.832h-.028a20.522 20.522 0 01-9.33-2.371c-1.923-1.032-4.034-3.147-5.172-4.281l-.205-.206-13.326.55a2.05 2.05 0 01-1.48-.558A2.015 2.015 0 010 18.512v-5.23c0-.55.228-1.083.626-1.463.398-.38.942-.585 1.493-.557l13.31.565.204-.201c1.135-1.139 3.25-3.254 5.164-4.281a20.449 20.449 0 019.351-2.38 1.809 1.809 0 011.828 1.814v18.239c0 .482-.197.957-.544 1.3-.338.325-.8.514-1.272.514zm-.941-1.822v.008-.008zm.941-.945h.008-.008zm-14.184-6.882c.567 0 .882.313 1.603 1.036.961.96 2.962 2.96 4.518 3.795a17.74 17.74 0 007.122 2.012V7.764a17.756 17.756 0 00-7.126 2.02c-1.552.833-3.553 2.838-4.514 3.798-.776.775-1.079 1.08-1.725 1.032L2.757 14.06v3.669l13.093-.538c.043-.004.083-.008.126-.008zm.004-5.332h.024-.024z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Scraping
