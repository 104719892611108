import { SVGProps } from 'react'
function Cycle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={10.5}
      height={10.5}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M4.179 17.687c.04-1.756.401-3.46 1.08-5.063A13.791 13.791 0 018.221 8.23a13.75 13.75 0 019.775-4.05c1.868 0 3.676.365 5.376 1.084 1.643.694 3.134 1.701 4.392 2.966.397.397.77.82 1.117 1.261l-2.42 1.889a.32.32 0 00.121.566l7.06 1.728c.2.048.397-.105.397-.31l.032-7.268a.32.32 0 00-.518-.253l-2.266 1.772c-3.09-3.954-7.895-6.489-13.295-6.489-9.205 0-16.694 7.377-16.867 16.546a.321.321 0 00.322.329h2.41a.32.32 0 00.322-.313zM34.554 18h-2.411a.32.32 0 00-.322.313 13.725 13.725 0 01-1.08 5.063 13.748 13.748 0 01-2.961 4.395 13.759 13.759 0 01-9.776 4.05 13.751 13.751 0 01-9.771-4.05c-.398-.397-.772-.82-1.117-1.261l2.418-1.889a.321.321 0 00-.12-.566l-7.06-1.728a.323.323 0 00-.397.31l-.028 7.272c0 .27.309.422.518.253l2.266-1.772c3.09 3.95 7.895 6.485 13.295 6.485 9.21 0 16.694-7.38 16.867-16.546a.322.322 0 00-.321-.329z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Cycle
