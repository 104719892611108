import { SVGProps } from 'react'
function SidebarRetract(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M4.977 4.755c.132-.115.328-.012.328.173v6.144c0 .184-.194.287-.328.174L1.416 8.173a.234.234 0 010-.346l3.561-3.072zm2.629-.093H8.66v6.675H7.606V4.662z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SidebarRetract
