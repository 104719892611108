import { SVGProps } from 'react'
function Copy(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M10.938.875H3.609A.11.11 0 003.5.984v.766c0 .06.05.109.11.109h6.78v9.406c0 .06.05.11.11.11h.766c.06 0 .109-.05.109-.11V1.312a.437.437 0 00-.438-.437zm-1.75 1.75h-7a.437.437 0 00-.438.437v7.256c0 .116.046.227.129.309l2.369 2.369c.03.03.064.055.101.075v.026h.057a.432.432 0 00.15.027h4.631a.437.437 0 00.438-.437V3.062a.437.437 0 00-.438-.437zM4.785 12.25h-.003L2.734 10.2V10.2h2.051v2.05z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Copy
