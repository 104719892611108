import { SVGProps } from 'react'
function Divider(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={28} viewBox='0 0 16 28' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path stroke='currentColor' d='M8 0v28' />
    </svg>
  )
}

export default Divider
