import { SVGProps } from 'react'
function Crown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={15}
      viewBox='0 0 16 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fill='currentColor'
        d='M14.921 3.29l-3.475 2.142L8.114.987a.144.144 0 00-.23 0l-3.33 4.445L1.076 3.29a.144.144 0 00-.218.141L2.223 13.8a.29.29 0 00.286.25h10.984a.287.287 0 00.284-.25L15.14 3.432a.145.145 0 00-.22-.141zm-2.25 9.538H3.327l-.961-7.31 2.496 1.537L8 2.87l3.137 4.186 2.497-1.537-.963 7.31zM8 7.443c-1.11 0-2.01.901-2.01 2.01 0 1.11.9 2.011 2.01 2.011 1.109 0 2.01-.902 2.01-2.01 0-1.11-.901-2.011-2.01-2.011zm0 2.873a.862.862 0 11.002-1.723A.862.862 0 018 10.316z'
      />
    </svg>
  )
}

export default Crown
