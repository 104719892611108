import { FC } from 'react'
import { Box, BoxProps } from 'rebass'
import styled from 'styled-components'

const CardNew = styled(Box as FC<BoxProps>).attrs({
  'data-cy': 'cardNew',
})`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  padding: 18px 30px;
`

const CardVerticalDivider = styled.hr.attrs({
  'data-cy': 'cardVerticalDivider',
})`
  margin: 20px -30px;
  border: 0.5px solid #ddd;
`

export { CardNew, CardVerticalDivider }
