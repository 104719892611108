import { SVGProps } from 'react'
function CheckMark(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={13}
      viewBox='0 0 15 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path d='M1.53 7.633l3.94 3.498 7.94-10.044' stroke='currentColor' />
    </svg>
  )
}

export default CheckMark
