import { useSession } from '@invisible/authentication/client'
import { useContext, useEffect } from 'react'

import FlagContext from './context'
import RoxInstance from './instance'

const useRollout = () => {
  const flags = useContext(FlagContext)
  const [session] = useSession()
  const setProperty = (name: string, value: string) => {
    RoxInstance.setCustomStringProperty(name, value)
  }

  useEffect(() => {
    setProperty('profileEmail', session?.user?.email ?? '')
  }, [])

  return { flags, setProperty }
}

export default useRollout
