import { SVGProps } from 'react'
function InfoOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M.875 6.503a6.125 6.125 0 1112.25 0 6.125 6.125 0 01-12.25 0zm1.04 0A5.087 5.087 0 1012.087 6.5a5.087 5.087 0 00-10.174.002zm4.62-1.943a.656.656 0 11.929-.928.656.656 0 01-.928.928zm.137 1.068h.656c.06 0 .11.049.11.11v3.718c0 .06-.05.11-.11.11h-.656a.11.11 0 01-.11-.11V5.737c0-.06.05-.11.11-.11z'
        fill='currentColor'
      />
    </svg>
  )
}

export default InfoOutline
