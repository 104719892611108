import { SVGProps } from 'react'

function NoFillArrowDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='8'
      height='6'
      viewBox='0 0 8 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.56057 5.18555C4.17148 5.44953 3.6376 5.40914 3.29283 5.06437L0.464402 2.23594C0.0738777 1.84542 0.0738777 1.21225 0.464402 0.821729C0.854926 0.431204 1.48809 0.431204 1.87862 0.821729L3.99994 2.94305L6.12129 0.821702C6.51181 0.431178 7.14498 0.431177 7.5355 0.821702C7.92603 1.21223 7.92603 1.84539 7.5355 2.23592L4.70708 5.06434C4.70219 5.06923 4.69726 5.07406 4.6923 5.07882C4.65079 5.11868 4.6067 5.15425 4.56057 5.18555Z'
        fill='#0A0810'
      />
    </svg>
  )
}

export default NoFillArrowDown
