import { SVGProps } from 'react'
function IDCard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={13}
      viewBox='0 0 20 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M19.2861 0.638794H0.714299C0.319202 0.638794 0 0.95799 0 1.35308V15.6388C0 16.0339 0.319202 16.3531 0.714299 16.3531H19.2861C19.6812 16.3531 20.0004 16.0339 20.0004 15.6388V1.35308C20.0004 0.95799 19.6812 0.638794 19.2861 0.638794ZM18.3932 14.7459H1.60717V2.24594H18.3932V14.7459ZM12.1944 7.69237H14.9489C14.978 7.69237 15.0003 7.61201 15.0003 7.51379V6.44237C15.0003 6.34415 14.978 6.26379 14.9489 6.26379H12.1944C12.1654 6.26379 12.1431 6.34415 12.1431 6.44237V7.51379C12.1431 7.61201 12.1654 7.69237 12.1944 7.69237ZM12.3016 10.9067H16.4467C16.5338 10.9067 16.6052 10.8263 16.6052 10.7281V9.65665C16.6052 9.55844 16.5338 9.47808 16.4467 9.47808H12.3016C12.2145 9.47808 12.1431 9.55844 12.1431 9.65665V10.7281C12.1431 10.8263 12.2145 10.9067 12.3016 10.9067ZM3.5715 12.0897H4.55142C4.64518 12.0897 4.72107 12.016 4.72777 11.9223C4.81259 10.795 5.75457 9.90219 6.89745 9.90219C8.04033 9.90219 8.98231 10.795 9.06713 11.9223C9.07383 12.016 9.14973 12.0897 9.24348 12.0897H10.2234C10.2476 12.0897 10.2716 12.0848 10.2939 12.0753C10.3161 12.0658 10.3362 12.0518 10.3529 12.0343C10.3696 12.0167 10.3826 11.996 10.391 11.9733C10.3995 11.9506 10.4032 11.9264 10.402 11.9022C10.3395 10.7125 9.68768 9.67674 8.73677 9.08746C9.15611 8.6265 9.38783 8.02534 9.38634 7.40219C9.38634 6.02049 8.27248 4.90219 6.89968 4.90219C5.52689 4.90219 4.41303 6.02049 4.41303 7.40219C4.41303 8.05174 4.65857 8.64103 5.06259 9.08746C4.5785 9.38744 4.1742 9.80012 3.88422 10.2903C3.59425 10.7804 3.42721 11.3335 3.39739 11.9022C3.38846 12.0049 3.46882 12.0897 3.5715 12.0897ZM6.89745 6.24147C7.53362 6.24147 8.05149 6.76156 8.05149 7.40219C8.05149 8.04281 7.53362 8.5629 6.89745 8.5629C6.26128 8.5629 5.74341 8.04281 5.74341 7.40219C5.74341 6.76156 6.26128 6.24147 6.89745 6.24147Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IDCard
