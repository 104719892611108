import { SVGProps } from 'react'
function Diamond(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={10}
      height={18}
      viewBox='0 0 10 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path d='M10 9l-4.983 8.266L.043 9 5.017.734 10 9z' fill='#CE2128' />
      <path d='M5.017.734V9H.043L5.017.734z' fill='#F59295' />
      <path d='M5.016 9v8.266L9.998 9H5.016z' fill='#8C1E1E' />
    </svg>
  )
}

export default Diamond
