import { textColor } from '@invisible/ui/themes'
import { SVGProps } from 'react'
function FullScreen(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='15.14'
      height='15.14'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.17 0.966797H2.25031C1.53915 0.966797 0.9646 1.54135 0.9646 2.25251V11.2525C0.9646 11.6061 1.25389 11.8954 1.60746 11.8954H3.53603C3.8896 11.8954 4.17889 11.6061 4.17889 11.2525V4.18108H11.17C11.5235 4.18108 11.8128 3.8918 11.8128 3.53823V1.60965C11.8128 1.25608 11.5235 0.966797 11.17 0.966797ZM34.3932 24.1097H32.4646C32.111 24.1097 31.8217 24.3989 31.8217 24.7525V31.8239H24.8307C24.4771 31.8239 24.1878 32.1132 24.1878 32.4668V34.3954C24.1878 34.7489 24.4771 35.0382 24.8307 35.0382H33.7503C34.4615 35.0382 35.036 34.4637 35.036 33.7525V24.7525C35.036 24.3989 34.7467 24.1097 34.3932 24.1097ZM11.17 31.8239H4.17889V24.7525C4.17889 24.3989 3.8896 24.1097 3.53603 24.1097H1.60746C1.25389 24.1097 0.9646 24.3989 0.9646 24.7525V33.7525C0.9646 34.4637 1.53915 35.0382 2.25031 35.0382H11.17C11.5235 35.0382 11.8128 34.7489 11.8128 34.3954V32.4668C11.8128 32.1132 11.5235 31.8239 11.17 31.8239ZM33.7503 0.966797H24.8307C24.4771 0.966797 24.1878 1.25608 24.1878 1.60965V3.53823C24.1878 3.8918 24.4771 4.18108 24.8307 4.18108H31.8217V11.2525C31.8217 11.6061 32.111 11.8954 32.4646 11.8954H34.3932C34.7467 11.8954 35.036 11.6061 35.036 11.2525V2.25251C35.036 1.54135 34.4615 0.966797 33.7503 0.966797Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FullScreen
