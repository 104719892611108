import { SVGProps } from 'react'
function Messages(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M9.563.018a9 9 0 0 0-8.455 13.308L.374 15.92a1.377 1.377 0 0 0 1.706 1.706l2.594-.734A9 9 0 1 0 9.563.018Z'
        id='MessagesShape'
        fill='currentColor'
      />
      <path
        d='M4.33 2.986a7.614 7.614 0 1 1 .788 12.564.693.693 0 0 0-.542-.07l-2.867.81.812-2.866a.693.693 0 0 0-.07-.542 7.614 7.614 0 0 1 1.88-9.896Z'
        id='MessagesPath'
        fill={props.fill ?? '#FFFFFF'}
      />
      <path
        d='M10.048 8.991a1.04 1.04 0 1 1-2.079 0 1.04 1.04 0 0 1 2.08 0Zm-5.197 1.04a1.04 1.04 0 1 0 0-2.08 1.04 1.04 0 0 0 0 2.08Zm9.355-1.04a1.04 1.04 0 1 1-2.079 0 1.04 1.04 0 0 1 2.08 0Z'
        id='MessagesShape2'
        fill='currentColor'
      />
    </svg>
  )
}

export default Messages
