import { SVGProps } from 'react'
function Training(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='12'
      height='15'
      viewBox='0 0 12 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M10.9996 0.50293H0.999634C0.723071 0.50293 0.499634 0.726367 0.499634 1.00293V14.0029C0.499634 14.2795 0.723071 14.5029 0.999634 14.5029H10.9996C11.2762 14.5029 11.4996 14.2795 11.4996 14.0029V1.00293C11.4996 0.726367 11.2762 0.50293 10.9996 0.50293ZM6.93713 1.62793H8.43713V4.90762L7.71057 4.37793L6.93713 4.93105V1.62793ZM10.3746 13.3779H1.62463V1.62793H5.99963V6.26699C5.99963 6.31855 6.01526 6.37012 6.04651 6.4123C6.06548 6.43912 6.08958 6.46191 6.11741 6.47935C6.14525 6.49679 6.17626 6.50854 6.20866 6.51392C6.24107 6.51929 6.27422 6.51819 6.30619 6.51067C6.33817 6.50315 6.36833 6.48937 6.39495 6.47012L7.70432 5.53418L8.9762 6.4623C9.01838 6.49355 9.06995 6.51074 9.12307 6.51074C9.26057 6.51074 9.37307 6.39824 9.37307 6.26074V1.62793H10.3731V13.3779H10.3746Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Training
