import { SVGProps } from 'react'
function CaretDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={10}
      height={10}
      fill='none'
      viewBox='0 0 10 10'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.56 7.186a1 1 0 01-1.267-.122L1.464 4.236A1 1 0 012.88 2.822l2.12 2.121 2.122-2.121a1 1 0 011.415 1.414L5.707 7.064a1.006 1.006 0 01-.146.122z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CaretDown
