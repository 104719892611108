import { SVGProps } from 'react'
function OutputVar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={30}
      height={18}
      viewBox='0 0 30 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M20.621 4.429L25.163 9l-4.542 4.571M9.502 9h15.66M16.223 13.404a8.078 8.078 0 11-.004-8.97'
        stroke='currentColor'
      />
    </svg>
  )
}

export default OutputVar
