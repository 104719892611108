import { SVGProps } from 'react'
function Torguard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={54}
      height={49}
      viewBox='0 0 54 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M46.893 30.159a10.909 10.909 0 00-3.836-6.864v-6.686c0-8.96-7.292-16.252-16.252-16.252-8.96 0-16.251 7.292-16.251 16.252v7.734a14.22 14.22 0 00-1.64 3.826c-4.657.966-8.128 5.11-8.128 9.988 0 5.624 4.577 10.2 10.205 10.2l32.926-.057c5.13 0 9.297-4.173 9.297-9.297 0-4.013-2.63-7.57-6.321-8.844zM26.805 4.203c6.84 0 12.407 5.566 12.407 12.406v4.61a10.92 10.92 0 00-3.163-.472c-1.202 0-2.37.188-3.485.563a14.407 14.407 0 00-9.753-3.812c-3.134 0-6.037.995-8.412 2.701v-3.586c0-6.844 5.566-12.41 12.406-12.41zm17.112 40.251l-32.926.058a6.362 6.362 0 01-6.36-6.355c0-3.273 2.505-6.018 5.74-6.325l1.735.269.298-1.89a10.48 10.48 0 0110.402-8.868c2.995 0 5.855 1.279 7.854 3.514l.966 1.082 1.308-.635a7.077 7.077 0 013.115-.711c3.917 0 7.104 3.182 7.104 7.09v1.639l1.62.26c2.624.422 4.6 2.754 4.6 5.421a5.463 5.463 0 01-5.456 5.451z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Torguard
