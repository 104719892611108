import { SVGProps } from 'react'

function AccessRecord(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M37.2287 33.299H28.1266V30.68H37.0504V2.61903H9.52296V16.556H7.02631V2.43195C7.02631 1.09251 8.06777 0 9.34463 0H37.2287C38.5056 0 39.547 1.09251 39.547 2.43195V30.8671C39.547 32.2103 38.5056 33.299 37.2287 33.299Z'
        fill='currentColor'
      />
      <path
        d='M45.6817 26.744C45.3107 26.744 44.9398 26.6467 44.5974 26.4521L44.4405 26.3474L37.0432 20.4808V12.923L44.5974 6.95163C45.3072 6.54755 46.1418 6.56252 46.8373 6.99653C47.5649 7.44924 48 8.26488 48 9.17406V24.2335C48 25.1426 47.5649 25.9583 46.8373 26.411C46.4806 26.6317 46.0811 26.744 45.6817 26.744ZM39.5435 19.175L45.5033 23.9005V9.49582L39.5435 14.225V19.175Z'
        fill='currentColor'
      />
      <path
        d='M12.3264 40C11.7486 40 11.1601 39.9551 10.5752 39.8728C9.01654 39.6483 7.54351 39.1357 6.19175 38.35C4.47263 37.3623 2.96393 35.948 1.84044 34.2606C1.05934 33.0783 0.470842 31.7912 0.0856438 30.4256C-0.0284891 30.044 -0.0284891 29.6212 0.0927771 29.2171C1.67994 23.6011 6.71605 19.6651 12.33 19.6651C17.9439 19.6651 22.9764 23.6012 24.5743 29.2395L24.5814 29.262C24.6777 29.6249 24.6777 30.014 24.585 30.3882L24.5743 30.4293C22.9764 36.064 17.9403 40 12.3264 40ZM2.52167 29.8344C2.83553 30.8858 3.29206 31.866 3.88769 32.764C4.79006 34.1184 6.00629 35.2558 7.39728 36.0565C8.48154 36.6888 9.66567 37.0966 10.9176 37.2799C11.3991 37.351 11.8592 37.3847 12.3264 37.3847C16.7918 37.3847 20.8008 34.2905 22.1311 29.8382C20.8008 25.3858 16.7918 22.2916 12.3264 22.2916C7.86095 22.2879 3.85203 25.3821 2.52167 29.8344ZM2.48243 29.9729C2.48243 29.9766 2.47887 29.9803 2.47887 29.9841C2.47887 29.9803 2.48243 29.9766 2.48243 29.9729ZM2.47173 29.6623C2.47173 29.6698 2.4753 29.6735 2.4753 29.681C2.4753 29.6735 2.4753 29.6661 2.47173 29.6623Z'
        fill='currentColor'
      />
      <path
        d='M12.4548 35.9143C9.26617 35.9143 6.67322 33.1943 6.67322 29.8494C6.67322 26.5045 9.26617 23.7845 12.4548 23.7845C15.6433 23.7845 18.2363 26.5045 18.2363 29.8494C18.2363 33.1943 15.6433 35.9143 12.4548 35.9143ZM12.4548 26.3998C10.6429 26.3998 9.16987 27.945 9.16987 29.8457C9.16987 31.7463 10.6429 33.2915 12.4548 33.2915C14.2666 33.2915 15.7396 31.7463 15.7396 29.8457C15.7396 27.945 14.2702 26.3998 12.4548 26.3998Z'
        fill='currentColor'
      />
      <path d='M18.379 5.43634H12.2907V8.05537H18.379V5.43634Z' fill='currentColor' />
    </svg>
  )
}

export default AccessRecord
