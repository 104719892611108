import { SVGProps } from 'react'
function LinkedIn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M41.9848 2.56653H6.01691C4.11512 2.56653 2.57227 4.10939 2.57227 6.01117V41.979C2.57227 43.8808 4.11512 45.4237 6.01691 45.4237H41.9848C43.8866 45.4237 45.4294 43.8808 45.4294 41.979V6.01117C45.4294 4.10939 43.8866 2.56653 41.9848 2.56653ZM41.9848 41.9951C17.9955 41.9897 6.00084 41.9844 6.00084 41.979C6.00619 17.9897 6.01155 5.9951 6.01691 5.9951C30.0062 6.00046 42.0008 6.00581 42.0008 6.01117C41.9955 30.0005 41.9901 41.9951 41.9848 41.9951ZM8.92584 18.6326H15.2848V39.0862H8.92584V18.6326ZM12.108 15.8362C14.1383 15.8362 15.7937 14.1862 15.7937 12.1505C15.7937 11.6664 15.6984 11.1872 15.5131 10.74C15.3279 10.2928 15.0564 9.88651 14.7142 9.54426C14.3719 9.20201 13.9656 8.93053 13.5184 8.7453C13.0713 8.56008 12.592 8.46474 12.108 8.46474C11.624 8.46474 11.1447 8.56008 10.6975 8.7453C10.2503 8.93053 9.84404 9.20201 9.50179 9.54426C9.15954 9.88651 8.88805 10.2928 8.70282 10.74C8.5176 11.1872 8.42227 11.6664 8.42227 12.1505C8.41691 14.1862 10.0669 15.8362 12.108 15.8362ZM25.6241 28.9665C25.6241 26.2987 26.133 23.7165 29.4383 23.7165C32.6955 23.7165 32.7437 26.7647 32.7437 29.138V39.0862H39.0973V27.8683C39.0973 22.3612 37.908 18.1237 31.4741 18.1237C28.383 18.1237 26.3098 19.8219 25.458 21.429H25.3723V18.6326H19.2705V39.0862H25.6241V28.9665Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LinkedIn
