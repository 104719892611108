import { SVGProps } from 'react'
function CaretUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={9}
      height={7}
      viewBox='0 0 9 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M8.692 5.639L4.724 1.037a.302.302 0 00-.448 0L.308 5.64c-.148.172-.015.423.223.423H8.47c.238 0 .37-.251.223-.423z'
        fill='currentColor'
        fillOpacity={0.25}
      />
    </svg>
  )
}

export default CaretUp
