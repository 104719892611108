import { SVGProps } from 'react'

function Bell(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 36 36'
      fill='none'
      stroke='#423F4C'
      stroke-width='2'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M28.6875 27H27.8438V15.0469C27.8438 10.0863 24.177 5.98359 19.4062 5.30156V3.9375C19.4062 3.16055 18.777 2.53125 18 2.53125C17.223 2.53125 16.5938 3.16055 16.5938 3.9375V5.30156C11.823 5.98359 8.15625 10.0863 8.15625 15.0469V27H7.3125C6.69023 27 6.1875 27.5027 6.1875 28.125V29.25C6.1875 29.4047 6.31406 29.5312 6.46875 29.5312H14.0625C14.0625 31.7039 15.8273 33.4688 18 33.4688C20.1727 33.4688 21.9375 31.7039 21.9375 29.5312H29.5312C29.6859 29.5312 29.8125 29.4047 29.8125 29.25V28.125C29.8125 27.5027 29.3098 27 28.6875 27ZM18 31.2188C17.0684 31.2188 16.3125 30.4629 16.3125 29.5312H19.6875C19.6875 30.4629 18.9316 31.2188 18 31.2188Z'
        fill={props.fill ?? '#6300ff'}
      />
    </svg>
  )
}

export default Bell
