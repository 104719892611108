import { SVGProps } from 'react'

function DownSquare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.8264 15.9295C11.9122 16.0473 12.0863 16.0473 12.172 15.9295L16.9398 9.34018C17.0416 9.19821 16.9398 9 16.7657 9H15.5095C15.2363 9 14.9764 9.13125 14.8157 9.35357L12.0005 13.2482L9.18269 9.35357C9.02198 9.13125 8.76483 9 8.48894 9H7.23269C7.05858 9 6.9568 9.19821 7.05858 9.34018L11.8264 15.9295Z'
        fill='currentColor'
      />
      <path
        d='M21.8576 1.28612H2.14328C1.66917 1.28612 1.28613 1.66916 1.28613 2.14326V21.8576C1.28613 22.3317 1.66917 22.7147 2.14328 22.7147H21.8576C22.3317 22.7147 22.7147 22.3317 22.7147 21.8576V2.14326C22.7147 1.66916 22.3317 1.28612 21.8576 1.28612ZM20.7861 20.7861H3.2147V3.21469H20.7861V20.7861Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DownSquare
