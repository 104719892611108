import { SVGProps } from 'react'
function Export(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.442 10.832h-.84a.121.121 0 00-.121.12v.966h-9.84v-9.84h9.842v.965c0 .066.054.12.12.12h.84a.12.12 0 00.12-.12V1.477a.479.479 0 00-.479-.48H1.042a.479.479 0 00-.48.48v11.041c0 .266.214.48.48.48h11.04c.266 0 .48-.214.48-.48v-1.566a.12.12 0 00-.12-.12zm.29-3.933l-2.217-1.75a.125.125 0 00-.203.099v1.187H5.406a.125.125 0 00-.125.125v.875c0 .069.056.125.125.125h4.906v1.188c0 .104.122.164.203.098l2.218-1.75a.125.125 0 000-.197z'
        fill='#423F4C'
      />
    </svg>
  )
}

export default Export
