import { SVGProps } from 'react'
function Telephone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M14.5219 3.12477L12.6201 1.22477C12.3879 0.992628 12.0772 0.86227 11.7487 0.86227C11.4201 0.86227 11.1094 0.990842 10.8772 1.22477L8.82902 3.26941C8.59688 3.50156 8.46652 3.81406 8.46652 4.14263C8.46652 4.47299 8.59509 4.78191 8.82902 5.01584L10.429 6.61763C10.0645 7.46689 9.54081 8.23852 8.88616 8.89084C8.2308 9.54977 7.46473 10.0694 6.61473 10.4373L5.01473 8.83549C4.78259 8.60334 4.47187 8.47299 4.1433 8.47299C3.98124 8.47239 3.82068 8.50414 3.67105 8.56639C3.52141 8.62863 3.3857 8.72012 3.27188 8.83549L1.22188 10.8801C0.989732 11.1123 0.859375 11.4248 0.859375 11.7533C0.859375 12.0837 0.987946 12.3926 1.22188 12.6266L3.12188 14.5266C3.5183 14.923 4.06473 15.1498 4.62545 15.1498C4.74152 15.1498 4.85402 15.1408 4.9683 15.1212C7.33259 14.7319 9.67902 13.473 11.5737 11.5801C13.4665 9.6837 14.7237 7.33727 15.1165 4.9712C15.229 4.29977 15.004 3.6087 14.5219 3.12477ZM13.8504 4.7587C13.5022 6.86406 12.3701 8.96406 10.6647 10.6694C8.95938 12.3748 6.86116 13.5069 4.7558 13.8551C4.49152 13.8998 4.22009 13.8105 4.02723 13.6194L2.16116 11.7533L4.13973 9.77299L6.27902 11.9158L6.29509 11.9319L6.6808 11.7891C7.85032 11.3591 8.91235 10.6799 9.79329 9.79867C10.6742 8.91742 11.353 7.85515 11.7826 6.68549L11.9254 6.29977L9.7683 4.14441L11.7469 2.16406L13.6129 4.03013C13.8058 4.22299 13.8951 4.49441 13.8504 4.7587Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Telephone
