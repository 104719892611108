import { DEFAULT_AVATAR_PURPLE } from '@invisible/ui/images'
import { styled } from '@invisible/ui/themes'
import Image from 'next/image'
import { FC } from 'react'

const Img = styled(Image)<{ ml: number }>`
  border-radius: 100%;
  margin-left: ${({ ml }) => `${ml}px`};
`

interface Props {
  src?: string
  size?: number
  marginLeft?: number
}
export const Avatar: FC<Props> = ({ size = 40, src = DEFAULT_AVATAR_PURPLE, marginLeft = 19 }) => (
  <Img width={size} height={size} src={src} ml={marginLeft} data-cy='avatar' />
)
