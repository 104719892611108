import { SVGProps } from 'react'
function Reports(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={15}
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.5 7.595H6.906V2.001a.125.125 0 00-.125-.125h-.406a6.226 6.226 0 00-4.419 1.83 6.222 6.222 0 00-1.831 4.42 6.226 6.226 0 001.83 4.419 6.221 6.221 0 004.42 1.831 6.225 6.225 0 004.418-1.83 6.222 6.222 0 001.832-4.42V7.72a.125.125 0 00-.125-.125zm-2.474 4.216a5.18 5.18 0 01-3.676 1.503 5.154 5.154 0 01-3.644-1.519 5.154 5.154 0 01-1.519-3.669c0-1.386.54-2.689 1.519-3.668a5.142 5.142 0 013.138-1.493v5.693h5.692a5.145 5.145 0 01-1.51 3.153zm3.849-5.085l-.04-.44a6.215 6.215 0 00-1.8-3.822A6.24 6.24 0 008.202.667L7.76.627a.124.124 0 00-.136.123V6.75c0 .069.056.125.125.125l6-.015a.125.125 0 00.125-.135zm-5.19-.91V1.809a5.19 5.19 0 012.6 1.403 5.158 5.158 0 011.405 2.595l-4.006.01z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Reports
