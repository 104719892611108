import { SVGProps } from 'react'
function Home(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M15.7599 7.87393L8.85989 0.9775L8.39739 0.515C8.29201 0.410316 8.1495 0.351563 8.00097 0.351562C7.85243 0.351562 7.70992 0.410316 7.60454 0.515L0.242038 7.87393C0.134058 7.98149 0.0487196 8.10959 -0.00894006 8.25067C-0.0665997 8.39175 -0.0954118 8.54296 -0.093676 8.69536C-0.0865331 9.32393 0.436681 9.82571 1.06525 9.82571H1.82418V15.6418H14.1778V9.82571H14.9528C15.2581 9.82571 15.5456 9.70607 15.7617 9.49C15.8681 9.38395 15.9524 9.25784 16.0097 9.11899C16.067 8.98014 16.0962 8.83129 16.0956 8.68107C16.0956 8.3775 15.976 8.09 15.7599 7.87393ZM9.00097 14.3561H7.00097V10.7132H9.00097V14.3561ZM12.892 8.54V14.3561H10.1438V10.2846C10.1438 9.89 9.82418 9.57036 9.42954 9.57036H6.57239C6.17775 9.57036 5.85811 9.89 5.85811 10.2846V14.3561H3.10989V8.54H1.39561L8.00275 1.93821L8.41525 2.35071L14.6081 8.54H12.892Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Home
