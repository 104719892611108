const MOBILE_BREAKPOINT = '600px'
const lightGray = '#f6f6f6'
const midGray = '#999999'
const darkGray = '#666666'
export const theme = {
  MEDIA_IS_MOBILE: `@media (max-width: ${MOBILE_BREAKPOINT})`,
  MOBILE_BREAKPOINT,
  fontFamily: 'Roboto, Arial, Helvetica, sans-serif' as const,
  breakpoints: ['40em', '52em', '64em'] as const,
  fontSizes: ['12px', '14px', '16px', '20px', '24px', '30px', '38px'] as const,
  colors: {
    aqua: '#00D7D3',
    blue: '#604CA5',
    clearBlue: '#8BD4F5',
    darkBlue: '#22315C',
    extendedBrandOrange: '#F5AC71',
    extendedBrandMagenta: '#EC2474',
    indigo: '#8F45FF',
    neonBlue: '#01F3FD',
    primary: '#604CA5',
    lightprimary: '#f3ebff',
    darkprimary: '#4800b9',
    success: '#52C41A', //updated
    attention: '#ff002e',
    danger: '#FF4D4F', //updated
    warning: '#ff5c00',
    info: '#0075ff',
    red: '#ff002e',
    darkred: '#bc0022',
    lightred: '#ffdde3',
    orange: '#FF933C',
    highlightGood: '#daffeb',
    green: '#2CE28B',
    magenta: '#EB5C95',
    mainGreen: '#2CE28B',
    lightgreen: '#bcffdf',
    darkgreen: '#11c26d',
    highlightYellow: '#feffd8',
    highlightBlue: '#e2ecff',
    translucentBlueOverlay: 'rgba(0, 87, 255, 0.31)',
    translucentRedOverlay: 'rgba(255, 0, 46, 0.28)',
    categoryTagTextColor: '#007E1C',
    categoryTagBackgroundColor: '#D7FFE9',
    companyTagTextColor: '#00229C',
    companyTagBackgroundColor: '#D2F4FF',
    textStatusRed: '#FF4D4F',
    textStatusOrange: '#FAAD14',
    textStatusGreen: '#52C41A',
    marketingBlack: '#1A1E21',
    marketingBlue: '#0102FA',
    marketingOrange: '#FD7A5E',
    marketingPurple: '#6A6AF7',
    marketingCloudBlue: '#DEF1F1',
    marketingCloudDesert: '#F3EEE9',
    white: '#ffffff',
    gray: midGray,
    lightgray: lightGray,
    darkgray: darkGray,
    goldenPurple10: '#1A1A29',
  } as const,
  purpleScale: {
    1: '#EFEDF5',
    2: '#DFDBED',
    3: '#CFC9E4',
    4: '#A094C9',
    5: '#8070B7',
    6: '#604CA5',
    7: '#4F3E87',
    8: '#352A5A',
    9: '#241C3D',
    10: '#130F1F',
    12: '#000000',
  } as const,
  grayScale: {
    1: '#FFFFFF',
    2: '#FAFAFB',
    3: '#F3F2F3',
    4: '#EAE9EB',
    5: '#D0CFD2',
    6: '#A19FA5',
    7: '#716F79',
    8: '#5A5762',
    9: '#423F4C',
    10: '#363341',
    11: '#2B2735',
    12: '#1F1B2A',
    13: '#0A0810',
  } as const,
  textColor: {
    title: '#0A0810',
    primary: '#423F4C',
    secondary: '#A19FA5',
    disabled: 'rgba(0, 0, 0, 0.25)',
    warning: '#FAAD14',
    danger: '#FF4D4F',
    mark: '#000000',
  } as const,
  pbTaskColors: {
    magenta: {
      primary: '#ED005F',
      secondary: '#EB5C95',
    },
    blue: {
      primary: '#1167E8',
      secondary: '#679AE8',
    },
    teal: {
      primary: '#0A7A82',
      secondary: '#62A6AA',
    },
    green: {
      primary: '#25A148',
      secondary: '#72BD88',
    },
    indigo: {
      primary: '#8F45FF',
      secondary: '#B286F5',
    },
    royalPurple: {
      primary: '#002284',
      secondary: '#5C71AC',
    },
    redPurple: {
      primary: '#72267C',
      secondary: '#A784AC',
    },
    orange: {
      primary: '#FF933C',
      secondary: '#F5B580',
    },
    red: {
      primary: '#FF2733',
      secondary: '#F5747B',
    },
    aqua: {
      primary: '#00D7D3',
      secondary: '#5CDDDB',
    },
  } as const,
  space: ['0px', '4px', '8px', '16px', '24px', '32px', '64px', '128px', '256px'] as const,
  fonts: {
    sans: 'Roboto, system-ui, sans-serif',
    mono: 'Menlo, monospace',
  } as const,
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    medium: '0 0 24px rgba(0, 0, 0, .125)',
    large: '0px 8px 12px rgba(0, 0, 0, 0.2)',
  } as const,
} as const

export type TTheme = Omit<typeof theme, 'purpleScale'> & {
  purpleScale: Omit<typeof theme['purpleScale'], 12> & { 12?: string }
}

export const breakpoints =
  (thisBreak: number) =>
  ({ theme }: { theme: TTheme }) =>
    theme.breakpoints[thisBreak]

export const fontSizes =
  (thisFontSize: number) =>
  ({ theme }: { theme: TTheme }) =>
    theme.fontSizes[thisFontSize]

export const purple =
  (thisPurple: keyof typeof theme.purpleScale) =>
  ({ theme }: { theme: TTheme }) =>
    theme.purpleScale[thisPurple]

export const color =
  (thisColor: keyof typeof theme.colors) =>
  ({ theme }: { theme: TTheme }) =>
    theme.colors[thisColor]

export const gray =
  (thisGray: keyof typeof theme.grayScale) =>
  ({ theme }: { theme: TTheme }) =>
    theme.grayScale[thisGray]

export const textColor =
  (thisText: keyof typeof theme.textColor) =>
  ({ theme }: { theme: TTheme }) =>
    theme.textColor[thisText]

export const space =
  (thisSpace: number) =>
  ({ theme }: { theme: TTheme }) =>
    theme.space[thisSpace]

export const fonts =
  (thisFont: keyof typeof theme.fonts) =>
  ({ theme }: { theme: TTheme }) =>
    theme.fonts[thisFont]

export const shadows =
  (thisShadow: keyof typeof theme.shadows) =>
  ({ theme }: { theme: TTheme }) =>
    theme.shadows[thisShadow]
