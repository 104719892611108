import { SVGProps } from 'react'
function ProductRef(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='19'
      height='17'
      viewBox='0 0 19 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M6.429 13.333c.51.003 1 .214 1.36.589.362.374.566.882.568 1.411a.68.68 0 0 0 .188.472.631.631 0 0 0 .91 0 .68.68 0 0 0 .188-.472c.002-.53.206-1.037.567-1.411.361-.375.85-.586 1.361-.589h5.143c.341 0 .668-.14.91-.39.24-.25.376-.59.376-.943V1.333c0-.353-.135-.692-.377-.942A1.263 1.263 0 0 0 16.714 0h-3.857c-.852 0-1.67.351-2.273.976a3.397 3.397 0 0 0-.941 2.357V10a.68.68 0 0 1-.188.471.631.631 0 0 1-.455.196.631.631 0 0 1-.455-.196.68.68 0 0 1-.188-.471V3.333c0-.884-.339-1.732-.941-2.357A3.157 3.157 0 0 0 5.143 0H1.286C.945 0 .618.14.376.39.137.64 0 .98 0 1.334V12c0 .354.135.693.377.943.24.25.568.39.909.39h5.143Z'
          id='DocumentsPath'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
        <path
          d='M1.286 0C.945 0 .618.14.376.39.137.64 0 .98 0 1.334V12c0 .354.135.693.377.943.24.25.568.39.909.39h5.143c.511 0 1.002.211 1.363.586.362.375.565.884.565 1.414 0 .369.288.667.643.667a.655.655 0 0 0 .643-.667c0-.53.203-1.039.565-1.414a1.895 1.895 0 0 1 1.363-.586h5.143c.341 0 .668-.14.91-.39.24-.25.376-.59.376-.943V1.333c0-.353-.135-.692-.377-.942A1.263 1.263 0 0 0 16.714 0h-5.143C10.72 0 9.901.351 9.3.976A3.303 3.303 0 0 0 9 1.333a3.303 3.303 0 0 0-.299-.357A3.157 3.157 0 0 0 6.43 0H1.286Zm8.357 3.333v9.334A3.14 3.14 0 0 1 11.57 12h5.143V1.333h-5.143c-.511 0-1.002.211-1.363.586a2.038 2.038 0 0 0-.565 1.414Zm-1.286 0c0-.53-.203-1.039-.565-1.414a1.894 1.894 0 0 0-1.363-.586H1.286V12h5.143c.7 0 1.375.236 1.928.667V3.333Z'
          id='DocumentsShape'
          fill='currentColor'
        />
      </g>
    </svg>
  )
}

export default ProductRef
