import { SVGProps } from 'react'
function CloseSquare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={36}
      height={36}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.65 24.387h2.367c.19 0 .37-.084.495-.229L17.999 20l3.488 4.158c.12.145.3.23.494.23h2.366a.32.32 0 00.245-.527l-4.913-5.862 4.917-5.862a.32.32 0 00-.245-.527h-2.366a.654.654 0 00-.494.23l-3.492 4.158-3.487-4.159a.642.642 0 00-.495-.229h-2.366a.32.32 0 00-.245.527l4.914 5.862-4.914 5.862a.32.32 0 00.245.526z'
        fill='currentColor'
      />
      <path
        d='M32.786 1.93H3.215c-.711 0-1.286.574-1.286 1.285v29.571c0 .712.575 1.286 1.286 1.286h29.571c.712 0 1.286-.575 1.286-1.286V3.215c0-.711-.575-1.286-1.286-1.286zM31.18 31.18H4.822V4.821H31.18V31.18z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CloseSquare
