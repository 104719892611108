import { SVGProps } from 'react'
function Upload(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.4997 10.2057H16.4689V23.7981C16.4689 23.9748 16.6135 24.1195 16.7903 24.1195H19.201C19.3778 24.1195 19.5225 23.9748 19.5225 23.7981V10.2057H22.4997C22.7689 10.2057 22.9175 9.89628 22.7528 9.68735L18.2528 3.99003C18.2227 3.95161 18.1843 3.92054 18.1405 3.89917C18.0966 3.8778 18.0485 3.8667 17.9997 3.8667C17.9509 3.8667 17.9028 3.8778 17.8589 3.89917C17.815 3.92054 17.7766 3.95161 17.7466 3.99003L13.2466 9.68333C13.0818 9.89628 13.2305 10.2057 13.4997 10.2057V10.2057ZM32.705 22.5927H30.2943C30.1175 22.5927 29.9729 22.7373 29.9729 22.9141V29.1016H6.02647V22.9141C6.02647 22.7373 5.88183 22.5927 5.70504 22.5927H3.29433C3.11754 22.5927 2.9729 22.7373 2.9729 22.9141V30.8695C2.9729 31.5807 3.54745 32.1552 4.25861 32.1552H31.7408C32.4519 32.1552 33.0265 31.5807 33.0265 30.8695V22.9141C33.0265 22.7373 32.8818 22.5927 32.705 22.5927Z'
        fill={props.fill ?? '#6300ff'}
      />
    </svg>
  )
}

export default Upload
