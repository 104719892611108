import { SVGProps } from 'react'
function Trash(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 34 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M.634 4.705a4 4 0 014-4h25a4 4 0 014 4v23a4 4 0 01-4 4h-25a4 4 0 01-4-4v-23z'
        stroke='currentColor'
      />
      <path
        d='M23.47 5.705v11.33M23.47 22.664v4.04M21.117 22.664v4.04M25.708 22.664v4.04M18.806 21.676a4.664 4.664 0 019.328 0v4.53h-9.328v-4.53z'
        stroke='currentColor'
      />
      <ellipse cx={15.088} cy={25.739} rx={0.98} ry={0.967} fill='currentColor' />
      <ellipse cx={11.645} cy={22.998} rx={0.98} ry={0.967} fill='currentColor' />
      <ellipse cx={9.686} cy={19.561} rx={0.98} ry={0.967} fill='currentColor' />
      <ellipse cx={6.614} cy={22.575} rx={0.98} ry={0.967} fill='currentColor' />
      <ellipse cx={9.262} cy={25.739} rx={0.98} ry={0.967} fill='currentColor' />
      <path
        d='M4.634 1.705h25v-2h-25v2zm28 3v23h2v-23h-2zm-3 26h-25v2h25v-2zm-28-3v-23h-2v23h2zm3 3a3 3 0 01-3-3h-2a5 5 0 005 5v-2zm28-3a3 3 0 01-3 3v2a5 5 0 005-5h-2zm-3-26a3 3 0 013 3h2a5 5 0 00-5-5v2zm-25-2a5 5 0 00-5 5h2a3 3 0 013-3v-2z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Trash
