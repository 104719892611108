import { SVGProps } from 'react'
function MessageFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.7341 3.79611C11.4493 3.1163 11.0349 2.49841 10.5141 1.97687C9.99487 1.45441 9.37805 1.03903 8.6987 0.754309C8.00276 0.461214 7.25501 0.310974 6.49987 0.312512H6.47448C5.70641 0.31632 4.96374 0.468664 4.26422 0.768273C3.5907 1.05592 2.97967 1.47204 2.4653 1.99337C1.94953 2.51374 1.5399 3.12948 1.25924 3.80626C0.967905 4.51004 0.820283 5.26499 0.825064 6.02667C0.828872 6.90773 1.03961 7.78243 1.43317 8.56446V10.4942C1.43317 10.8166 1.69469 11.0781 2.01588 11.0781H3.94303C4.72871 11.4746 5.59572 11.6832 6.47575 11.6875H6.50241C7.26159 11.6875 7.99665 11.5402 8.68981 11.2521C9.36572 10.9707 9.98024 10.5602 10.4989 10.0435C11.0219 9.52423 11.4333 8.91739 11.7215 8.24073C12.0198 7.53995 12.1721 6.79474 12.1759 6.0254C12.1785 5.25226 12.0287 4.50196 11.7341 3.79611ZM3.96588 6.60939C3.63073 6.60939 3.35778 6.33644 3.35778 6.00001C3.35778 5.66359 3.63073 5.39064 3.96588 5.39064C4.30104 5.39064 4.57399 5.66359 4.57399 6.00001C4.57399 6.33644 4.30231 6.60939 3.96588 6.60939ZM6.49987 6.60939C6.16471 6.60939 5.89176 6.33644 5.89176 6.00001C5.89176 5.66359 6.16471 5.39064 6.49987 5.39064C6.83502 5.39064 7.10797 5.66359 7.10797 6.00001C7.10797 6.33644 6.83502 6.60939 6.49987 6.60939ZM9.03385 6.60939C8.6987 6.60939 8.42575 6.33644 8.42575 6.00001C8.42575 5.66359 8.6987 5.39064 9.03385 5.39064C9.36901 5.39064 9.64196 5.66359 9.64196 6.00001C9.64196 6.33644 9.36901 6.60939 9.03385 6.60939Z'
        fill={props.fill ?? '#423F4C'}
      />
    </svg>
  )
}

export default MessageFilled
