import { Icons } from '@invisible/ui/icons'
import { TIconName } from '@invisible/ui/icons'
import { Text } from '@invisible/ui/text'
import { styled } from '@invisible/ui/themes'
import * as CSS from 'csstype'
import { ElementType, FC, useMemo } from 'react'
import { Flex } from 'rebass'

const StatusBoxContainer = styled(Flex)`
  background: #130f1f;
  border: 1px solid rgba(96, 76, 165, 0.3);
  box-sizing: border-box;
  border-radius: 112px;
  padding: 6.5px 13px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  ${Text} {
    color: white;
  }
`

export interface StatusBoxProps {
  icon: TIconName
  iconColor?: CSS.Properties['color']
  text: string
}

export type TStatusBox = FC<StatusBoxProps>

const StatusBox: TStatusBox = ({ icon, iconColor, text, ...props }) => {
  const StatusIcon = styled(Icons[icon])`
    margin-right: 10px;
  ` as ElementType
  return (
    <StatusBoxContainer data-cy='statusBox' {...props}>
      <StatusIcon color={iconColor} /> <Text>{text}</Text>
    </StatusBoxContainer>
  )
}

type TStatusBoxes = FC<{
  items: StatusBoxProps[]
}>

const StatusBoxesContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-size: 14px;
  font-weight: normal;
`

const StatusBoxes: TStatusBoxes = ({ items }) => (
  <StatusBoxesContainer data-cy='statusBoxesContainer'>
    {items.map((item) => (
      <StatusBox key={item.text} {...item} />
    ))}
  </StatusBoxesContainer>
)

export { StatusBox, StatusBoxes }
