import { SVGProps } from 'react'
function History(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={15}
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M7.377 3.775h-.752A.125.125 0 006.5 3.9V8.2c0 .041.02.079.052.102l2.583 1.886c.056.04.134.03.175-.027l.447-.609a.123.123 0 00-.027-.175l-2.228-1.61V3.9a.125.125 0 00-.125-.125zM10.81 4.95l2.45.598a.125.125 0 00.155-.12l.012-2.523a.124.124 0 00-.201-.099L10.764 4.73a.125.125 0 00.047.22zm2.62 4.705l-.885-.305a.125.125 0 00-.158.075 5.491 5.491 0 01-1.28 1.994 5.518 5.518 0 01-6.06 1.186 5.517 5.517 0 01-1.759-1.186A5.542 5.542 0 012.103 9.66a5.523 5.523 0 01-.43-2.152 5.49 5.49 0 011.62-3.91 5.516 5.516 0 016.059-1.187 5.516 5.516 0 012.205 1.691l.934-.731A6.7 6.7 0 007.197.79 6.706 6.706 0 00.485 7.578a6.717 6.717 0 006.715 6.65 6.717 6.717 0 006.308-4.41.128.128 0 00-.076-.163z'
        fill='currentColor'
      />
    </svg>
  )
}

export default History
