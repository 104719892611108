import { If } from '@invisible/ui/conditional'
import {
  ToastCancelIcon,
  ToastErrorIcon,
  ToastInfoIcon,
  ToastSuccessIcon,
  ToastTriangleIcon,
} from '@invisible/ui/icons'
import { color, css, gray, keyframes, shadows, space, styled } from '@invisible/ui/themes'
import { FC, forwardRef, MutableRefObject } from 'react'
import { ToastProps } from 'react-toast-notifications'
import { ToastProvider, ToastProviderProps } from 'react-toast-notifications'
import { Flex } from 'rebass'

const slidein = keyframes`
  from { transform: translateX(100%) }
  to { transform: translateX(0) }
`

const slideout = keyframes`
  from { transform: translateX(0) }
  to { transform: translateX(100%) }
`

const shrink = keyframes`
  from { height: 100% } 
  to { height: 0% }
`

const CancelIconWrapper = styled.button`
  border: none;
  background: ${gray(1)};
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border-top-right-radius: ${space(1)};
  border-bottom-right-radius: ${space(1)};
  color: ${gray(6)};
  &:hover {
    color: ${gray(1)};
  }
`

const BaseToast = styled.div<{ transitionState: string }>`
  width: 447px;
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${gray(1)};
  box-shadow: ${shadows('large')};
  border-radius: ${space(1)};
  margin: 30px 10px 30px 0;
  position: relative;
  transition: transform ease-in-out;
  animation: ${({ transitionState }) =>
    transitionState === 'exiting'
      ? css`
          ${slideout} 0.5s
        `
      : css`
          ${slidein} 0.2s
        `};
`
const SuccessToast = styled(BaseToast)`
  border-left: 6px solid ${color('highlightGood')};
`
const WarningToast = styled(BaseToast)`
  border-left: 6px solid ${color('highlightYellow')};
`
const InfoToast = styled(BaseToast)`
  border-left: 6px solid ${color('highlightBlue')};
`
const ErrorToast = styled(BaseToast)`
  border-left: 6px solid ${color('lightred')};
`

const ShrinkingBar = styled.div<{
  autoDismissTimeout: number
  isRunning: boolean
}>`
  width: 6px;
  height: ${({ isRunning }) => (isRunning ? `0%` : `100%`)};
  border-top-right-radius: ${({ isRunning }) => (isRunning ? `4px` : `0px`)};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  animation: ${({ isRunning }) =>
    isRunning &&
    css`
      ${shrink} linear
    `};
  animation-duration: ${({ autoDismissTimeout }) => `${autoDismissTimeout}ms`};
  position: absolute;
  bottom: 0;
  left: -6px;
  z-index: 10000;
`
const SuccessBar = styled(ShrinkingBar)`
  background: ${color('darkgreen')};
`
const WarningBar = styled(ShrinkingBar)`
  background: ${color('warning')};
`
const InfoBar = styled(ShrinkingBar)`
  background: ${color('info')};
`
const ErrorBar = styled(ShrinkingBar)`
  background: ${color('danger')};
`

const Body = styled.div`
  width: 321px;
  font-size: 14px;
  line-height: ${space(3)};
  color: ${gray(10)};
  font-style: normal;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fontFamily};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const CustomToast: FC<ToastProps> = ({
  appearance,
  children,
  transitionState,
  onDismiss,
  autoDismissTimeout,
  isRunning,
}) => (
  <>
    <If conditional={appearance === 'success'}>
      <SuccessToast transitionState={transitionState} data-cy='toastSuccess'>
        <SuccessBar autoDismissTimeout={autoDismissTimeout} isRunning={isRunning} />
        <Flex alignItems='center' justifyContent='center' width='72px'>
          <ToastSuccessIcon />
        </Flex>
        <Body>{children}</Body>
        <CancelIconWrapper onClick={() => onDismiss()}>
          <ToastCancelIcon />
        </CancelIconWrapper>
      </SuccessToast>
    </If>

    <If conditional={appearance === 'warning'}>
      <WarningToast transitionState={transitionState} data-cy='toastWarning'>
        <WarningBar autoDismissTimeout={autoDismissTimeout} isRunning={isRunning} />
        <Flex alignItems='center' justifyContent='center' width='72px'>
          <ToastTriangleIcon />
        </Flex>
        <Body>{children}</Body>
        <CancelIconWrapper onClick={() => onDismiss()}>
          <ToastCancelIcon />
        </CancelIconWrapper>
      </WarningToast>
    </If>

    <If conditional={appearance === 'error'}>
      <ErrorToast transitionState={transitionState} data-cy='toastError'>
        <ErrorBar autoDismissTimeout={autoDismissTimeout} isRunning={isRunning} />
        <Flex alignItems='center' justifyContent='center' width='72px'>
          <ToastErrorIcon />
        </Flex>
        <Body>{children}</Body>
        <CancelIconWrapper onClick={() => onDismiss()}>
          <ToastCancelIcon />
        </CancelIconWrapper>
      </ErrorToast>
    </If>

    <If conditional={appearance === 'info'}>
      <InfoToast transitionState={transitionState} data-cy='toastInfo'>
        <InfoBar autoDismissTimeout={autoDismissTimeout} isRunning={isRunning} />
        <Flex alignItems='center' justifyContent='center' width='72px'>
          <ToastInfoIcon />
        </Flex>
        <Body>{children}</Body>
        <CancelIconWrapper onClick={() => onDismiss()}>
          <ToastCancelIcon />
        </CancelIconWrapper>
      </InfoToast>
    </If>
  </>
)

interface IProps extends ToastProviderProps {
  ref?: ((instance: unknown) => void) | MutableRefObject<unknown> | null
}

const CustomProvider: FC<IProps> = forwardRef(({ children, autoDismiss = true, ...props }, ref) => (
  <ToastProvider components={{ Toast: CustomToast }} autoDismiss={autoDismiss} ref={ref} {...props}>
    {children}
  </ToastProvider>
))

export { CustomToast, CustomProvider as ToastProvider }
export { useToasts } from 'react-toast-notifications'
