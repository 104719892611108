import { SVGProps } from 'react'
function Calendar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.75 1.125h-2.625v-1A.125.125 0 0010 0h-.875A.125.125 0 009 .125v1H5v-1A.125.125 0 004.875 0H4a.125.125 0 00-.125.125v1H1.25a.5.5 0 00-.5.5V12a.5.5 0 00.5.5h11.5a.5.5 0 00.5-.5V1.625a.5.5 0 00-.5-.5zm-.625 10.25H1.875V5.437h10.25v5.938zm-10.25-7V2.25h2V3c0 .069.056.125.125.125h.875A.125.125 0 005 3v-.75h4V3c0 .069.056.125.125.125H10A.125.125 0 0010.125 3v-.75h2v2.125H1.875z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Calendar
