import { SVGProps } from 'react'
function Vpn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M24 3L6 9V27C6 36.9422 14.0578 45 24 45C33.9422 45 42 36.9422 42 27V9L24 3ZM38.625 27C38.625 35.0766 32.0766 41.625 24 41.625C15.9234 41.625 9.375 35.0766 9.375 27V11.5312L24 6.375L38.625 11.5312V27Z'
        fill='currentColor'
      />
      <path
        d='M17.7353 22.2646C17.5788 22.1075 17.3929 21.9828 17.1882 21.8977C16.9835 21.8127 16.764 21.7689 16.5423 21.7689C16.3206 21.7689 16.1011 21.8127 15.8963 21.8977C15.6916 21.9828 15.5057 22.1075 15.3493 22.2646C15.1922 22.421 15.0675 22.6069 14.9824 22.8116C14.8974 23.0164 14.8536 23.2359 14.8536 23.4576C14.8536 23.6793 14.8974 23.8988 14.9824 24.1035C15.0675 24.3082 15.1922 24.4941 15.3493 24.6505L21.4149 30.7162L21.5134 30.8146C21.6613 30.9628 21.837 31.0804 22.0305 31.1607C22.2239 31.2409 22.4313 31.2822 22.6407 31.2822C22.8501 31.2822 23.0575 31.2409 23.251 31.1607C23.4444 31.0804 23.6201 30.9628 23.7681 30.8146L34.2446 20.338C34.3928 20.1901 34.5104 20.0144 34.5907 19.8209C34.6709 19.6275 34.7122 19.4201 34.7122 19.2107C34.7122 19.0013 34.6709 18.7939 34.5907 18.6005C34.5104 18.407 34.3928 18.2313 34.2446 18.0834L34.1134 17.9521C33.9654 17.8039 33.7897 17.6863 33.5963 17.6061C33.4028 17.5258 33.1955 17.4845 32.986 17.4845C32.7766 17.4845 32.5692 17.5258 32.3758 17.6061C32.1824 17.6863 32.0066 17.8039 31.8587 17.9521L22.6384 27.1677L17.7353 22.2646Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Vpn
