import { logout } from '@invisible/authentication/internal'
import { LoadingModal } from '@invisible/ui/loading-modal'
import { useEffect } from 'react'

export const SignOutPage = () => {
  useEffect(() => {
    logout()
  }, [])
  return <LoadingModal text='Signing you out...' />
}
