import { SVGProps } from 'react'
function AddRow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M1.5 37.9688C1.31396 37.9688 1.12793 37.9347 0.949951 37.8647C0.376099 37.6388 0 37.0847 0 36.4688V21.4688C0 20.8528 0.376099 20.2987 0.949951 20.0728C1.526 19.8468 2.17603 19.9929 2.59607 20.4448L9.59583 27.9448C10.136 28.5209 10.136 29.4148 9.59583 29.9908L2.59607 37.4908C2.30603 37.8029 1.90796 37.9688 1.5 37.9688ZM3 25.2748V32.6627L6.44788 28.9688L3 25.2748Z'
        fill='currentColor'
      />
      <path
        d='M44.5001 45.9686H13.5C11.57 45.9686 10.0001 44.3987 10.0001 42.4688V37.4689C10.0001 35.5386 11.57 33.9686 13.5 33.9686H44.5001C46.43 33.9686 48 35.5386 48 37.4689V42.4688C48 44.3987 46.43 45.9686 44.5001 45.9686ZM13.5 36.9686C13.2239 36.9686 13.0001 37.1927 13.0001 37.4689V42.4688C13.0001 42.7449 13.2239 42.9686 13.5 42.9686H44.5001C44.7759 42.9686 45 42.7449 45 42.4688V37.4689C45 37.1927 44.7759 36.9686 44.5001 36.9686H13.5Z'
        fill='currentColor'
      />
      <path
        d='M44.5001 23.9689H13.5C11.57 23.9689 10.0001 22.3989 10.0001 20.4686V5.46863C10.0001 3.5387 11.57 1.96875 13.5 1.96875H44.5001C46.43 1.96875 48 3.5387 48 5.46863V20.4686C48 22.3989 46.43 23.9689 44.5001 23.9689ZM13.5 4.96875C13.2239 4.96875 13.0001 5.19287 13.0001 5.46863V20.4686C13.0001 20.7448 13.2239 20.9689 13.5 20.9689H44.5001C44.7759 20.9689 45 20.7448 45 20.4686V5.46863C45 5.19287 44.7759 4.96875 44.5001 4.96875H13.5Z'
        fill='currentColor'
      />
      <path
        d='M28.9999 45.9686C28.1719 45.9686 27.4999 45.2966 27.4999 44.4686V35.4686C27.4999 34.6406 28.1719 33.9686 28.9999 33.9686C29.8279 33.9686 30.4999 34.6406 30.4999 35.4686V44.4686C30.4999 45.2966 29.8279 45.9686 28.9999 45.9686Z'
        fill='currentColor'
      />
      <path
        d='M28.9999 23.9689C28.1719 23.9689 27.4999 23.2969 27.4999 22.4689V3.46875C27.4999 2.64075 28.1719 1.96875 28.9999 1.96875C29.8279 1.96875 30.4999 2.64075 30.4999 3.46875V22.4689C30.4999 23.2969 29.8279 23.9689 28.9999 23.9689Z'
        fill='currentColor'
      />
      <path
        d='M46.5 14.4686H11.5001C10.6721 14.4686 10.0001 13.7966 10.0001 12.9686C10.0001 12.1406 10.6721 11.4686 11.5001 11.4686H46.5C47.328 11.4686 48 12.1406 48 12.9686C48 13.7966 47.328 14.4686 46.5 14.4686Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default AddRow
