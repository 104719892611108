import { Severity } from '@sentry/types'

export const LOG_LEVEL = (process.env.LOG_LEVEL as Severity) ?? Severity.Warning
export const SENTRY_DSN = process.env.SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN
export const SENTRY_SAMPLE_RATE = Number(
  process.env.SENTRY_SAMPLE_RATE ?? process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE ?? 0.2
)
export const SENTRY_TRACE_SAMPLE_RATE = Number(
  process.env.SENTRY_TRACE_SAMPLE_RATE ?? process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE ?? 0.2
)
