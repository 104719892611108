import { SVGProps } from 'react'
function Validate(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M44 6H4C1.8 6 0 7.8 0 10V38C0 40.2 1.8 42 4 42H44C46.2 42 47.98 40.2 47.98 38L48 10C48 7.8 46.2 6 44 6ZM45 39H3V9H45V39ZM42 12H28V22H42V12ZM40 16L35 19.5L30 16V14L35 17.5L40 14V16ZM18 24C21.3 24 24 21.3 24 18C24 14.7 21.3 12 18 12C14.7 12 12 14.7 12 18C12 21.3 14.7 24 18 24ZM18 16C19.1 16 20 16.9 20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16ZM30 33.18C30 28.18 22.06 26.02 18 26.02C13.94 26.02 6 28.18 6 33.18V36H30V33.18ZM10.96 32C12.44 31 15.4 30 18 30C20.6 30 23.54 30.98 25.04 32H10.96Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Validate
