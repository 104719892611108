import { SVGProps } from 'react'
function Trophy(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={15}
      viewBox='0 0 16 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M14.357 1.217h-1.642V.502a.143.143 0 00-.143-.143H3.429a.143.143 0 00-.143.143v.715H1.643a.786.786 0 00-.786.785v2.643a2.933 2.933 0 002.468 2.893 4.577 4.577 0 004.032 3.937v1.879h-3.5a.57.57 0 00-.571.571v.577c0 .079.064.143.143.143h9.143a.143.143 0 00.143-.143v-.577a.57.57 0 00-.572-.571h-3.5v-1.879a4.577 4.577 0 004.032-3.937 2.933 2.933 0 002.468-2.893V2.002a.785.785 0 00-.786-.785zM2.143 4.645V2.502h1.143V6.21a1.643 1.643 0 01-1.143-1.564zm9.286 2.286a3.26 3.26 0 01-.963 2.323 3.264 3.264 0 01-2.323.963h-.286a3.26 3.26 0 01-2.323-.963 3.263 3.263 0 01-.962-2.323V1.645h6.857v5.286zm2.428-2.286c0 .732-.48 1.354-1.142 1.564V2.502h1.142v2.143z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Trophy
