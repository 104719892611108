import { SVGProps } from 'react'
function Download(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M23.662 32.33a.429.429 0 00.675 0l6-7.591a.427.427 0 00-.337-.692h-3.97V5.92a.43.43 0 00-.429-.429h-3.214a.43.43 0 00-.428.429v18.123h-3.96a.427.427 0 00-.337.691l6 7.597zm19.945-1.875h-3.215a.43.43 0 00-.428.428v8.25H8.035v-8.25a.43.43 0 00-.428-.428H4.392a.43.43 0 00-.428.428V41.49c0 .948.766 1.715 1.714 1.715h36.643c.948 0 1.714-.767 1.714-1.715V30.883a.43.43 0 00-.428-.428z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Download
