import { SVGProps } from 'react'
function Save(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M33.3207 9.21785L26.7877 2.68481C26.4863 2.38347 26.1167 2.16249 25.7149 2.04195V1.93347H3.21491C2.50375 1.93347 1.9292 2.50803 1.9292 3.21919V32.7906C1.9292 33.5018 2.50375 34.0763 3.21491 34.0763H32.7863C33.4975 34.0763 34.0721 33.5018 34.0721 32.7906V11.0339C34.0721 10.3509 33.8029 9.69999 33.3207 9.21785ZM12.8578 4.82633H23.1435V9.0049H12.8578V4.82633ZM31.1792 31.1835H4.82206V4.82633H10.2863V10.2906C10.2863 11.0018 10.8609 11.5763 11.5721 11.5763H24.4292C25.1404 11.5763 25.7149 11.0018 25.7149 10.2906V5.70222L31.1792 11.1665V31.1835ZM18.0006 15.1924C14.8064 15.1924 12.2149 17.7839 12.2149 20.9781C12.2149 24.1723 14.8064 26.7638 18.0006 26.7638C21.1948 26.7638 23.7863 24.1723 23.7863 20.9781C23.7863 17.7839 21.1948 15.1924 18.0006 15.1924ZM18.0006 24.1924C16.2247 24.1924 14.7863 22.754 14.7863 20.9781C14.7863 19.2022 16.2247 17.7638 18.0006 17.7638C19.7765 17.7638 21.2149 19.2022 21.2149 20.9781C21.2149 22.754 19.7765 24.1924 18.0006 24.1924Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Save
