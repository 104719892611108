import { SVGProps } from 'react'
function Instructions(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.1876 5.99889H11.3751C11.3113 5.99889 11.259 6.05112 11.259 6.11496V10.7578H1.74118V1.23996H6.38403C6.44787 1.23996 6.5001 1.18772 6.5001 1.12388V0.311384C6.5001 0.247545 6.44787 0.195312 6.38403 0.195312H1.16082C0.904011 0.195312 0.696533 0.40279 0.696533 0.659598V11.3382C0.696533 11.595 0.904011 11.8025 1.16082 11.8025H11.8394C12.0962 11.8025 12.3037 11.595 12.3037 11.3382V6.11496C12.3037 6.05112 12.2514 5.99889 12.1876 5.99889Z'
        fill='#604CA5'
      />
      <path
        d='M4.23535 6.33114L4.20778 8.05625C4.20633 8.18538 4.31079 8.29129 4.43992 8.29129H4.44572L6.15778 8.24922C6.1868 8.24777 6.21581 8.23616 6.23613 8.21585L12.2704 2.19464C12.3154 2.14967 12.3154 2.07567 12.2704 2.03069L10.4669 0.228683C10.4437 0.205469 10.4147 0.195312 10.3842 0.195312C10.3538 0.195312 10.3247 0.20692 10.3015 0.228683L4.26872 6.24989C4.24776 6.2718 4.23584 6.30082 4.23535 6.33114V6.33114ZM5.15666 6.67355L10.3842 1.45759L11.04 2.11194L5.80956 7.3308L5.14651 7.34676L5.15666 6.67355V6.67355Z'
        fill='#423F4C'
      />
    </svg>
  )
}

export default Instructions
