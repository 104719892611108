import { SVGProps } from 'react'
function RightArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M.835.41l5.51 5.2-5.51 5.2' stroke='currentColor' />
    </svg>
  )
}

export default RightArrow
