import { SVGProps } from 'react'
function GiftBox(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14.572 4.395h-2.636c.243-.382.386-.836.386-1.321A2.462 2.462 0 0 0 8 1.456a2.462 2.462 0 0 0-4.322 1.618c0 .485.14.939.385 1.321H1.43a.57.57 0 0 0-.572.571v3.572c0 .078.065.143.143.143h.714v6.143a.57.57 0 0 0 .572.571h11.428a.57.57 0 0 0 .572-.571V8.68H15a.143.143 0 0 0 .143-.143V4.966a.57.57 0 0 0-.571-.571ZM8.607 3.074a1.251 1.251 0 0 1 2.5 0c0 .689-.56 1.25-1.25 1.25h-1.25v-1.25Zm-2.464-1.25c.69 0 1.25.56 1.25 1.25v1.25h-1.25a1.251 1.251 0 0 1 0-2.5ZM2.072 7.466V5.61h5.321v1.857H2.072Zm.857 1.215h4.464v5.5H2.929v-5.5Zm10.143 5.5H8.607v-5.5h4.465v5.5Zm.857-6.715H8.607V5.61h5.322v1.857Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default GiftBox
