import { SVGProps } from 'react'
function Hashtag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={13}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M17.035 6.36747C17.1332 6.36747 17.2136 6.28711 17.2136 6.1889V4.84961C17.2136 4.7514 17.1332 4.67104 17.035 4.67104H13.3742V0.965681C13.3742 0.867467 13.2938 0.787109 13.1956 0.787109H11.767C11.6688 0.787109 11.5884 0.867467 11.5884 0.965681V4.67104H6.49906V0.965681C6.49906 0.867467 6.4187 0.787109 6.32049 0.787109H4.89189C4.79367 0.787109 4.71331 0.867467 4.71331 0.965681V4.67104H0.963243C0.865027 4.67104 0.784668 4.7514 0.784668 4.84961V6.1889C0.784668 6.28711 0.865027 6.36747 0.963243 6.36747H4.71331V11.6353H0.963243C0.865027 11.6353 0.784668 11.7157 0.784668 11.8139V13.1532C0.784668 13.2514 0.865027 13.3318 0.963243 13.3318H4.71331V17.0371C4.71331 17.1353 4.79367 17.2157 4.89189 17.2157H6.32049C6.4187 17.2157 6.49906 17.1353 6.49906 17.0371V13.3318H11.5884V17.0371C11.5884 17.1353 11.6688 17.2157 11.767 17.2157H13.1956C13.2938 17.2157 13.3742 17.1353 13.3742 17.0371V13.3318H17.035C17.1332 13.3318 17.2136 13.2514 17.2136 13.1532V11.8139C17.2136 11.7157 17.1332 11.6353 17.035 11.6353H13.3742V6.36747H17.035ZM11.5884 11.6353H6.49906V6.36747H11.5884V11.6353Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Hashtag
