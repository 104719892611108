import { SVGProps } from 'react'
function EnvelopePlus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M7.02833 5.25C4.82244 5.25 3 7.07244 3 9.27833V29.7217C3 31.9276 4.82244 33.75 7.02833 33.75H24.1201C24.8539 38.8237 29.2293 42.75 34.5 42.75C40.2812 42.75 45 38.0312 45 32.25C45 28.0797 42.5384 24.4734 39 22.7812V9.27833C39 7.07244 37.1776 5.25 34.9717 5.25H7.02833ZM35.1211 8.24997L22.2803 19.5263C21.4543 20.2516 20.5457 20.2516 19.7197 19.5263L6.94629 8.3115C16.4643 8.18805 25.9397 8.2458 35.1211 8.25V8.24997ZM6 11.4727L17.7393 21.7793C19.5969 23.4103 22.4031 23.4103 24.2607 21.7793L36 11.4727V21.8701C35.5086 21.799 35.0105 21.75 34.5 21.75C29.2293 21.75 24.8539 25.6763 24.1201 30.75H7.02833C6.43256 30.75 6 30.3174 6 29.7217V11.4727ZM34.5 24.75C38.6599 24.75 42 28.0901 42 32.25C42 36.4099 38.6599 39.75 34.5 39.75C30.3401 39.75 27 36.4099 27 32.25C27 28.0901 30.3401 24.75 34.5 24.75Z'
        fill='currentColor'
      />
      <path
        d='M34.5 27.7354C34.1021 27.7354 33.7206 27.8934 33.4393 28.1747C33.158 28.456 33 28.8375 33 29.2354V30.7529H31.4824C31.0846 30.7529 30.703 30.911 30.4217 31.1923C30.1404 31.4736 29.9824 31.8551 29.9824 32.2529C29.9824 32.6508 30.1404 33.0323 30.4217 33.3136C30.703 33.5949 31.0846 33.7529 31.4824 33.7529H33V35.2705C33 35.6683 33.158 36.0499 33.4393 36.3312C33.7206 36.6125 34.1021 36.7705 34.5 36.7705C34.8978 36.7705 35.2793 36.6125 35.5606 36.3312C35.8419 36.0499 36 35.6683 36 35.2705V33.7529H37.5176C37.9154 33.7529 38.2969 33.5949 38.5782 33.3136C38.8595 33.0323 39.0176 32.6508 39.0176 32.2529C39.0176 31.8551 38.8595 31.4736 38.5782 31.1923C38.2969 30.911 37.9154 30.7529 37.5176 30.7529H36V29.2354C36 28.8375 35.8419 28.456 35.5606 28.1747C35.2793 27.8934 34.8978 27.7354 34.5 27.7354Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default EnvelopePlus
