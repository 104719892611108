import { datadogRum } from '@datadog/browser-rum'

import {
  NEXT_PUBLIC_DATADOG_APP_ID,
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  NEXT_PUBLIC_DATADOG_SAMPLE_RATE,
  NEXT_PUBLIC_DATADOG_SERVICE_NAME,
} from '../config/env'

if (process.browser && NEXT_PUBLIC_DATADOG_APP_ID && NEXT_PUBLIC_DATADOG_CLIENT_TOKEN)
  datadogRum.init({
    applicationId: NEXT_PUBLIC_DATADOG_APP_ID,
    clientToken: NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: NEXT_PUBLIC_DATADOG_SERVICE_NAME,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: NEXT_PUBLIC_DATADOG_SAMPLE_RATE,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

datadogRum.startSessionReplayRecording()
