import { SVGProps } from 'react'
function PortalLaunchpad(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={15}
      viewBox='0 0 12 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.5 11.005A4.373 4.373 0 009 7.053v-2.59a.991.991 0 00-.236-.644L6.383.995a.5.5 0 00-.766 0L3.236 3.82A1 1 0 003 4.462v2.591a4.373 4.373 0 00-2.5 3.952h2.445a1.208 1.208 0 00-.054.371 1.517 1.517 0 001.007 1.429A2.278 2.278 0 006 14.192c.455 0 .895-.134 1.272-.387.369-.247.655-.593.828-1a1.515 1.515 0 001.008-1.427c0-.131-.017-.256-.049-.372H11.5v-.001zM9.911 9.214c.147.228.266.473.352.728H8.938V8.234c.39.255.721.589.973.98zM4.063 7.053V4.486L6 2.189l1.938 2.297v5.456H4.063V7.053zm-2.325 2.89a3.325 3.325 0 011.325-1.708v1.707H1.738zm6.076 1.815a.421.421 0 01-.267.053l-.305-.038-.043.303A1.216 1.216 0 016 13.116a1.216 1.216 0 01-1.198-1.04l-.044-.304-.305.039a.44.44 0 01-.488-.436c0-.166.093-.303.229-.372h3.614c.137.07.228.208.228.372a.443.443 0 01-.222.383zM5.25 5.755a.75.75 0 101.5 0 .75.75 0 00-1.5 0z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PortalLaunchpad
