import { SVGProps } from 'react'
function SettingsFull(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.011 9.155a2.6 2.6 0 00-1.854.769 2.61 2.61 0 00-.768 1.854c0 .7.274 1.357.768 1.854a2.61 2.61 0 001.854.768c.701 0 1.357-.274 1.854-.768a2.61 2.61 0 00.769-1.854c0-.701-.274-1.357-.769-1.854a2.6 2.6 0 00-1.854-.769zm9.663 5.52l-1.532-1.31a8.417 8.417 0 000-2.705l1.532-1.31a.75.75 0 00.218-.825l-.02-.061a10.37 10.37 0 00-1.866-3.228l-.042-.049a.753.753 0 00-.823-.223l-1.903.678a8.183 8.183 0 00-2.335-1.348l-.367-1.99a.752.752 0 00-.605-.602l-.063-.012a10.562 10.562 0 00-3.722 0l-.064.012a.751.751 0 00-.604.602l-.37 2a8.284 8.284 0 00-2.319 1.342l-1.917-.682a.75.75 0 00-.822.223l-.043.05a10.451 10.451 0 00-1.865 3.227l-.021.06a.753.753 0 00.218.826l1.551 1.324a8.24 8.24 0 000 2.672L2.343 14.67a.751.751 0 00-.218.825l.021.06a10.398 10.398 0 001.866 3.228l.042.05a.753.753 0 00.823.222l1.917-.682a8.115 8.115 0 002.318 1.343l.37 2a.751.751 0 00.605.601l.063.012c1.231.222 2.491.222 3.722 0l.063-.012a.751.751 0 00.605-.602l.368-1.99a8.239 8.239 0 002.335-1.347l1.903.677a.751.751 0 00.822-.223l.043-.049a10.452 10.452 0 001.865-3.227l.021-.061a.757.757 0 00-.223-.82zm-9.663 1.223a4.12 4.12 0 110-8.24 4.12 4.12 0 010 8.24z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SettingsFull
