import { SVGProps } from 'react'
function Activities(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={13}
      fill='none'
      viewBox='0 0 14 13'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.75.258H1.25a.5.5 0 00-.5.5v11.5a.5.5 0 00.5.5h11.5a.5.5 0 00.5-.5V.758a.5.5 0 00-.5-.5zm-.625 11.375H1.875V1.383h10.25v10.25zM3.762 6.696h.793a.106.106 0 00.106-.107V5.356c0-.307.248-.556.555-.556H8.43v.835c0 .089.101.137.17.082l1.705-1.339a.106.106 0 000-.167L8.6 2.872a.105.105 0 00-.17.083v.834H5.214c-.86 0-1.558.7-1.558 1.564v1.233c0 .063.047.11.107.11zm-.065 2.107l1.705 1.34c.068.054.17.004.17-.083v-.835h3.214c.86 0 1.558-.7 1.558-1.564V6.428a.106.106 0 00-.106-.106h-.793a.106.106 0 00-.106.106v1.233a.555.555 0 01-.555.556H5.572v-.834a.105.105 0 00-.17-.083L3.697 8.64a.103.103 0 000 .163z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Activities
