import { SVGProps } from 'react'
function CopyPaste(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M41.142.357H12.427A.43.43 0 0012 .786v3a.43.43 0 00.428.428H39v36.857a.43.43 0 00.428.429h3a.43.43 0 00.429-.429v-39c0-.948-.766-1.714-1.714-1.714zm-6.858 6.857H6.857c-.948 0-1.714.766-1.714 1.715v28.43c0 .455.182.89.503 1.21l9.284 9.285c.118.117.252.214.397.294v.102h.225c.187.07.385.107.589.107h18.145c.948 0 1.714-.766 1.714-1.714V8.929c0-.949-.766-1.715-1.715-1.715zM15.32 42.796l-4.612-4.617h4.612v4.617zM32.142 44.5H18.749v-7.607a2.142 2.142 0 00-2.143-2.143H8.999V11.071h23.143V44.5z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CopyPaste
