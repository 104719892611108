import { SVGProps } from 'react'
function Edit(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={36}
      height={36}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M7.782 27.64c.08 0 .16-.009.241-.021l6.758-1.185a.393.393 0 00.213-.113L32.026 9.29a.4.4 0 000-.567l-6.678-6.682a.398.398 0 00-.285-.116.397.397 0 00-.285.116L7.746 19.073a.408.408 0 00-.113.213l-1.185 6.758a1.346 1.346 0 00.378 1.197c.265.258.599.398.956.398zm2.708-7.008L25.063 6.063l2.945 2.945-14.573 14.569-3.572.63.627-3.575zm22.295 10.382H3.214c-.711 0-1.286.575-1.286 1.286v1.446c0 .177.145.322.322.322h31.5a.322.322 0 00.321-.322V32.3c0-.711-.575-1.286-1.286-1.286z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Edit
