import { SVGProps } from 'react'
function PortalProcesses(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={15}
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.187 9.503h-1.625V7.128a.125.125 0 00-.125-.125H7.562v-1.5H9.25a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-4.5a.25.25 0 00-.25.25v4.5c0 .137.112.25.25.25h1.687v1.5H2.562a.125.125 0 00-.125.125v2.375H.812a.25.25 0 00-.25.25v4.5c0 .137.113.25.25.25h4.5a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-1.75V8.128h6.875v1.375h-1.75a.25.25 0 00-.25.25v4.5c0 .137.113.25.25.25h4.5a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25zM4.375 10.69v2.625H1.75V10.69h2.625zm1.312-6.375V1.69h2.625v2.625H5.687zm6.563 9H9.625V10.69h2.625v2.625z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PortalProcesses
