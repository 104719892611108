import { SVGProps } from 'react'
function ArrowUndo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M14.0003 13.7524C13.9244 13.7524 13.8494 13.7351 13.7811 13.7018C13.7128 13.6685 13.6531 13.6201 13.6063 13.5602C12.8919 12.6455 12.2291 11.9396 11.315 11.4664C10.4691 11.0299 9.40659 10.8021 8.00034 10.7596V13.2524C8.00005 13.35 7.97119 13.4453 7.91734 13.5267C7.86349 13.6081 7.78699 13.672 7.69729 13.7104C7.60758 13.7489 7.50858 13.7602 7.41251 13.7431C7.31643 13.7259 7.22747 13.681 7.15659 13.6139L1.65659 8.36394C1.60772 8.31726 1.56882 8.26115 1.54224 8.199C1.51566 8.13686 1.50195 8.06997 1.50195 8.00238C1.50195 7.93479 1.51566 7.8679 1.54224 7.80576C1.56882 7.74361 1.60772 7.6875 1.65659 7.64082L7.15659 2.39082C7.22747 2.32372 7.31643 2.27883 7.41251 2.26168C7.50858 2.24454 7.60758 2.25588 7.69729 2.29433C7.78699 2.33277 7.86349 2.39662 7.91734 2.47801C7.97119 2.55941 8.00005 2.65478 8.00034 2.75238V5.26363C10.3172 5.37019 12.0435 6.22957 13.1363 7.82207C14.0416 9.14113 14.5003 10.968 14.5003 13.2524C14.5003 13.385 14.4477 13.5122 14.3539 13.6059C14.2601 13.6997 14.133 13.7524 14.0003 13.7524Z'
        fill={props.fill ?? '#604CA5'}
      />
    </svg>
  )
}

export default ArrowUndo
