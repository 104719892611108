import { createContext } from 'react'

import RoxContainer from './container'
import RoxService from './service'

RoxService(RoxContainer)

const FlagContext = createContext(RoxContainer)

export default FlagContext
