import { SVGProps } from 'react'

function FilledCircledDash(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <circle cx='8' cy='8' r='8' fill='currentColor' />
      <rect x='2.46155' y='6.76923' width='11.0769' height='2.46154' fill='white' />
    </svg>
  )
}

export default FilledCircledDash
