import { SVGProps } from 'react'
function Close(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={36}
      height={36}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M20.08 17.998L30.629 5.426a.32.32 0 00-.246-.526h-3.206a.654.654 0 00-.494.229l-8.699 10.37-8.698-10.37a.642.642 0 00-.495-.23H5.584a.32.32 0 00-.245.527l10.547 12.572L5.339 30.57a.32.32 0 00.245.526H8.79c.19 0 .37-.084.495-.229l8.698-10.37 8.699 10.37c.12.145.301.23.494.23h3.206a.32.32 0 00.245-.527L20.082 17.998z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Close
