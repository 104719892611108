import { LoadingModal } from '@invisible/ui/loading-modal'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/client'

import SignInPage from './SignInPage'

export const withAuth = (InnerComponent: NextPage) => {
  const Page: NextPage = (props) => {
    const { asPath } = useRouter()
    const [session, loading] = useSession()
    if (loading) return <LoadingModal text='Signing you in...' />
    if (!session?.user) {
      return <SignInPage redirectTo={asPath} />
    }
    return <InnerComponent {...props} />
  }

  return Page
}
