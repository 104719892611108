import { SVGProps } from 'react'
function Flag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={13}
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M17.215 4.88225H11.5006V2.35993C11.5006 1.96484 11.1814 1.64565 10.7863 1.64565H1.67895V0.75279C1.67895 0.654576 1.59859 0.574219 1.50038 0.574219H0.250352C0.152136 0.574219 0.0717773 0.654576 0.0717773 0.75279V18.2528C0.0717773 18.351 0.152136 18.4314 0.250352 18.4314H1.50038C1.59859 18.4314 1.67895 18.351 1.67895 18.2528V12.3599H7.21477V14.8823C7.21477 15.2773 7.53397 15.5965 7.92907 15.5965H17.215C17.6101 15.5965 17.9293 15.2773 17.9293 14.8823V5.59654C17.9293 5.20145 17.6101 4.88225 17.215 4.88225ZM1.67895 10.7528V3.25279H9.89339V10.7528H1.67895ZM16.3221 13.9894H8.82194V12.3599H11.322C11.4202 12.3599 11.5006 12.2796 11.5006 12.1814V6.4894H16.3221V13.9894Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Flag
