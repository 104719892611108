import { SVGProps } from 'react'
function ArrowBack(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={31}
      height={12}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 31 12'
      {...props}>
      <path d='M30.834 6.436H.96M6.471 11.636l-5.51-5.2 5.51-5.2' stroke='currentColor' />
    </svg>
  )
}

export default ArrowBack
