import { SVGProps } from 'react'
function SortIconDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width ?? '30'}
      height={props.width ?? '30'}
      viewBox='0 0 30 30'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3684_78220)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.535714 20.1027H14.4643C14.7602 20.1027 15 20.3265 15 20.6027V23.6027C15 23.8788 14.7602 24.1027 14.4643 24.1027H0.535714C0.239847 24.1027 0 23.8788 0 23.6027V20.6027C0 20.3265 0.239847 20.1027 0.535714 20.1027ZM0.535714 3H25.1786C25.4744 3 25.7143 3.22386 25.7143 3.5V6.5C25.7143 6.77614 25.4744 7 25.1786 7H0.535714C0.239847 7 0 6.77614 0 6.5V3.5C0 3.22386 0.239847 3 0.535714 3ZM0.535714 11.5513H17.6786C17.9744 11.5513 18.2143 11.7752 18.2143 12.0513V15.0513C18.2143 15.3275 17.9744 15.5513 17.6786 15.5513H0.535714C0.239847 15.5513 0 15.3275 0 15.0513V12.0513C0 11.7752 0.239847 11.5513 0.535714 11.5513ZM24.0804 11.55H25.4223C25.7182 11.55 25.958 11.7739 25.958 12.05V22.9053L28.132 20.9209C28.3408 20.7303 28.6747 20.731 28.8827 20.9224L29.8428 21.8061C30.0534 22 30.0558 22.3166 29.848 22.5132C29.8463 22.5149 29.8445 22.5165 29.8428 22.5181L25.1276 26.8584C24.919 27.0504 24.5838 27.0504 24.3752 26.8584L19.6613 22.5194C19.4511 22.3258 19.4483 22.0099 19.6551 21.8132L20.6129 20.9022C20.8201 20.7051 21.1593 20.7021 21.3705 20.8955L21.3714 20.8964L23.5447 22.8969V12.05C23.5447 11.7739 23.7846 11.55 24.0804 11.55Z'
          fill={props.fill ?? '#604CA5'}
        />
      </g>
      <defs>
        <clipPath id='clip0_3684_78220'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SortIconDown
