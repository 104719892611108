import { SVGProps } from 'react'
function Grid(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M7.143 1.43H1.714a.287.287 0 00-.285.285v5.429c0 .157.128.286.285.286h5.429a.287.287 0 00.286-.286V1.715a.287.287 0 00-.286-.285zm-.929 4.785H2.643V2.644h3.571v3.571zm8.072-4.785H8.857a.287.287 0 00-.285.285v5.429c0 .157.128.286.285.286h5.429a.287.287 0 00.286-.286V1.715a.287.287 0 00-.286-.285zm-.929 4.785H9.786V2.644h3.571v3.571zM7.143 8.573H1.714a.287.287 0 00-.285.285v5.429c0 .157.128.285.285.285h5.429a.287.287 0 00.286-.285V8.858a.287.287 0 00-.286-.285zm-.929 4.785H2.643V9.787h3.571v3.571zm8.072-4.785H8.857a.287.287 0 00-.285.285v5.429c0 .157.128.285.285.285h5.429a.287.287 0 00.286-.285V8.858a.287.287 0 00-.286-.285zm-.929 4.785H9.786V9.787h3.571v3.571z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Grid
