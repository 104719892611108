import '@invisible/segment'
import '@invisible/utils/datadog'

import { Provider as AuthenticationProvider } from '@invisible/authentication/client'
import { FlagContext, RoxContainer } from '@invisible/rollout'
import { ErrorBoundary } from '@invisible/sentry/client'
import { light, ThemeProvider } from '@invisible/ui/themes'
import { ToastProvider } from '@invisible/ui/toasts'
import { AppType } from 'next/dist/shared/lib/utils'
import Head from 'next/head'
import { FC } from 'react'

interface Options {
  Layout?: FC
  pageTitle?: string
}

export const createApp: (options: Options) => AppType =
  ({ Layout = ({ children }) => <main>{children}</main>, pageTitle }) =>
  ({ Component, pageProps }) =>
    (
      <ErrorBoundary>
        <FlagContext.Provider value={RoxContainer}>
          <ThemeProvider theme={light}>
            <ToastProvider>
              <AuthenticationProvider session={pageProps?.session}>
                <Layout>
                  {pageTitle ? (
                    <Head>
                      <title>{pageTitle}</title>
                    </Head>
                  ) : null}
                  <Component {...pageProps} />
                </Layout>
              </AuthenticationProvider>
            </ToastProvider>
          </ThemeProvider>
        </FlagContext.Provider>
      </ErrorBoundary>
    )
