import { SVGProps } from 'react'
function Lock(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.716 7.148h-1.214v-4A2.286 2.286 0 0010.216.862H5.788a2.286 2.286 0 00-2.286 2.286v4H2.288a.57.57 0 00-.572.571v6.858a.57.57 0 00.572.571h11.428a.57.57 0 00.572-.571V7.719a.57.57 0 00-.572-.571zm-8.928-4a1 1 0 011-1h4.428a1 1 0 011 1v4H4.788v-4zm8.214 10.714h-10V8.434h10v5.428zm-5.5-2.482v.947c0 .078.064.142.143.142h.714a.143.143 0 00.143-.142v-.947a.858.858 0 10-1 0z'
        fill='currentColor'
        fillOpacity={0.85}
      />
    </svg>
  )
}

export default Lock
