import { SVGProps } from 'react'
function LoginSecure(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0)'>
        <path
          d='M47.8912 41.9214L44.2773 30.2168V5.34597C44.2773 4.05388 43.2884 3 42.0758 3H7.01062C5.79809 3 4.8091 4.05388 4.8091 5.34597V12.3766H7.99963V6H40.9996C40.9996 15.2278 40.9996 19.9279 40.9996 29.1557H7.99963V22.9948H4.8091V30.2132L1.19521 41.9214C0.975061 42.6324 1.09022 43.4228 1.50343 44.0291C1.91664 44.6355 2.58387 45 3.28835 45H15.3561H33.7337H45.8015C46.506 45 47.1732 44.6355 47.5864 44.0291C47.9996 43.4228 48.108 42.6324 47.8912 41.9214ZM16.4996 42L17.4188 38.3663H31.6677L32.4996 42H16.4996ZM34.9996 42L33.4526 35.8399H15.6304L13.9996 42H3.99963L6.99963 32H41.9996L45.4996 42H34.9996Z'
          fill='currentColor'
        />
        <path
          d='M10.2486 24.1859L11.9929 25.8966L19.3832 17.3501L11.9929 8.79994L10.2486 10.5107L15.0682 16.0869H-0.00037384V18.6133H15.0682L10.2486 24.1859Z'
          fill='currentColor'
        />
        <path
          d='M29.0361 8.46069C26.567 8.46069 24.5619 10.868 24.5619 13.8275V14.6107C23.5458 14.6252 22.7228 15.5094 22.7228 16.5958V23.0743C22.7228 24.1678 23.5593 25.0593 24.5856 25.0593H33.6322C34.6584 25.0593 35.495 24.1678 35.495 23.0743V16.5922C35.495 15.4986 34.6584 14.6071 33.6322 14.6071H33.5102V13.8275C33.5102 10.868 31.5018 8.46069 29.0361 8.46069ZM33.1241 22.5293H25.0903V17.1335H33.1241V22.5293ZM27.2681 14.6071V13.8275C27.2681 12.4813 28.0776 11.348 29.0327 11.348C29.9912 11.348 30.7973 12.4849 30.7973 13.8275V14.6071H27.2681Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LoginSecure
