import { SVGProps } from 'react'
function RotateLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M7.99 1.07A7.136 7.136 0 00.857 8.211a7.13 7.13 0 002.747 5.629l-.67.857a.143.143 0 00.113.23l2.982-.014c.093 0 .16-.087.137-.177l-.705-2.898a.143.143 0 00-.252-.053l-.727.93a5.826 5.826 0 01-.523-.464 5.69 5.69 0 01-1.673-4.04 5.69 5.69 0 011.673-4.04A5.69 5.69 0 018 2.5a5.69 5.69 0 014.04 1.673 5.69 5.69 0 011.673 4.04 5.69 5.69 0 01-2.091 4.418.141.141 0 00-.022.197l.704.902c.05.063.14.074.203.024A7.142 7.142 0 007.99 1.07z'
        fill='currentColor'
      />
    </svg>
  )
}

export default RotateLeft
