import { SVGProps } from 'react'

function Url(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M7.96843 9.3957C7.94494 9.37244 7.91321 9.35939 7.88015 9.35939C7.84709 9.35939 7.81536 9.37244 7.79187 9.3957L5.97625 11.2113C5.13562 12.052 3.71687 12.141 2.78875 11.2113C1.85906 10.2816 1.94812 8.86445 2.78875 8.02383L4.60437 6.2082C4.65281 6.15977 4.65281 6.08008 4.60437 6.03164L3.9825 5.40977C3.959 5.3865 3.92728 5.37345 3.89421 5.37345C3.86115 5.37345 3.82943 5.3865 3.80593 5.40977L1.99031 7.22539C0.668433 8.54727 0.668433 10.6863 1.99031 12.0066C3.31218 13.327 5.45125 13.3285 6.77156 12.0066L8.58718 10.191C8.63562 10.1426 8.63562 10.0629 8.58718 10.0145L7.96843 9.3957ZM12.0091 1.98945C10.6872 0.667578 8.54812 0.667578 7.22781 1.98945L5.41062 3.80508C5.38736 3.82857 5.37431 3.8603 5.37431 3.89336C5.37431 3.92642 5.38736 3.95815 5.41062 3.98164L6.03093 4.60195C6.07937 4.65039 6.15906 4.65039 6.2075 4.60195L8.02312 2.78633C8.86375 1.9457 10.2825 1.85664 11.2106 2.78633C12.1403 3.71602 12.0512 5.1332 11.2106 5.97383L9.39499 7.78945C9.37173 7.81295 9.35868 7.84467 9.35868 7.87774C9.35868 7.9108 9.37173 7.94252 9.39499 7.96602L10.0169 8.58789C10.0653 8.63633 10.145 8.63633 10.1934 8.58789L12.0091 6.77227C13.3294 5.45039 13.3294 3.31133 12.0091 1.98945ZM8.5325 4.81602C8.509 4.79275 8.47728 4.7797 8.44421 4.7797C8.41115 4.7797 8.37943 4.79275 8.35593 4.81602L4.81687 8.35352C4.79361 8.37701 4.78056 8.40874 4.78056 8.4418C4.78056 8.47486 4.79361 8.50659 4.81687 8.53008L5.43562 9.14883C5.48406 9.19727 5.56375 9.19727 5.61218 9.14883L9.14968 5.61133C9.19812 5.56289 9.19812 5.4832 9.14968 5.43477L8.5325 4.81602Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Url
