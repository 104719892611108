import { SVGProps } from 'react'
function PlusPerson(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M15.083 14.196a4.21 4.21 0 012-.501h.002a.079.079 0 00.053-.138 9.122 9.122 0 00-2.546-1.615l-.03-.013a6.091 6.091 0 002.518-4.937c0-3.364-2.72-6.09-6.077-6.09-3.357 0-6.075 2.726-6.075 6.09 0 2.03.992 3.83 2.52 4.937l-.03.013a9.15 9.15 0 00-2.93 1.979 9.17 9.17 0 00-1.974 2.934 9.173 9.173 0 00-.721 3.39.196.196 0 00.196.202h1.47a.198.198 0 00.197-.191 7.326 7.326 0 012.151-5.017 7.29 7.29 0 015.198-2.158 7.28 7.28 0 013.88 1.107.199.199 0 00.198.008zm-4.078-2.981a4.189 4.189 0 01-2.98-1.238 4.204 4.204 0 01-1.236-2.985c0-1.127.44-2.188 1.235-2.986a4.18 4.18 0 012.981-1.238c1.127 0 2.183.44 2.98 1.238a4.203 4.203 0 011.236 2.986c0 1.127-.44 2.187-1.235 2.985a4.189 4.189 0 01-2.981 1.238zm9.03 5.844h-2.062v-2.063a.197.197 0 00-.196-.196h-1.375a.197.197 0 00-.197.196v2.063h-2.062a.197.197 0 00-.196.196v1.375c0 .108.088.197.196.197h2.063v2.062c0 .108.088.196.196.196h1.375a.197.197 0 00.196-.196v-2.063h2.063a.197.197 0 00.196-.196v-1.375a.197.197 0 00-.196-.196z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PlusPerson
