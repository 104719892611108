import { SVGProps } from 'react'
function FireFull(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.404 6.415A4.751 4.751 0 0010.27 4.84l-.398-.365a.11.11 0 00-.177.045l-.178.51c-.11.32-.314.646-.603.968a.085.085 0 01-.056.027.075.075 0 01-.059-.02.08.08 0 01-.027-.066c.05-.823-.196-1.752-.734-2.762a4.872 4.872 0 00-1.84-1.95L5.635.893a.11.11 0 00-.164.1l.03.656c.02.448-.031.845-.154 1.174-.15.404-.367.778-.643 1.115-.192.233-.41.445-.65.63a4.82 4.82 0 00-1.37 1.661 4.754 4.754 0 00-.117 3.969 4.804 4.804 0 002.562 2.537A4.811 4.811 0 007 13.111c.65 0 1.278-.126 1.872-.373a4.768 4.768 0 001.53-1.021 4.734 4.734 0 001.002-5.302z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FireFull
