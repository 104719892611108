import { SVGProps } from 'react'
function SmallBackArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.6563 6.32115H3.04292L9.39537 0.892578C9.49695 0.805078 9.43527 0.642578 9.30104 0.642578H7.6957C7.62495 0.642578 7.55784 0.667578 7.50523 0.712221L0.650329 6.56758C0.587546 6.62115 0.537194 6.68739 0.502686 6.76179C0.468177 6.8362 0.450317 6.91704 0.450317 6.99883C0.450317 7.08062 0.468177 7.16146 0.502686 7.23587C0.537194 7.31027 0.587546 7.3765 0.650329 7.43008L7.54514 13.3211C7.57235 13.3444 7.605 13.3569 7.63947 13.3569H9.29923C9.43346 13.3569 9.49513 13.1926 9.39355 13.1069L3.04292 7.67829H13.6563C13.7361 7.67829 13.8014 7.61401 13.8014 7.53544V6.46401C13.8014 6.38544 13.7361 6.32115 13.6563 6.32115Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SmallBackArrow
