import { SVGProps } from 'react'
function Lotty(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={67}
      height={73}
      viewBox='0 0 67 73'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g filter='url(#lotty_svg__filter0_d)'>
        <path
          d='M37.094 11.131c-.93 1.55-2.712 2.023-3.486 2.066v46.84s2.333-6.77 8.094-9.88c4.274-2.306 8.337-.28 5.898 3.712 2.572-1.568 4.44-4.127 5.406-6.523.965-2.396.416-6.795.416-6.795 1.744-3.833 5.36 5.308 5.36 5.308-.714-9.26-6.775-15.221-6.775-17.588 0-2.367 1.415-2.515 4.384 2.734.784-5.6-15.63-18.926-13.37-26.997-3.754 3.834-4.998 5.574-5.927 7.123z'
          fill='#fff'
        />
        <path
          d='M41.83 6.86c-2.03 1.277-3.78 5.46-3.78 5.46 2.556 0 4.906.942 5.761 1.414 0 0-2.432-4.095-1.982-6.875z'
          fill='#F34747'
        />
        <path
          d='M40.355 25.726c-1.433 0-2.337-.487-2.61-.73 0 .477-.552.885-.552 1.283 0 .398.192.987.966.987 1.61 0 2.137-1.046 2.196-1.54zM36.756 38.796c1.679 0 3.38-2.682 4.022-4.023-.685 1.822-1.976 5.214-3.03 6.076-.962.787-.644 3.675-4.14 3.675v-6.69c1.486 0 1.049.962 3.148.962z'
          fill='#000'
        />
        <mask id='lotty_svg__a' maskUnits='userSpaceOnUse' x={33} y={34} width={8} height={11}>
          <path
            d='M36.756 38.796c1.679 0 3.38-2.682 4.022-4.023-.685 1.822-1.976 5.214-3.03 6.076-.962.787-.644 3.675-4.14 3.675v-6.69c1.486 0 1.049.962 3.148.962z'
            fill='#000'
          />
        </mask>
        <g mask='url(#lotty_svg__a)'>
          <path
            d='M33.607 43.712c2.405 0 2.887-3.357 2.827-5.036 0-.847-1.884-.961-2.827-.913v5.95z'
            fill='#F34747'
          />
        </g>
        <path
          d='M36.375 33.634c0 1.61-1.845 2.516-2.768 2.767v-5.333c.705 0 2.768.554 2.768 2.566z'
          fill='#000'
        />
        <path
          d='M30.122 11.131c.93 1.55 2.71 2.023 3.485 2.066v46.84s-2.333-6.77-8.094-9.88c-4.274-2.306-8.337-.28-5.898 3.712-2.572-1.568-4.44-4.127-5.405-6.523-.965-2.396-.416-6.795-.416-6.795-1.745-3.833-5.361 5.308-5.361 5.308.715-9.26 6.775-15.221 6.775-17.588 0-2.367-1.414-2.515-4.384 2.734-.784-5.6 15.63-18.926 13.37-26.997 3.754 3.834 4.998 5.574 5.928 7.123z'
          fill='#fff'
        />
        <path
          d='M25.386 6.86c2.028 1.277 3.779 5.46 3.779 5.46-2.556 0-4.906.942-5.761 1.414 0 0 2.432-4.095 1.982-6.875z'
          fill='#F34747'
        />
        <path
          d='M26.86 25.726c1.433 0 2.336-.487 2.61-.73 0 .477.552.885.552 1.283 0 .398-.192.987-.966.987-1.61 0-2.137-1.046-2.196-1.54zM30.46 38.796c-1.68 0-3.382-2.682-4.023-4.023.685 1.822 1.976 5.214 3.03 6.076.962.787.644 3.675 4.14 3.675v-6.69c-1.486 0-1.049.962-3.148.962z'
          fill='#000'
        />
        <mask id='lotty_svg__b' maskUnits='userSpaceOnUse' x={26} y={34} width={8} height={11}>
          <path
            d='M30.46 38.796c-1.68 0-3.382-2.682-4.023-4.023.685 1.822 1.976 5.214 3.03 6.076.962.787.644 3.675 4.14 3.675v-6.69c-1.486 0-1.049.962-3.148.962z'
            fill='#000'
          />
        </mask>
        <g mask='url(#lotty_svg__b)'>
          <path
            d='M33.607 43.712c-2.404 0-2.886-3.357-2.826-5.036 0-.847 1.884-.961 2.826-.913v5.95z'
            fill='#F34747'
          />
        </g>
        <path
          d='M30.84 33.634c0 1.61 1.845 2.516 2.767 2.767v-5.333c-.704 0-2.767.554-2.767 2.566z'
          fill='#000'
        />
      </g>
      <defs>
        <filter
          id='lotty_svg__filter0_d'
          x={0.433}
          y={0.008}
          width={66.35}
          height={72.028}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default Lotty
