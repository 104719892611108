import { SVGProps } from 'react'
function Folder(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.031 4.08H7.123L5.52 2.546a.111.111 0 00-.075-.03H1.97a.437.437 0 00-.438.437v8.094c0 .242.196.437.438.437H12.03a.437.437 0 00.438-.437v-6.53a.437.437 0 00-.438-.437z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Folder
