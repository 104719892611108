import { SVGProps } from 'react'
function Slack(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M18.503 3.79a4.11 4.11 0 00-4.11 4.115c0 1.087.435 2.137 1.2 2.909a4.111 4.111 0 002.91 1.205h4.109V7.905a4.118 4.118 0 00-4.11-4.114zm0 10.972H7.537a4.11 4.11 0 00-4.11 4.114 4.114 4.114 0 004.11 4.115h10.96a4.11 4.11 0 004.11-4.115 4.106 4.106 0 00-4.104-4.114zm10.96 8.229a4.107 4.107 0 004.11-4.115V7.905a4.11 4.11 0 00-4.11-4.114 4.11 4.11 0 00-4.108 4.114v10.971a4.107 4.107 0 004.108 4.115zm15.075-4.115a4.114 4.114 0 00-4.108-4.114 4.114 4.114 0 00-4.11 4.114v4.115h4.11a4.114 4.114 0 004.108-4.115zm-4.114 6.857h-10.96a4.11 4.11 0 00-4.11 4.115c0 1.087.434 2.137 1.2 2.909a4.11 4.11 0 002.91 1.205h10.96a4.11 4.11 0 004.11-4.114 4.11 4.11 0 00-4.11-4.114zm-10.96 10.972h-4.11v4.114a4.114 4.114 0 004.11 4.114 4.107 4.107 0 004.108-4.114 4.11 4.11 0 00-4.109-4.114zM18.502 25.734a4.11 4.11 0 00-4.11 4.114v10.971a4.114 4.114 0 004.11 4.114 4.107 4.107 0 004.109-4.114V29.848a4.138 4.138 0 00-1.2-2.91 4.12 4.12 0 00-2.91-1.204zM3.428 29.848c0 1.087.434 2.137 1.2 2.909a4.11 4.11 0 007.018-2.91v-4.114h-4.11a4.114 4.114 0 00-4.108 4.115z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Slack
