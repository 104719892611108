import { SVGProps } from 'react'
function Settings(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={15}
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.45 9.282l-1.024-.875a5.623 5.623 0 000-1.807l1.024-.875a.5.5 0 00.145-.55l-.014-.04a6.936 6.936 0 00-1.245-2.155l-.028-.033a.501.501 0 00-.549-.148l-1.27.451a5.507 5.507 0 00-1.558-.9l-.245-1.328a.5.5 0 00-.403-.401L8.24.613a7.059 7.059 0 00-2.484 0L5.714.62a.5.5 0 00-.403.401l-.247 1.335a5.498 5.498 0 00-1.547.896L2.237 2.8a.5.5 0 00-.548.148l-.028.033A6.97 6.97 0 00.415 5.135l-.014.04a.502.502 0 00.146.55l1.036.885a5.498 5.498 0 000 1.784l-1.036.884a.5.5 0 00-.146.55l.014.041a6.95 6.95 0 001.246 2.155l.028.033a.502.502 0 00.548.148l1.28-.455c.466.383.986.686 1.547.897l.247 1.335a.501.501 0 00.403.401l.042.008a7.02 7.02 0 002.484 0l.043-.008a.5.5 0 00.403-.401l.245-1.329a5.466 5.466 0 001.558-.9l1.27.452a.5.5 0 00.549-.148l.028-.033a6.968 6.968 0 001.245-2.155l.014-.04a.498.498 0 00-.145-.547zm-2.133-2.497a4.418 4.418 0 010 1.44l-.103.627 1.167.998c-.177.408-.4.794-.665 1.15l-1.45-.514-.491.403c-.374.307-.79.547-1.24.716l-.595.223-.28 1.516c-.44.05-.886.05-1.327 0l-.28-1.519-.59-.226a4.37 4.37 0 01-1.23-.714l-.491-.405-1.46.519a5.903 5.903 0 01-.665-1.15l1.18-1.008-.102-.625a4.499 4.499 0 01-.058-.711c0-.24.02-.478.058-.71l.102-.626-1.18-1.008c.177-.408.4-.792.666-1.15l1.459.519.49-.405c.371-.304.785-.545 1.23-.714l.593-.223.28-1.519c.438-.05.887-.05 1.327 0l.28 1.516.595.223c.449.169.866.41 1.24.716l.49.403 1.45-.514c.266.358.487.744.666 1.15l-1.167.998.101.624zM7 4.599a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm1.237 3.987A1.744 1.744 0 017 9.1a1.744 1.744 0 01-1.617-1.08 1.744 1.744 0 01.38-1.907c.33-.332.77-.513 1.237-.513.467 0 .906.181 1.237.512A1.744 1.744 0 018.75 7.35c0 .467-.183.906-.513 1.237z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Settings
