import { SVGProps } from 'react'
function CopyAllText(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.917.998h-1.688V.373a.125.125 0 00-.124-.125h-.876a.125.125 0 00-.124.125v.625H7.729V.373a.125.125 0 00-.125-.125H6.73a.125.125 0 00-.125.125v.625H4.917a.5.5 0 00-.5.5v1.875h-1.5a.5.5 0 00-.5.5v9.875a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-1.5h1.5a.5.5 0 00.5-.5V1.498a.5.5 0 00-.5-.5zm-2.625 12.125h-6.75V4.498h3.406v2.719c0 .345.28.625.625.625h2.719v5.281zm0-6.281H7.948V4.498h.003l2.341 2.34v.004zm2 4.281h-.875v-4.75l-3-3H5.542v-1.25h1.062v.5c0 .069.057.125.125.125h.875a.125.125 0 00.125-.125v-.5h2.375v.5c0 .069.057.125.126.125h.874a.125.125 0 00.126-.125v-.5h1.062v9z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CopyAllText
