import { SVGProps } from 'react'
function Info(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M7 0a7 7 0 10.001 14.001A7 7 0 007 0zm.5 10.375a.125.125 0 01-.125.125h-.75a.125.125 0 01-.125-.125v-4.25c0-.069.056-.125.125-.125h.75c.069 0 .125.056.125.125v4.25zM7 5a.75.75 0 010-1.5A.75.75 0 017 5z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Info
