import { SVGProps } from 'react'
function Menu(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={13}
      height={14}
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.187 1.89H.812a.116.116 0 00-.116.116v.929c0 .064.052.116.116.116h11.375a.116.116 0 00.116-.116v-.929a.116.116 0 00-.116-.116zm0 9.054H.812a.116.116 0 00-.116.116v.928c0 .064.052.116.116.116h11.375a.116.116 0 00.116-.116v-.928a.116.116 0 00-.116-.116zm0-4.527H.812a.116.116 0 00-.116.116v.929c0 .063.052.116.116.116h11.375a.116.116 0 00.116-.116v-.929a.116.116 0 00-.116-.116z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Menu
