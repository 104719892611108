import { login, logout } from '@invisible/authentication/client'
import { InvisibleIcon } from '@invisible/ui/icons'
import { Text } from '@invisible/ui/text'
import { fontSizes, gray, styled } from '@invisible/ui/themes'
import { Topbar } from '@invisible/ui/topbar'
import { useSession } from 'next-auth/client'
import { FC, useEffect, useState } from 'react'
import { Box, Flex } from 'rebass'

import { MIDGARD_URL, PORTAL_URL } from '../../config/env'

const Title = styled(Text)`
  color: ${gray(1)};
  font-size: ${fontSizes(2)};
`

const Header: FC = () => {
  const [session, loading] = useSession()

  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }

  return (
    <Topbar fixed>
      <Topbar.Logo>
        <Flex alignItems='center'>
          <Box mr={2}>
            <InvisibleIcon color='white' width={28} />
          </Box>
          <Title>Process Builder</Title>
        </Flex>
      </Topbar.Logo>

      <Topbar.Menu>
        <Topbar.MenuItem href={PORTAL_URL} target='_blank'>
          Portal
        </Topbar.MenuItem>
        <Topbar.MenuItem href={MIDGARD_URL} target='_blank'>
          DAL
        </Topbar.MenuItem>
      </Topbar.Menu>
      <Topbar.Right>
        <Topbar.ProfileMenu
          session={session}
          sessionLoading={loading}
          onLoginClick={login}
          onLogoutClick={logout}
        />
      </Topbar.Right>
    </Topbar>
  )
}

export { Header }
