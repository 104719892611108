import { SVGProps } from 'react'
function Library(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M20.625 2.625H3.375a.75.75 0 00-.75.75v17.25c0 .415.335.75.75.75h17.25a.75.75 0 00.75-.75V3.375a.75.75 0 00-.75-.75zM8.906 16.313a.937.937 0 110-1.875.937.937 0 010 1.874zm0-3.375a.937.937 0 110-1.875.937.937 0 010 1.874zm0-3.376a.937.937 0 110-1.874.937.937 0 010 1.875zm7.125 6.376a.188.188 0 01-.187.187H11.53a.188.188 0 01-.187-.188v-1.124c0-.104.084-.188.187-.188h4.313c.103 0 .187.084.187.188v1.124zm0-3.376a.188.188 0 01-.187.188H11.53a.188.188 0 01-.187-.188v-1.124c0-.104.084-.188.187-.188h4.313c.103 0 .187.084.187.188v1.124zm0-3.374a.188.188 0 01-.187.187H11.53a.188.188 0 01-.187-.188V8.063c0-.104.084-.188.187-.188h4.313c.103 0 .187.084.187.188v1.124z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Library
