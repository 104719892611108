import { SVGProps } from 'react'
function Plus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M7.218.547v14.142M14.29 7.618H.146' stroke='currentColor' />
    </svg>
  )
}

export default Plus
