import { SVGProps } from 'react'
function SourceInfo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='49'
      viewBox='0 0 48 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0)'>
        <path
          d='M25.281 7.64962V5.216H22.8554V7.64962C20.1109 8.14803 17.9417 10.2963 17.4427 13.0187H14.9338V15.4248H17.4427C17.9451 18.1472 20.1109 20.2989 22.8554 20.7939V23.1175H25.281V20.7939C28.0255 20.2955 30.1948 18.1472 30.6937 15.4248H33.0674V13.0187H30.6937C30.1948 10.2998 28.0255 8.14803 25.281 7.64962ZM24.0682 18.5081C21.6876 18.5081 19.7505 16.5866 19.7505 14.2252C19.7505 11.8638 21.6876 9.94231 24.0682 9.94231C26.4488 9.94231 28.3859 11.8638 28.3859 14.2252C28.3859 16.5866 26.4488 18.5081 24.0682 18.5081Z'
          fill='currentColor'
        />
        <path
          d='M47.8876 37.568L44.1902 26.4208V2.73425C44.1902 1.5037 43.1783 0.5 41.9378 0.5H6.06558C4.82156 0.5 3.81318 1.5037 3.81318 2.73425V26.4208L0.112301 37.568C-0.11294 38.2451 0.00487833 38.9979 0.427638 39.5754C0.850397 40.1528 1.53305 40.5 2.25382 40.5H14.6005H33.4029H45.7495C46.4703 40.5 47.1529 40.1528 47.5757 39.5754C47.995 38.9979 48.1128 38.2451 47.8876 37.568ZM6.23885 2.90612H41.7645V25.4102H6.23885V2.90612ZM15.5881 38.0939L16.7108 34.1857H31.2856L32.4118 38.0973H15.5881V38.0939ZM34.9345 38.0939L33.1187 31.7795H14.8812L13.0619 38.0973H2.49292L5.90272 27.8198H42.0972L45.507 38.0973H34.9345V38.0939Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='48' height='48' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SourceInfo
