import { SVGProps } from 'react'

function FontColor(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M22.5004 20.1404H1.50042C1.38256 20.1404 1.28613 20.2368 1.28613 20.3547V22.4976C1.28613 22.6154 1.38256 22.7118 1.50042 22.7118H22.5004C22.6183 22.7118 22.7147 22.6154 22.7147 22.4976V20.3547C22.7147 20.2368 22.6183 20.1404 22.5004 20.1404ZM5.08167 17.9976H7.35845C7.47095 17.9976 7.57274 17.9252 7.60756 17.8154L9.04595 13.369H14.9174L16.3424 17.8154C16.3772 17.9226 16.4763 17.9976 16.5915 17.9976H18.9781C19.0076 17.9976 19.037 17.9922 19.0638 17.9842C19.0963 17.973 19.1262 17.9556 19.1518 17.9328C19.1775 17.9101 19.1984 17.8824 19.2134 17.8516C19.2283 17.8207 19.237 17.7872 19.2389 17.7529C19.2408 17.7187 19.2359 17.6844 19.2245 17.652L13.6504 1.46005C13.6323 1.40892 13.599 1.36456 13.555 1.33295C13.5109 1.30134 13.4582 1.284 13.404 1.28326H10.6638C10.5513 1.28326 10.4522 1.35291 10.4174 1.46005L4.83524 17.652C4.82453 17.6788 4.82185 17.7083 4.82185 17.7377C4.81917 17.8797 4.93703 17.9976 5.08167 17.9976V17.9976ZM11.9361 4.17344H12.046L14.2906 11.2395H9.66738L11.9361 4.17344V4.17344Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FontColor
