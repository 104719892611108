import { SVGProps } from 'react'
function Search(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M13.213 12.354L9.155 8.296c.63-.814.97-1.81.97-2.857a4.66 4.66 0 00-1.373-3.314A4.653 4.653 0 005.438.752a4.66 4.66 0 00-3.314 1.373A4.65 4.65 0 00.75 5.44c0 1.252.49 2.43 1.374 3.314a4.65 4.65 0 003.314 1.374 4.65 4.65 0 002.854-.969l4.058 4.056a.13.13 0 00.09.038.129.129 0 00.091-.038l.682-.68a.127.127 0 00.028-.139.128.128 0 00-.028-.041zm-5.3-4.44A3.481 3.481 0 015.438 8.94a3.481 3.481 0 01-2.475-1.025A3.481 3.481 0 011.938 5.44c0-.934.364-1.814 1.025-2.475A3.481 3.481 0 015.438 1.94c.934 0 1.814.363 2.475 1.025A3.481 3.481 0 018.938 5.44c0 .935-.364 1.814-1.025 2.475z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Search
