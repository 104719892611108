import Image, { ImageProps } from 'next/image'

import {
  ARROW_LEFT,
  ASSISTANT_BUILDER_WITH_SMALL_GREEN_TEXT_BOX,
  ASSISTANT_CLEANER,
  ASSISTANT_CLEANER_WITH_SMALL_GREEN_TEXT_BOX,
  ASSISTANT_COOK,
  ASSISTANT_COOK_WITH_LARGE_GREEN_TEXT_BOX,
  ASSISTANT_COOK_WITH_LARGE_GREY_TEXT_BOX,
  ASSISTANT_COOK_WITH_MEDIUM_GREEN_TEXT_BOX,
  ASSISTANT_COOK_WITH_MEDIUM_GREY_TEXT_BOX,
  ASSISTANT_COOK_WITH_SMALL_GREEN_TEXT_BOX,
  ASSISTANT_COOK_WITH_SMALL_GREY_TEXT_BOX,
  ASSISTANT_FIXER,
  ASSISTANT_FIXER_WITH_MEDIUM_GREEN_TEXT_BOX,
  ASSISTANT_FIXER_WITH_MEDIUM_GREY_TEXT_BOX,
  ASSISTANT_FIXER_WITH_SMALL_GREEN_TEXT_BOX,
  ASSISTANT_FIXER_WITH_SMALL_GREY_TEXT_BOX,
  ASSISTANT_HANDYMAN_WITH_SMALL_GREEN_TEXT_BOX,
  ASSISTANT_NURSE,
  ASSISTANT_NURSE_WITH_LARGE_GREEN_TEXT_BOX,
  ASSISTANT_NURSE_WITH_MEDIUM_GREEN_TEXT_BOX,
  ASSISTANT_NURSE_WITH_MEDIUM_GREY_TEXT_BOX,
  ASSISTANT_NURSE_WITH_SMALL_GREEN_TEXT_BOX,
  ASSISTANT_NURSE_WITH_SMALL_GREY_TEXT_BOX,
  ASSISTANT_SOLDIER,
  ASSISTANT_SOLDIER_WITH_LARGE_GREEN_TEXT_BOX,
  BUILD_PROCESS,
  CARD_BACK_ICON,
  CARD_ICON,
  CARD_IMAGE,
  CHECK_CIRCLE,
  COFFEE_MUGG,
  CUSTOM_PLAN,
  DEFAULT_AVATAR_PURPLE,
  DEFAULT_AVATAR_WHITE,
  DEFAULT_STRIPE_SUB_IMAGE,
  EXISTING_PROCESS,
  GOOGLE_LOGO,
  HANDSHAKE,
  INV_LOGO,
  INV_LOGO_BIG,
  INV_LOGO_FULL,
  JUGGLING_ROBOT,
  LINKEDIN_LOGO,
  MAIL_ICON,
  MIDDLE_PLAN_CHECKMARK,
  MISSING_PROFILE_IMAGE,
  MISSING_PROFILE_IMAGE_PLUS,
  NOT_FOUND_IMG,
  OPEN_ENVELOPE,
  PARACHUTE_MAN,
  PERSON,
  PORTTAL,
  PURPLE_INVISIBLE_LOGO_ONBOARDING,
  RED_DOT_ICO,
  REUSE_ICON,
  ROBOT_SMILE,
  SIDE_PLAN_CHECKMARK,
  SQUARES,
  STRIPE_LOGO,
  STRIPE_TEAL,
  TELEPHONE,
  TYPING_MAN,
} from './urls'

type Props = Omit<ImageProps, 'src'>

//a BlurDataURL must be passed as a prop for static import
//see doc: https://nextjs.org/docs/messages/placeholder-blur-data-url
const wrapImage = (src: ImageProps['src']) => (props: Props) =>
  (
    <Image
      data-cy='images'
      placeholder={typeof src !== 'string' ? 'blur' : 'empty'}
      {...(props as any)}
      src={src as ImageProps['src']}
      blurDataURL={src as ImageProps['src']}
    />
  )

export const ArrowLeft = wrapImage(ARROW_LEFT)
export const BuildProcess = wrapImage(BUILD_PROCESS)
export const CardBackIcon = wrapImage(CARD_BACK_ICON)
export const CardIcon = wrapImage(CARD_ICON)
export const CheckCircle = wrapImage(CHECK_CIRCLE)
export const CoffeeMugg = wrapImage(COFFEE_MUGG)
export const CustomPlan = wrapImage(CUSTOM_PLAN)
export const DefaultStripeSub = wrapImage(DEFAULT_STRIPE_SUB_IMAGE)
export const ExistingProcess = wrapImage(EXISTING_PROCESS)
export const GoogleLogo = wrapImage(GOOGLE_LOGO)
export const InvLogo = wrapImage(INV_LOGO)
export const InvLogoFull = wrapImage(INV_LOGO_FULL)
export const InvLogoBig = wrapImage(INV_LOGO_BIG)
export const JugglingRobot = wrapImage(JUGGLING_ROBOT)
export const LinkedinLogo = wrapImage(LINKEDIN_LOGO)
export const MailIcon = wrapImage(MAIL_ICON)
export const MiddlePlanCheckmark = wrapImage(MIDDLE_PLAN_CHECKMARK)
export const MissingProfile = wrapImage(MISSING_PROFILE_IMAGE)
export const MissingProfilePlus = wrapImage(MISSING_PROFILE_IMAGE_PLUS)
export const NotFoundImg = wrapImage(NOT_FOUND_IMG)
export const ParachuteMan = wrapImage(PARACHUTE_MAN)
export const Person = wrapImage(PERSON)
export const RedDotIco = wrapImage(RED_DOT_ICO)
export const ReuseIcon = wrapImage(REUSE_ICON)
export const RobotSmile = wrapImage(ROBOT_SMILE)
export const SidePlanCheckmark = wrapImage(SIDE_PLAN_CHECKMARK)
export const Squares = wrapImage(SQUARES)
export const CardImage = wrapImage(CARD_IMAGE)
export const StripeLogo = wrapImage(STRIPE_LOGO)
export const StripeTeal = wrapImage(STRIPE_TEAL)
export const Telephone = wrapImage(TELEPHONE)
export const Handshake = wrapImage(HANDSHAKE)
export const TypingMan = wrapImage(TYPING_MAN)
export const OpenEnvelope = wrapImage(OPEN_ENVELOPE)
export const DefaultAvatarWhite = wrapImage(DEFAULT_AVATAR_WHITE)
export const DefaultAvatarPurple = wrapImage(DEFAULT_AVATAR_PURPLE)
export const Portal = wrapImage(PORTTAL)
export const BuilderWithGreenTextBox = wrapImage(ASSISTANT_BUILDER_WITH_SMALL_GREEN_TEXT_BOX)
export const CleanerWithSmallGreenTextBox = wrapImage(ASSISTANT_CLEANER_WITH_SMALL_GREEN_TEXT_BOX)
export const FixerWithSmallGreenTextBox = wrapImage(ASSISTANT_FIXER_WITH_SMALL_GREEN_TEXT_BOX)
export const FixerWithSmallGreyTextBox = wrapImage(ASSISTANT_FIXER_WITH_SMALL_GREY_TEXT_BOX)
export const FixerWithMediumGreenTextBox = wrapImage(ASSISTANT_FIXER_WITH_MEDIUM_GREEN_TEXT_BOX)
export const NurseWithMediumGreenTextBox = wrapImage(ASSISTANT_NURSE_WITH_MEDIUM_GREEN_TEXT_BOX)
export const NurseWithLargeGreenTextBox = wrapImage(ASSISTANT_NURSE_WITH_LARGE_GREEN_TEXT_BOX)
export const SoldierWithLargeGreenTextBox = wrapImage(ASSISTANT_SOLDIER_WITH_LARGE_GREEN_TEXT_BOX)
export const NurseWithMediumGreyTextBox = wrapImage(ASSISTANT_NURSE_WITH_MEDIUM_GREY_TEXT_BOX)
export const NurseWithSmallGreyTextBox = wrapImage(ASSISTANT_NURSE_WITH_SMALL_GREY_TEXT_BOX)
export const AssistantNurseWithSmallGreenTextBox = wrapImage(
  ASSISTANT_NURSE_WITH_SMALL_GREEN_TEXT_BOX
)

export const FixerWithMediumGreyTextBox = wrapImage(ASSISTANT_FIXER_WITH_MEDIUM_GREY_TEXT_BOX)
export const AssistantCook = wrapImage(ASSISTANT_COOK)
export const AssistantFixer = wrapImage(ASSISTANT_FIXER)

export const AssistantSoldier = wrapImage(ASSISTANT_SOLDIER)
export const AssistantNurse = wrapImage(ASSISTANT_NURSE)

export const InvisibleLogoOnboarding = wrapImage(PURPLE_INVISIBLE_LOGO_ONBOARDING)
export const Cleaner = wrapImage(ASSISTANT_CLEANER)
export const HandymanWithSmallGreenTextBox = wrapImage(ASSISTANT_HANDYMAN_WITH_SMALL_GREEN_TEXT_BOX)
export const CookWithSmallGreenTextBox = wrapImage(ASSISTANT_COOK_WITH_SMALL_GREEN_TEXT_BOX)
export const CookWithSmallGreyTextBox = wrapImage(ASSISTANT_COOK_WITH_SMALL_GREY_TEXT_BOX)
export const CookWithLargeGreyTextBox = wrapImage(ASSISTANT_COOK_WITH_LARGE_GREY_TEXT_BOX)
export const CookWithLargeGreenTextBox = wrapImage(ASSISTANT_COOK_WITH_LARGE_GREEN_TEXT_BOX)
export const CookWithMediumGreenTextBox = wrapImage(ASSISTANT_COOK_WITH_MEDIUM_GREEN_TEXT_BOX)
export const CookWithMediumGreyTextBox = wrapImage(ASSISTANT_COOK_WITH_MEDIUM_GREY_TEXT_BOX)
