import { SVGProps } from 'react'
function RotateRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M12.397 13.84a7.13 7.13 0 002.746-5.629 7.136 7.136 0 00-7.132-7.14 7.142 7.142 0 00-4.518 12.68c.063.05.154.039.204-.024l.703-.901a.143.143 0 00-.021-.199 5.69 5.69 0 01-2.093-4.416 5.69 5.69 0 011.673-4.04A5.69 5.69 0 018 2.5a5.69 5.69 0 014.04 1.673 5.69 5.69 0 011.673 4.04 5.69 5.69 0 01-2.197 4.504l-.726-.931a.144.144 0 00-.143-.053.142.142 0 00-.11.106l-.706 2.897a.143.143 0 00.137.176l2.982.015c.12 0 .188-.138.113-.23l-.666-.856z'
        fill='currentColor'
      />
    </svg>
  )
}

export default RotateRight
