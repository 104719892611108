import { SVGProps } from 'react'
function Conditional(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M36 3H12L0 24L12 45H36L48 24L36 3ZM34.3674 42.1429H13.6326L3.2653 24L13.6326 5.85714H34.3674L44.7347 24L34.3674 42.1429Z'
        fill='currentColor'
      />
      <path
        d='M27.533 13.2076L25.1801 14.7925L28.4277 19.7143H15.0452V22.5714H30.3129L31.2556 24L30.3129 25.4286H15.0452V28.2857H28.4277L25.1801 33.2076L27.533 34.7925L34.6542 24L27.533 13.2076Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Conditional
