export const NODE_ENV = process.env.NODE_ENV
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
export const AUTH0_CLIENT_SECRET = process.env.AUTH0_CLIENT_SECRET
export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN
const getOriginPolyfill = () =>
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '')
export const NEXTAUTH_URL =
  typeof window !== 'undefined'
    ? window.location.origin ?? getOriginPolyfill()
    : process.env.NEXTAUTH_URL ?? `https://${process.env.VERCEL_URL}`
export const DATABASE_URL = process.env.DATABASE_URL
