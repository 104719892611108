import { SVGProps } from 'react'
function JoinData(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0)'>
        <path
          d='M46.3508 12.4577H33.0989V4.21068C33.0989 3.2999 32.3605 2.56152 31.4497 2.56152H1.64906C0.738375 2.56152 0 3.2999 0 4.21068V33.8933C0 34.8041 0.738375 35.5425 1.64916 35.5425H14.9013V43.7894C14.9013 44.7002 15.6397 45.4386 16.5504 45.4386H46.3509C47.2617 45.4386 48.0001 44.7002 48.0001 43.7894V14.1067C48 13.1959 47.2616 12.4577 46.3508 12.4577ZM14.8998 32.2442H3.29822V25.6482H14.8998V32.2442ZM14.8998 22.35H3.29822V15.7559H14.8998V22.35ZM14.8998 12.4576H3.29822V5.85974H14.8998V12.4576ZM29.8006 42.1402H18.1995V35.5437H29.8007V42.1402H29.8006ZM29.8006 32.2442H18.1995V15.7559H29.8007V32.2442H29.8006ZM29.8006 12.4577H18.198V5.85974H29.8006V12.4577ZM44.7018 42.1402H33.0989V35.5437H44.7018V42.1402ZM44.7018 32.2454H33.0989V25.6501H44.7018V32.2454ZM44.7018 22.3519H33.0989V15.7559H44.7018V22.3519Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default JoinData
