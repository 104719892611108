import { SVGProps } from 'react'
function DocQA(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M36 48C41.666 47.043 46 41.9341 46 36V34H42V36C42 40.4111 38.4111 44 34 44C29.5889 44 26 40.4111 26 36C26 31.5889 29.5889 28 34 28H36V11.1709L24.8291 0H2V48H36ZM31.1719 12H24V4.8291L31.1719 12ZM6 4H20V16H32V24.1812C26.334 25.1372 22 30.0659 22 36C22 39.0752 23.1729 41.874 25.0811 44H6V4Z'
        fill='currentColor'
      />
      <path
        d='M32 31.1709L29.1709 34L34 38.8291L45.8291 27L43 24.1709L34 33.1709L32 31.1709Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DocQA
