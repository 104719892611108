import { SVGProps } from 'react'
function Person(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M14.189 12.494a6.678 6.678 0 00-1.44-2.134 6.708 6.708 0 00-2.133-1.44l-.022-.008A4.428 4.428 0 008.001.894a4.428 4.428 0 00-2.592 8.02l-.022.008c-.8.338-1.518.822-2.134 1.44a6.707 6.707 0 00-1.966 4.6.143.143 0 00.143.146h1.071a.143.143 0 00.143-.14 5.324 5.324 0 011.568-3.647 5.322 5.322 0 013.79-1.57c1.432 0 2.776.557 3.789 1.57a5.324 5.324 0 011.568 3.648c.001.078.064.14.142.14h1.072a.143.143 0 00.143-.147 6.652 6.652 0 00-.527-2.468zM8 8.394c-.82 0-1.59-.32-2.171-.9-.58-.58-.9-1.352-.9-2.172 0-.82.32-1.59.9-2.171.58-.58 1.352-.9 2.171-.9.82 0 1.592.32 2.172.9.58.58.9 1.352.9 2.171 0 .82-.32 1.591-.9 2.172-.58.58-1.352.9-2.172.9z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Person
