import { SVGProps } from 'react'
function Build(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M15.566 11.333h-1.413v-.666c0-.354-.068-.694-.21-1.027a2.615 2.615 0 0 0-.619-.867 2.92 2.92 0 0 0-.917-.58c-.347-.133-.72-.173-1.087-.2H9.2v-2h1.06c.781 0 1.413-.6 1.413-1.333V1.333C11.673.6 11.041 0 10.26 0H6.72c-.782 0-1.413.6-1.413 1.333v3.334C5.307 5.4 5.938 6 6.72 6h1.06v2H5.646c-.373 0-.734.067-1.073.2-.347.133-.66.327-.924.58-.265.247-.47.54-.612.867-.143.326-.21.673-.21 1.02v.666H1.413c-.781 0-1.413.6-1.413 1.334v4C0 17.4.632 18 1.413 18H5.66c.781 0 1.413-.6 1.413-1.333v-4c0-.734-.632-1.334-1.413-1.334H4.247v-.666c0-.174.034-.347.108-.514.068-.16.177-.306.306-.433.13-.127.285-.22.462-.287.17-.066.353-.08.544-.1h5.673a1.474 1.474 0 0 1 .999.387c.13.127.23.267.306.433.074.167.108.334.108.514v.666H11.34c-.781 0-1.413.6-1.413 1.334v4c0 .733.632 1.333 1.413 1.333h4.247c.781 0 1.413-.6 1.413-1.333v-4c0-.734-.632-1.334-1.413-1.334h-.02Z'
        id='BuildShape'
        fill='currentColor'
      />
      <path
        d='M6.72 1.333h3.54v3.334H6.72V1.333ZM5.66 16.667H1.406v-4h4.247v4h.007Zm9.906 0H11.32v-4h4.246v4Z'
        id='BuildPath'
        fill={props.fill ?? '#FFFFFF'}
      />
    </svg>
  )
}

export default Build
