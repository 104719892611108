import { SVGProps } from 'react'
function BellNoFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M15.1071 14.1403H14.625V7.30999C14.625 4.47539 12.5297 2.13298 9.80357 1.74325V0.961775C9.80357 0.517801 9.44397 0.158203 9 0.158203C8.55603 0.158203 8.19643 0.517801 8.19643 0.961775V1.74325C5.47031 2.13298 3.375 4.47539 3.375 7.30999V14.1403H2.89286C2.53728 14.1403 2.25 14.4276 2.25 14.7832V15.4261C2.25 15.5145 2.32232 15.5868 2.41071 15.5868H6.75C6.75 16.8283 7.75848 17.8368 9 17.8368C10.2415 17.8368 11.25 16.8283 11.25 15.5868H15.5893C15.6777 15.5868 15.75 15.5145 15.75 15.4261V14.7832C15.75 14.4276 15.4627 14.1403 15.1071 14.1403ZM9 16.5511C8.46763 16.5511 8.03571 16.1191 8.03571 15.5868H9.96429C9.96429 16.1191 9.53237 16.5511 9 16.5511ZM4.82143 14.1403V7.30999C4.82143 6.19302 5.25536 5.14436 6.04487 4.35485C6.83438 3.56535 7.88304 3.13142 9 3.13142C10.117 3.13142 11.1656 3.56535 11.9551 4.35485C12.7446 5.14436 13.1786 6.19302 13.1786 7.30999V14.1403H4.82143Z'
        fill={props.fill ?? '#0A0810'}
      />
    </svg>
  )
}

export default BellNoFill
