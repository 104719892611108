import { SVGProps } from 'react'
function SortData(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M21 16.5V4.5C21 3.30653 20.5259 2.16193 19.682 1.31802C18.8381 0.474106 17.6935 0 16.5 0L4.5 0C3.30653 0 2.16193 0.474106 1.31802 1.31802C0.474106 2.16193 0 3.30653 0 4.5L0 16.5C0 17.6935 0.474106 18.8381 1.31802 19.682C2.16193 20.5259 3.30653 21 4.5 21H16.5C17.6935 21 18.8381 20.5259 19.682 19.682C20.5259 18.8381 21 17.6935 21 16.5ZM16.5 18H12V15H18V16.5C18 16.8978 17.842 17.2794 17.5607 17.5607C17.2794 17.842 16.8978 18 16.5 18ZM3 9H9V12H3V9ZM12 9H18V12H12V9ZM4.5 3H16.5C16.8978 3 17.2794 3.15804 17.5607 3.43934C17.842 3.72064 18 4.10218 18 4.5V6H3V4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3ZM3 16.5V15H9V18H4.5C4.10218 18 3.72064 17.842 3.43934 17.5607C3.15804 17.2794 3 16.8978 3 16.5Z'
        fill='currentColor'
      />
      <path
        d='M30 27H18C16.8065 27 15.6619 27.4741 14.818 28.318C13.9741 29.1619 13.5 30.3065 13.5 31.5V43.5C13.5 44.6935 13.9741 45.8381 14.818 46.682C15.6619 47.5259 16.8065 48 18 48H30C31.1935 48 32.3381 47.5259 33.182 46.682C34.0259 45.8381 34.5 44.6935 34.5 43.5V31.5C34.5 30.3065 34.0259 29.1619 33.182 28.318C32.3381 27.4741 31.1935 27 30 27ZM16.5 36H22.5V39H16.5V36ZM25.5 36H31.5V39H25.5V36ZM18 30H30C30.3978 30 30.7794 30.158 31.0607 30.4393C31.342 30.7206 31.5 31.1022 31.5 31.5V33H16.5V31.5C16.5 31.1022 16.658 30.7206 16.9393 30.4393C17.2206 30.158 17.6022 30 18 30ZM16.5 43.5V42H22.5V45H18C17.6022 45 17.2206 44.842 16.9393 44.5607C16.658 44.2794 16.5 43.8978 16.5 43.5ZM30 45H25.5V42H31.5V43.5C31.5 43.8978 31.342 44.2794 31.0607 44.5607C30.7794 44.842 30.3978 45 30 45Z'
        fill='currentColor'
      />
      <path
        d='M43.5 0H31.5C30.3065 0 29.1619 0.474106 28.318 1.31802C27.4741 2.16193 27 3.30653 27 4.5V16.5C27 17.6935 27.4741 18.8381 28.318 19.682C29.1619 20.5259 30.3065 21 31.5 21H43.5C44.6935 21 45.8381 20.5259 46.682 19.682C47.5259 18.8381 48 17.6935 48 16.5V4.5C48 3.30653 47.5259 2.16193 46.682 1.31802C45.8381 0.474106 44.6935 0 43.5 0V0ZM30 9H36V12H30V9ZM39 9H45V12H39V9ZM31.5 3H43.5C43.8978 3 44.2794 3.15804 44.5607 3.43934C44.842 3.72064 45 4.10218 45 4.5V6H30V4.5C30 4.10218 30.158 3.72064 30.4393 3.43934C30.7206 3.15804 31.1022 3 31.5 3ZM30 16.5V15H36V18H31.5C31.1022 18 30.7206 17.842 30.4393 17.5607C30.158 17.2794 30 16.8978 30 16.5ZM43.5 18H39V15H45V16.5C45 16.8978 44.842 17.2794 44.5607 17.5607C44.2794 17.842 43.8978 18 43.5 18Z'
        fill='currentColor'
      />
      <path
        d='M7.93946 29.5605C8.07783 29.7038 8.24335 29.818 8.42635 29.8967C8.60936 29.9753 8.80619 30.0167 9.00536 30.0184C9.20453 30.0201 9.40205 29.9822 9.58639 29.9067C9.77073 29.8313 9.93821 29.7199 10.0791 29.5791C10.2199 29.4383 10.3313 29.2708 10.4067 29.0864C10.4821 28.9021 10.5201 28.7046 10.5183 28.5054C10.5166 28.3062 10.4752 28.1094 10.3966 27.9264C10.318 27.7434 10.2037 27.5779 10.0605 27.4395L7.06046 24.4395C7.04696 24.426 7.02896 24.4215 7.01546 24.4095C6.88756 24.284 6.7382 24.1824 6.57446 24.1095C6.56246 24.1095 6.54896 24.1095 6.53546 24.1005C6.38359 24.0439 6.22347 24.0125 6.06146 24.0075C6.01046 24.0075 5.96246 24.0075 5.91146 24.0075C5.78434 24.016 5.65883 24.0407 5.53796 24.081C5.48687 24.0952 5.43677 24.1128 5.38796 24.1335C5.22049 24.2044 5.06785 24.3062 4.93796 24.4335L1.93796 27.4335C1.66472 27.7164 1.51353 28.0953 1.51695 28.4886C1.52037 28.8819 1.67812 29.2581 1.95623 29.5362C2.23434 29.8143 2.61056 29.9721 3.00386 29.9755C3.39715 29.9789 3.77606 29.8277 4.05896 29.5545L4.49996 29.121V37.5C4.49996 37.8978 4.65799 38.2794 4.9393 38.5607C5.2206 38.842 5.60213 39 5.99996 39H8.99996C9.39778 39 9.77931 38.842 10.0606 38.5607C10.3419 38.2794 10.5 37.8978 10.5 37.5C10.5 37.1022 10.3419 36.7207 10.0606 36.4393C9.77931 36.158 9.39778 36 8.99996 36H7.49996V29.121L7.93946 29.5605Z'
        fill='currentColor'
      />
      <path
        d='M43.0605 24.4395C42.93 24.3129 42.7775 24.2112 42.6105 24.1395C42.564 24.1185 42.516 24.105 42.468 24.0885C42.346 24.0483 42.2195 24.0237 42.0915 24.015C42.0405 24.015 41.9925 24.006 41.9415 24.015C41.7795 24.0202 41.6194 24.0516 41.4675 24.108C41.4555 24.108 41.4405 24.108 41.4285 24.117C41.2647 24.1898 41.1154 24.2914 40.9875 24.417C40.974 24.429 40.956 24.4335 40.9425 24.447L37.9425 27.447C37.7992 27.5854 37.6849 27.7509 37.6063 27.9339C37.5277 28.1169 37.4863 28.3137 37.4846 28.5129C37.4829 28.7121 37.5208 28.9096 37.5962 29.0939C37.6717 29.2783 37.783 29.4457 37.9239 29.5866C38.0647 29.7274 38.2322 29.8388 38.4165 29.9142C38.6009 29.9896 38.7984 30.0276 38.9976 30.0259C39.1967 30.0241 39.3936 29.9828 39.5766 29.9041C39.7596 29.8255 39.9251 29.7113 40.0635 29.568L40.5 29.121V36H39C38.6021 36 38.2206 36.158 37.9393 36.4393C37.658 36.7206 37.5 37.1022 37.5 37.5C37.5 37.8978 37.658 38.2793 37.9393 38.5606C38.2206 38.842 38.6021 39 39 39H42C42.3978 39 42.7793 38.842 43.0606 38.5606C43.3419 38.2793 43.5 37.8978 43.5 37.5V29.121L43.9395 29.5605C44.2224 29.8337 44.6013 29.9849 44.9946 29.9815C45.3879 29.9781 45.7641 29.8203 46.0422 29.5422C46.3203 29.2641 46.4781 28.8879 46.4815 28.4946C46.4849 28.1013 46.3337 27.7224 46.0605 27.4395L43.0605 24.4395Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SortData
