import { SVGProps } from 'react'
function CalendarFull(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M19.5 2.625h-2.531v-.938a.188.188 0 00-.188-.187H15.47a.188.188 0 00-.188.188v.937H11.72v-.938a.188.188 0 00-.188-.187H10.22a.188.188 0 00-.188.188v.937H7.5a.75.75 0 00-.75.75v2.813H4.5a.75.75 0 00-.75.75V21.75c0 .415.335.75.75.75h12a.75.75 0 00.75-.75V19.5h2.25a.75.75 0 00.75-.75V3.375a.75.75 0 00-.75-.75zm-3.938 8.766h-3.515V7.875h.005l3.51 3.51v.006zm3 6.421H17.25v-7.125l-4.5-4.5H8.437V4.313h1.594v.75c0 .104.085.188.188.188h1.312a.188.188 0 00.188-.188v-.75h3.562v.75c0 .104.085.188.188.188h1.312a.188.188 0 00.188-.188v-.75h1.593v13.5z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CalendarFull
