import { SVGProps } from 'react'
function And(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M36.7406 35.0944C39.3102 31.8447 41.6704 28.4615 43.95 25.007L38.5266 21.8176C36.5897 24.7423 34.6074 27.6228 32.4628 30.4023L22.9463 19.967C26.2197 17.9659 29.7453 15.3978 30.6882 11.4405C32.2544 4.85939 25.6673 0.0399179 19.7279 0.000261868C14.5427 -0.0329254 9.47529 3.08987 8.65611 8.49209C8.24548 11.208 9.02829 13.9071 10.8048 15.8974C10.8123 15.9058 10.8198 15.9133 10.8264 15.9217L13.1373 18.4548C8.1297 21.1339 3.96441 25.3234 3.18864 31.186C1.94871 40.5552 10.5851 48.4704 19.7279 47.1919C24.7322 46.5024 29.3754 43.638 32.7028 39.9113L40.079 48L44.9306 44.0747L36.7406 35.0944ZM14.9879 9.34334C15.3442 6.98844 17.5597 5.99029 19.7279 6.01617C24.3482 6.06482 25.8199 10.1824 22.3154 12.9639C21.196 13.8536 19.964 14.621 18.7351 15.3489L15.6911 12.0114C15.0901 11.3318 14.8333 10.3596 14.9879 9.34334ZM19.7279 41.0588C14.2275 42.3307 8.7791 37.6404 9.53454 31.9304C9.9461 28.8245 12.047 25.9745 15.1548 24.3058C15.9348 23.8867 16.6764 23.4883 17.3804 23.1076L28.4766 35.2763C26.1337 37.9642 23.2779 40.2443 19.7279 41.0588Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default And
