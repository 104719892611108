import { SVGProps } from 'react'
function House(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M9 0c.374 0 .735.136 1.011.382l7.5 6.635.01.01A1.455 1.455 0 0 1 18 8.085v8.455c0 .387-.158.758-.44 1.032A1.521 1.521 0 0 1 16.5 18H12c-.398 0-.78-.154-1.06-.428a1.441 1.441 0 0 1-.44-1.032v-4.382h-3v4.382c0 .387-.158.758-.44 1.032A1.521 1.521 0 0 1 6 18H1.5a1.52 1.52 0 0 1-1.06-.428A1.441 1.441 0 0 1 0 16.54V8.084a1.456 1.456 0 0 1 .479-1.057l.01-.01L7.987.383A1.524 1.524 0 0 1 9 0Z'
        id='HouseShape'
        fill='currentColor'
      />
      <path
        d='m1.507 8.09 7.491-6.628L9 1.46l.002.002 7.49 6.628a.028.028 0 0 1 .007.016l.001 8.434H12v-4.382c0-.387-.158-.759-.44-1.033a1.521 1.521 0 0 0-1.06-.427h-3c-.398 0-.78.154-1.06.427-.282.274-.44.646-.44 1.033v4.382H1.5V8.106a.027.027 0 0 1 .007-.016Z'
        id='HousePath'
        fill={props.fill ?? '#FFFFFF'}
      />
    </svg>
  )
}

export default House
