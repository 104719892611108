import { SVGProps } from 'react'
function Discovery(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='17'
      height='19'
      viewBox='0 0 17 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M15.273 0c.436 0 .727.277.727.692v13.846c0 .416-.291.693-.727.693H2.909c-.145 0-.364 0-.654.207-.146.07-.291.139-.437.277-.145.07-.218.277-.29.416 0 .138-.073.277-.073.484h12.363c.437 0 .727.277.727.693 0 .415-.29.692-.727.692H.728C.29 18 0 17.723 0 17.308V2.769c0-.346.073-.692.218-1.038.146-.346.364-.693.655-.9.218-.277.582-.485.945-.623A2.773 2.773 0 0 1 2.91 0Zm-3.637 1.385H7.273v6.23L9.018 6.37c.291-.207.582-.207.873 0l1.745 1.246v-6.23Z'
        id='DiscoveryShape'
        fill='#423F4C'
      />
      <path
        d='M14.545 13.846H2.91c-.364 0-.727.07-1.09.208-.146.07-.219.07-.364.138V2.77c0-.207 0-.346.072-.554.073-.138.218-.277.364-.415.145-.138.29-.277.436-.346.218 0 .364-.07.582-.07h2.91V9c0 .277.145.485.436.623.218.139.509.07.727-.07l2.473-1.73 2.472 1.73c.146.07.291.14.437.14.145 0 .218 0 .29-.07.219-.138.437-.346.437-.623V1.385h1.454v12.461Z'
        id='DiscoveryPath'
        fill='#FFFFFF'
      />
    </svg>
  )
}

export default Discovery
