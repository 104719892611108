import { SVGProps } from 'react'
function Create(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M14.118 4.01L10.275.169A.57.57 0 009.871 0H2.286a.57.57 0 00-.572.571V15.43a.57.57 0 00.572.571h11.428a.57.57 0 00.572-.571V4.416a.574.574 0 00-.168-.405zm-1.15.669h-3.36V1.318l3.36 3.36zM13 14.714H3V1.286h5.393v3.857a.75.75 0 00.75.75H13v8.821zM8.572 7.286a.143.143 0 00-.143-.143h-.857a.143.143 0 00-.143.143v1.928H5.5a.143.143 0 00-.143.143v.857c0 .079.064.143.143.143H7.43v1.929c0 .078.064.143.143.143h.857a.143.143 0 00.143-.143v-1.929H10.5a.143.143 0 00.143-.143v-.857a.143.143 0 00-.143-.143H8.572V7.286z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Create
