import { Icons } from '@invisible/ui/icons'
import { TIconName } from '@invisible/ui/icons'
import { styled } from '@invisible/ui/themes'
import Link from 'next/link'
import { ElementType, FC } from 'react'
import { Box } from 'rebass'

import { Left } from './Menu'

const LogoLink = styled.a`
  &,
  &:hover,
  &:visited {
    cursor: pointer;
    color: white;
    text-decoration: none;
  }
`

export const Logo: FC<{ icon?: TIconName }> = ({ icon = 'InvisibleIcon', children }) => {
  const Icon = Icons[icon] as ElementType
  return (
    <Left data-cy='logo'>
      <Box pt={2} pr={3} pb={2} pl={3}>
        <Link href='/' shallow passHref>
          <LogoLink>{children ?? <Icon width={32} />}</LogoLink>
        </Link>
      </Box>
    </Left>
  )
}
