import { SVGProps } from 'react'
function FormatSheet(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={46}
      height={47}
      viewBox='0 0 46 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M45.205 42.948a1.838 1.838 0 010 2.613v.002a1.841 1.841 0 01-2.613 0l-1.463-1.483-.073-.075H4.188a1.84 1.84 0 01-1.84-1.84V5.298l-.074-.074L.791 3.763a1.849 1.849 0 012.615-2.614l2.09 2.09 12.54 12.54 12.54 12.54 12.539 12.539 2.09 2.09zM6.455 9.406l-.427-.427v6.266h6.267l-.427-.426-5.413-5.413zm8.183 30.92h.25v-8.86h-8.86v8.86h8.61zm0-12.54h.25v-8.86h-8.86v8.86h8.61zm4.357-5.84l-.427-.427v6.266h6.267l-.427-.427-5.413-5.413zm8.183 18.38h.25v-8.86h-8.86v8.86h8.61zm3.93-.25v.25h6.267l-.427-.428-5.413-5.413-.427-.426v6.016zm-3.68-33.44v-.25h-11.39a1.84 1.84 0 110-3.68h25.77a1.84 1.84 0 011.84 1.84v25.769a1.84 1.84 0 11-3.68 0v-11.39h-10.7a1.84 1.84 0 01-1.84-1.84V6.636zm3.93-.25h-.25v8.86h8.86v-8.86h-8.61z'
        fill='currentColor'
      />
    </svg>
  )
}

export default FormatSheet
