import { SVGProps } from 'react'
function EditData(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0)'>
        <path
          d='M17.2779 48C16.8842 48 16.5019 47.8458 16.2181 47.5602C15.85 47.1921 15.6999 46.6619 15.8182 46.1561L17.9982 36.906C18.0619 36.6339 18.2 36.386 18.3981 36.1901L37.4902 17.098C39.1879 15.3981 42.1542 15.3959 43.8542 17.098L46.6821 19.9259C47.532 20.774 48 21.906 48 23.1061C48 24.308 47.532 25.4399 46.6821 26.2881L27.59 45.3779C27.3919 45.576 27.144 45.7141 26.8741 45.7778L17.6239 47.9579C17.5082 47.9879 17.3921 48 17.2779 48ZM20.8199 38.0079L19.2939 44.484L25.7699 42.958L44.5602 24.1681C44.844 23.8839 45 23.5082 45 23.1079C45 22.708 44.844 22.3301 44.5602 22.0481L41.732 19.2198C41.146 18.6361 40.1961 18.6339 39.6102 19.2198L20.8199 38.0079Z'
          fill='currentColor'
        />
        <path
          d='M40.6721 31.678C40.288 31.678 39.9042 31.5319 39.612 31.2382L32.5401 24.1659C31.9541 23.58 31.9541 22.63 32.5401 22.0441C33.126 21.4581 34.076 21.4581 34.6619 22.0441L41.7342 29.116C42.3201 29.7019 42.3201 30.6519 41.7342 31.2382C41.4401 31.5319 41.0559 31.678 40.6721 31.678Z'
          fill='currentColor'
        />
        <path
          d='M13.6802 42H4.50003C2.01785 42 3.05176e-05 39.9822 3.05176e-05 37.5V4.5C3.05176e-05 2.01782 2.01785 0 4.50003 0H33.4999C35.9821 0 37.9999 2.01782 37.9999 4.5V13.76C37.9999 14.588 37.3279 15.26 36.4999 15.26C35.6719 15.26 34.9999 14.588 34.9999 13.76V4.5C34.9999 3.672 34.3279 3 33.4999 3H4.50003C3.67203 3 3.00003 3.672 3.00003 4.5V37.5C3.00003 38.328 3.67203 39 4.50003 39H13.6802C14.5082 39 15.1802 39.672 15.1802 40.5C15.1802 41.328 14.5082 42 13.6802 42Z'
          fill='currentColor'
        />
        <path
          d='M36.4999 13.0001H1.50003C0.672028 13.0001 3.05176e-05 12.3281 3.05176e-05 11.5001C3.05176e-05 10.6721 0.672028 10.0001 1.50003 10.0001H36.4999C37.3279 10.0001 37.9999 10.6721 37.9999 11.5001C37.9999 12.3281 37.3279 13.0001 36.4999 13.0001Z'
          fill='currentColor'
        />
        <path
          d='M29.1402 22.9999H1.50003C0.672028 22.9999 3.05176e-05 22.3279 3.05176e-05 21.4999C3.05176e-05 20.6719 0.672028 19.9999 1.50003 19.9999H29.1402C29.9682 19.9999 30.6402 20.6719 30.6402 21.4999C30.6402 22.3279 29.9682 22.9999 29.1402 22.9999Z'
          fill='currentColor'
        />
        <path
          d='M19.0002 33H1.50003C0.672028 33 3.05176e-05 32.328 3.05176e-05 31.5C3.05176e-05 30.672 0.672028 30 1.50003 30H19.0002C19.8282 30 20.5002 30.672 20.5002 31.5C20.5002 32.328 19.8282 33 19.0002 33Z'
          fill='currentColor'
        />
        <path
          d='M19.0002 33C18.1721 33 17.5001 32.328 17.5001 31.5V11.5001C17.5001 10.6721 18.1721 10.0001 19.0001 10.0001C19.8281 10.0001 20.5001 10.6721 20.5001 11.5001L20.5002 31.5C20.5002 32.328 19.8282 33 19.0002 33Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EditData
