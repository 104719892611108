import { SVGProps } from 'react'
function TableMode(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#table-mode_svg__clip0)'>
        <path
          d='M15.429 1.711H.57A.57.57 0 000 2.282v11.429a.57.57 0 00.571.572H15.43A.57.57 0 0016 13.71V2.282a.57.57 0 00-.571-.571zm-.715 3.714H10.93V2.997h3.785v2.428zm0 4H10.93V6.568h3.785v2.857zm-8.5-2.857h3.572v2.857H6.214V6.568zm3.572-1.143H6.214V2.997h3.572v2.428zm-8.5 1.143H5.07v2.857H1.286V6.568zm0-3.571H5.07v2.428H1.286V2.997zm0 7.571H5.07v2.429H1.286v-2.429zm4.928 0h3.572v2.429H6.214v-2.429zm8.5 2.429H10.93v-2.429h3.785v2.429z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='table-mode_svg__clip0'>
          <path fill='currentColor' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TableMode
