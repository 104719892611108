import { SVGProps } from 'react'
function ClockFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M7 .875a6.125 6.125 0 100 12.25A6.125 6.125 0 007 .876zm2.413 8.008l-.39.533a.11.11 0 01-.154.023L6.608 7.79a.108.108 0 01-.045-.089V3.938c0-.06.049-.11.109-.11h.657c.06 0 .11.05.11.11V7.32l1.95 1.41c.049.034.06.102.024.152z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ClockFilled
