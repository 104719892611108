import { SVGProps } from 'react'
function LeftArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={7}
      height={12}
      viewBox='0 0 7 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M6.84 2.066V1.031c0-.09-.104-.14-.173-.084L.629 5.662a.427.427 0 000 .674l6.038 4.716a.107.107 0 00.173-.085V9.932a.217.217 0 00-.082-.169L1.936 6l4.822-3.765a.217.217 0 00.082-.169z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LeftArrow
