import { SVGProps } from 'react'
function Teams(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='14'
      viewBox='0 0 18 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M16.875 13.5S18 13.5 18 12.375s-1.125-4.5-5.625-4.5-5.625 3.375-5.625 4.5S7.875 13.5 7.875 13.5h9ZM15.75 3.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM7.011 8.923c.305-.339.19-.908-.26-.972a8.266 8.266 0 0 0-1.126-.076c-4.5 0-5.625 3.375-5.625 4.5 0 .75.375 1.125 1.125 1.125h3.85c.415 0 .696-.444.66-.856a2.518 2.518 0 0 1-.01-.269c0-1.136.424-2.297 1.226-3.267.052-.063.105-.124.16-.185ZM1.687 3.937a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0Z'
        id='TeamsShape'
        fill='currentColor'
      />
      <path
        d='M7.9 12.375a.288.288 0 0 1-.025-.005c.001-.296.188-1.158.855-1.935C9.351 9.708 10.442 9 12.375 9c1.932 0 3.023.709 3.645 1.435.667.777.853 1.64.855 1.935l-.009.003a.395.395 0 0 1-.016.002H7.9Zm4.475-6.75a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5ZM5.535 9A6.18 6.18 0 0 0 4.5 12.375H1.125c0-.293.185-1.159.855-1.94C2.593 9.72 3.659 9.021 5.535 9Zm-.473-7.313a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z'
        id='TeamsPath'
        fill={props.fill ?? '#FFFFFF'}
      />
    </svg>
  )
}

export default Teams
