import { SVGProps } from 'react'
function Comment(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={36}
      height={33}
      viewBox='0 0 40 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M17.771 32.85l-.866.5.866 1.5.866-1.5-.866-.5zm-4.144-7.178l.866-.5-.288-.5h-.578v1zm8.289 0v-1h-.578l-.289.5.867.5zm13.106-4h-1 1zm0-16.822h1-1zM4.52 25.672v-1 1zm14.116 6.678l-4.144-7.178-1.732 1 4.144 7.178 1.732-1zm2.412-7.178l-4.144 7.178 1.732 1 4.145-7.178-1.733-1zm9.973-.5h-9.106v2h9.105v-2zm3-3a3 3 0 01-3 3v2a5 5 0 005-5h-2zm0-16.822v16.822h2V4.85h-2zm-3-3a3 3 0 013 3h2a5 5 0 00-5-5v2zm-26.501 0h26.5v-2h-26.5v2zm-3 3a3 3 0 013-3v-2a5 5 0 00-5 5h2zm0 16.822V4.85h-2v16.822h2zm3 3a3 3 0 01-3-3h-2a5 5 0 005 5v-2zm9.106 0H4.521v2h9.106v-2z'
        fill='currentColor'
        mask='url(#comment_svg__path-1-inside-1)'
      />
      <path d='M17.984 6.85v11.887m5.943-5.944H12.041' stroke='currentColor' />
    </svg>
  )
}

export default Comment
