import { SVGProps } from 'react'
function Fire(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={17}
      viewBox='0 0 16 17'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#fire_svg__clip0)'>
        <path
          fill='currentColor'
          d='M13.752 7.738a6.204 6.204 0 00-1.48-2.057l-.52-.477a.144.144 0 00-.232.06l-.232.665c-.145.418-.41.845-.787 1.265a.112.112 0 01-.074.035.098.098 0 01-.076-.027.105.105 0 01-.036-.085c.066-1.075-.255-2.288-.96-3.607-.581-1.097-1.39-1.952-2.4-2.549L6.216.527a.143.143 0 00-.215.13l.04.858C6.068 2.1 6 2.619 5.84 3.049A5.092 5.092 0 015 4.504a5.279 5.279 0 01-.848.823 6.297 6.297 0 00-1.79 2.17 6.21 6.21 0 00-.153 5.184 6.274 6.274 0 003.346 3.314c.776.325 1.597.49 2.446.49.848 0 1.67-.165 2.444-.488a6.225 6.225 0 001.998-1.334 6.179 6.179 0 001.843-4.409c0-.871-.178-1.718-.534-2.516zm-2.162 6.06A5.063 5.063 0 018 15.267a5.063 5.063 0 01-3.589-1.47 4.96 4.96 0 01-1.482-3.544c0-.777.175-1.521.52-2.214a5.051 5.051 0 011.443-1.748 6.243 6.243 0 001.046-1.015 6.326 6.326 0 001.04-1.803c.099-.269.171-.547.215-.83.43.396.791.875 1.093 1.435.597 1.118.872 2.113.818 2.96a1.322 1.322 0 002.3.967c.238-.266.443-.537.614-.814.25.32.46.668.625 1.043.284.639.429 1.32.429 2.02a4.956 4.956 0 01-1.482 3.542z'
        />
      </g>
      <defs>
        <clipPath id='fire_svg__clip0'>
          <path transform='translate(0 .5)' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Fire
