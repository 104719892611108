import { SVGProps } from 'react'
function Frame(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={198}
      height={334}
      viewBox='0 0 198 334'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#frame_svg__clip0)'>
        <path
          d='M79.519 245.281c-34.542-1.435-61.38-30.6-59.946-65.142 1.435-34.542 30.6-61.38 65.142-59.945 34.542 1.434 61.38 30.599 59.945 65.141-1.434 34.542-30.599 61.381-65.141 59.946z'
          fill='#F1ECE7'
        />
        <path
          d='M197.809 114.643c8.7-2.6 18.5-2 27.5 2.2 7.9 3.7 13.9 10.2 17.5 17.1 0 0 20.3-84.8-57.8-124.5 22.4 27.6 25.2 70 12.8 105.2z'
          fill='#FC7A5E'
        />
        <path
          d='M102.909 81.943s14-5.2 23.7-1.8c8.2 3 19.3 8.7 23.6 15.3l31.9-88.3c-.1-.1-.1 0-.3-.1-32.2 12.8-63 42.6-78.9 74.9z'
          fill='#6A6AF7'
        />
        <path
          d='M54.209 69.743c8.3-3.9 18.3-4.8 28-1.8 8.4 2.6 15.2 7.7 19.8 14.1l.2.2c8.5-3.5 19.3-4.5 28.9-1 8.2 3 15.4 8.8 19.7 15.4l.3-.2c8.8-2.8 18.9-2.3 28.1 2 7.9 3.7 14 9.6 17.7 16.6l.2.2c8.8-2.8 18.9-2.3 28.1 2 8 3.6 13.9 10.1 17.6 17.1v-.2c18.2-52-9.2-108.9-61.2-127.1-52-18.2-110.1 11.3-128.3 63.3M197.409 114.843c13.9-36.1 11-78.1-12.7-106.5M102.009 82.143c11.7-36.9 45.4-63.3 78.6-75.2M182.909 7.743l-31.9 89.4'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.309 115.343l-34 43.3M102.009 82.043l.4 29.2M243.109 134.743l-71.8 50.2M53.11 70.143l28.2 83.8'
          stroke='#0102FA'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M106.109 129.043c-.2-4.8-.3-8.7 3.1-12.4 3.8-4.1 11.3-4.5 16-1.5 7.7 4.9 6.6 21.5 6.4 29.2-.2 11.2-11.6 9-14.6 5.6-3.2-3.7-5-6.1-6.1-9.3'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M130.309 123.543c-5.6 3.7-12.7 3.9-18.5.5-.4-.2-1 0-1.1.4-.4 1.8-1.3 4.8-2.5 5.8-.3.3-.8.3-1.1 0-1-1-3.7-3.5-5.4-2.4-2.1 1.4-2.3 4.7-.6 6.6 1.5 1.7 4 2.3 6.1 1.7.4-.1.8.2.8.6 0 2.4-.1 8.1-.5 9.4-.9 3.4-3.3 6.8-5.5 9.4-3.7 4.4-9.2 5.9-12.9 9.7-3.7 3.8-3.4 11.1-3.4 16.1-.1 12.1-.3 24.3-.4 36.4-.2 17.3 13.5 32.4 24.5 45.7 11.8 1.9 25.3 1.6 36.2-3.2-.3-.4-.6-.7-.9-1.1'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M124.609 131.643c.7 1.1 1.4 2.2 2.1 3.2.4.7 1.4 1.7 1.1 2.6-.3.7-1.2.7-1.8.8-1.2 0-2.4-.1-3.6-.4-.9-.2-.5-1.7.4-1.4.7.2 1.4.3 2.2.3.4 0 1.4.1 1.6-.1-.1.2-.1.4-.2.5 0-.3-.5-.9-.7-1.2-.2-.4-.5-.7-.7-1.1-.5-.8-1.1-1.7-1.6-2.5-.6-.7.7-1.5 1.2-.7zM116.709 136.343c1.1 2 3.3 3.2 5.6 2.8.9-.1 1.4 1.3.4 1.4-2.9.5-5.8-.9-7.3-3.5-.5-.8.8-1.6 1.3-.7z'
          fill='#1A1E21'
        />
        <path
          d='M129.109 150.843c-.9 3.9 1.4 6.1 5.7 7.9 3.7 1.6 7.9 1.7 11.7 3.2 10.7 4.2 15 16.7 17.5 26.8 3.5 14.3 7.1 28.5 10.6 42.8 1.7 6.7 3.3 13.8 1.4 20.5-2.9 9.9-21.3 21.1-29.1 10-1.6-2.3-2.3-5-3-7.6-4.4-16.3-8.9-32.6-13.3-48.9 8.6-.8 16.7-4.6 22.3-11.1 4.3 11.2 8.9 22.6 13.2 33.8'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M127.909 130.243s.1 0 0 0c.9 0 1.6.7 1.6 1.5s-.7 1.5-1.5 1.5h-.1c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5zM118.809 133.543h-.2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h.2c.8 0 1.5.7 1.5 1.5s-.6 1.5-1.5 1.5z'
          fill='#1A1E21'
        />
        <path
          d='M151.809 195.443c-3.8-8.6-7.5-17.2-11.3-25.7-1.1-2.5-2.2-5-4.3-6.9-2.2-2-6.5-3.3-8 .1'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M134.609 170.643c-1.1-2.2-2.4-4.3-3.8-6.4-.5-.7-1.1-1.4-1.9-1.6-1.1-.2-2.2.9-2.3 2.1-.1 1.2.3 2.3.8 3.3s1 2.7 1.9 4.3M126.509 167.943c-1.3.1-2.3 1.3-2.7 2.5-.3 1.3-.1 2.6.1 3.9 1.8 10.3 5 20 6.8 30.3'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M147.109 162.343c-.8 5.3-1.6 10.7-2.4 16-2.8-5.7-5.6-11-8.4-16.7.3-1.3.3-1.2.6-2.5 3.3 1.3 6.8 2.1 10.2 3.2z'
          fill='#1A1E21'
          stroke='#1A1E21'
          strokeMiterlimit={10}
        />
        <path
          d='M137.409 268.743s19.5 53.1 30.7 60.1 27.7 5.9 36.6-12.4c8.8-18.3 8.4-94.7 8.4-94.7-4.3-5.5-12.4-11-16-12.1-3.5-1.2-7.6-1.2-10.8.6-2.7 9.1-5.5 20-8 30.5M176.309 252.243c6.4 14.1 12.8 28.2 19.3 42.3'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M191.009 209.143c-1.6-4.3 3.3-8.9 7.9-8.7 4.6.2 8.6 3.2 12.1 6.3 8.2 7.1 16 14.6 23.3 22.6 1.9 2 3.9 4.7 3 7.3-1.1 3.2-5.5 3.7-8.7 2.9-3.2-.8-10.3-6.5-13.3-5.1'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M95.81 160.343c4.899 23.8 2 48.3-8.1 70.4-3.3-8.5-3.7-60.4-2.2-64.4.7-1.8 6.2-4.5 10.3-6z'
          fill='#1A1E21'
        />
        <path
          d='M120.209 262.243c6.3-3.4 11.5-8.8 14.6-15.2M126.009 261.343c5.1-.8 10-3.3 13.6-7.1M160.509 319.243c-6.3 2.4-13.1-1.6-18.3-5.8-15.3-12.7-26.8-30-32.3-49.1M86.809 220.043c-5.9 20-17.1 41.1-24.6 61.3-11 1.7-16.9-.9-24.8-8.8 10.1-27.3 18.9-52.9 29-80.1 2.1-5.8 4.3-11.6 7.8-16.7s12.2-10.8 18.9-13.2M169.309 209.143c5.7-.2 11.5 2.7 14.7 7.5M237.009 236.743c2.4-1.9 2.6-5.6 1.4-8.4-1.2-2.8-3.5-4.9-5.8-7-6-5.4-12.1-10.8-18.1-16.3-3.1-2.8-6.4-5.7-10.5-6.8-4.1-1.1-8.7-.2-10.2 3.7M129.909 155.743c-3.9 2.4-8.7 3.5-13.2 3.1-4.5-.4-9-2.3-12.5-5.4M138.109 270.943l-8.7 1.2'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M137.609 271.243c3.5 14.5 10 33.3 13.7 47.8 3.9 1 6.3 1.1 8.8 0-9.7-16.6-14.9-31.8-22.2-47.8h-.3z'
          fill='#1A1E21'
        />
        <path
          d='M39.709 274.643c-3.9 6.5-7.7 12.9-11.6 19.4-.6 1-1.2 2.3-.4 3.2.8.9 2.2.5 3.3-.1 2.3-1.3 4.4-3 6.1-4.9 1.5 1 3.8 1.1 5.5.3 1.6-.8 2.8-2.5 2.9-4.4'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M37.509 292.343c-2.4 4.5-4.2 9.3-5.3 14.3-.2.8-.3 1.8.3 2.3.5.5 1.4.4 2 0 .6-.4 1-1 1.4-1.6 2.4-3.6 4.2-6.9 6.6-10.5M51.91 298.843c-3.4.1-8.9-.9-11.4-3.2'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M48.109 298.943c-1.9 4.4-3.6 9.1-5.5 13.5-.3.7-.6 1.4-1.2 1.9-.5.5-1.4.7-2 .4-.9-.5-.9-1.8-.7-2.9.8-4.6 2.4-10 3.9-14.6'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M53.209 299.043c-.5 4.2-1.1 8.8-1.6 13.1-.2 1.4-.8 3.3-2.2 3.1-1.3-.1-1.7-1.9-1.7-3.2 0-4.1-.2-9.1-.2-13.2M59.609 281.543c-.7 6.7-2.2 18.2-2.9 24.9-.1 1-.3 2.1-1.2 2.5-.9.4-1.9-.2-2.4-1-.4-.8-.3-4.7-.2-5.6'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M151.209 96.543l-9 29.4'
          stroke='#0102FA'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M168.609 206.743c1.7-3.9 6.2-7.8 10.4-8.2 4.2-.4 9.7 2.3 12 5.8'
          stroke='#1A1E21'
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d='M106.009 129.543c.4.4.8.7 1 .9.3.3.8.3 1.1 0 1.2-1 2.1-4 2.5-5.8.1-.5.7-.7 1.1-.4 5.6 3.3 13 3 18.6-.4-.9-3.7-2.6-6.8-5.2-8.5-4.7-3-12.2-2.6-16 1.5-3.4 3.5-3.3 8.1-3.1 12.7z'
          fill='#1A1E21'
        />
      </g>
      <defs>
        <clipPath id='frame_svg__clip0'>
          <path transform='matrix(-1 0 0 1 249.209 .843)' fill='#fff' d='M0 0h248.3v332.8H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Frame
