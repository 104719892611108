import { SVGProps } from 'react'
function SmileyStar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={59}
      height={56}
      viewBox='0 0 59 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M28.158 2.518c.45-1.382 2.404-1.382 2.853 0l5.4 16.616a2.5 2.5 0 002.377 1.728h17.47c1.454 0 2.058 1.86.882 2.713l-14.134 10.27a2.5 2.5 0 00-.908 2.794l5.399 16.616c.449 1.382-1.133 2.531-2.309 1.677L31.054 44.663a2.5 2.5 0 00-2.939 0l-14.134 10.27c-1.176.853-2.757-.296-2.308-1.678l5.399-16.616a2.5 2.5 0 00-.909-2.795L2.03 23.575c-1.175-.854-.571-2.713.882-2.713h17.47a2.5 2.5 0 002.378-1.728l5.4-16.616z'
        fill='currentColor'
        stroke='currentColor'
      />
      <circle cx={25.164} cy={26.302} r={2} fill='currentColor' />
      <circle cx={33.595} cy={26.302} r={2} fill='currentColor' />
      <path d='M35.595 33.072a6.216 6.216 0 11-12.43 0h12.43z' fill='currentColor' />
    </svg>
  )
}

export default SmileyStar
