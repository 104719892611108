const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

type TRox = {
  setContext: (arg0: { server: boolean }) => void
  containerCache: Record<string, unknown>
  hasStarted: boolean
  Flag: any
  register: (arg0: string, arg1: unknown) => void
  setup: (arg0: unknown, arg1: { freeze: string }) => void
  setCustomStringProperty: (arg0: string, arg1: string) => void
}

const Rox: TRox = canUseDOM ? require('rox-browser') : require('rox-node')

if (canUseDOM) Rox.setContext({ server: true })

export default Rox
