import { SVGProps } from 'react'
function CaretDown2(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={9}
      height={7}
      viewBox='0 0 9 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M8.469.938H.53c-.238 0-.37.252-.223.423l3.968 4.602a.302.302 0 00.448 0l3.968-4.602c.148-.171.015-.423-.223-.423z'
        fill='currentColor'
        fillOpacity={0.25}
      />
    </svg>
  )
}

export default CaretDown2
