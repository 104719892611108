import { SVGProps } from 'react'
function Screenshot(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M24 30.3448C27.5041 30.3448 30.3448 27.5042 30.3448 24C30.3448 20.4959 27.5041 17.6552 24 17.6552C20.4958 17.6552 17.6552 20.4959 17.6552 24C17.6552 27.5042 20.4958 30.3448 24 30.3448Z'
        fill='currentColor'
      />
      <path
        d='M17.1034 44.1379H3.86207V30.731C3.86207 29.6662 2.99586 28.8 1.93103 28.8C0.866207 28.8 0 29.6607 0 30.731V44.9655C0 46.6372 1.36276 48 3.03448 48H17.1034C18.1683 48 19.0345 47.1338 19.0345 46.069C19.0345 45.0041 18.1683 44.1379 17.1034 44.1379Z'
        fill='currentColor'
      />
      <path
        d='M1.93103 18.869C2.99586 18.869 3.86207 18.0028 3.86207 16.9379V3.86207H17.1034C18.1683 3.86207 19.0345 2.99586 19.0345 1.93103C19.0345 0.866207 18.1683 0 17.1034 0H3.03448C1.36276 0 0 1.36276 0 3.03448V16.9379C0 18.0028 0.866207 18.869 1.93103 18.869Z'
        fill='currentColor'
      />
      <path
        d='M44.9655 0H30.8965C29.8317 0 28.9655 0.866207 28.9655 1.93103C28.9655 2.99586 29.8317 3.86207 30.8965 3.86207H44.1379V16.9379C44.1379 18.0028 45.0041 18.869 46.069 18.869C47.1338 18.869 48 18.0028 48 16.9379V3.03448C48 1.36276 46.6372 0 44.9655 0Z'
        fill='currentColor'
      />
      <path
        d='M46.069 28.8C45.0041 28.8 44.1379 29.6662 44.1379 30.731V44.1379H30.8965C29.8317 44.1379 28.9655 45.0041 28.9655 46.069C28.9655 47.1338 29.8317 48 30.8965 48H44.9655C46.6372 48 48 46.6372 48 44.9655V30.731C48 29.6607 47.1338 28.8 46.069 28.8Z'
        fill='currentColor'
      />
      <path
        d='M34.2069 24C34.2069 18.3724 29.6276 13.7931 24 13.7931C18.3724 13.7931 13.7931 18.3724 13.7931 24C13.7931 29.6276 18.3724 34.2069 24 34.2069C29.6276 34.2069 34.2069 29.6276 34.2069 24ZM24 30.3448C20.5021 30.3448 17.6552 27.4979 17.6552 24C17.6552 20.5021 20.5021 17.6552 24 17.6552C27.4979 17.6552 30.3448 20.5021 30.3448 24C30.3448 27.4979 27.4979 30.3448 24 30.3448Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Screenshot
