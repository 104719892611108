import { NEXTAUTH_URL, useSession } from '@invisible/authentication/client'
import { identify, User } from '@invisible/sentry/internal'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/nextjs'
import { FC, useEffect } from 'react'

export const ErrorBoundary: FC = NEXTAUTH_URL
  ? (props) => {
      const [session] = useSession()
      useEffect(() => {
        if (session?.user) identify(session.user as User)
      }, [session])
      return <SentryErrorBoundary {...props} showDialog />
    }
  : (props) => <SentryErrorBoundary {...props} showDialog />
