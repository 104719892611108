import { SVGProps } from 'react'
function ToastCancel(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path d='M1 1l14 14M15 1L1 15' stroke='currentColor' />
    </svg>
  )
}

export default ToastCancel
