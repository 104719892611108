import { SVGProps } from 'react'

function Envelope(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={14}
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M14 1H1a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-10A.5.5 0 0014 1zm-.625 1.731v8.144H1.625V2.731l-.431-.336.614-.789.669.52h10.048l.669-.52.614.79-.433.335zm-.85-.606L7.5 6.031 2.475 2.125l-.669-.52-.614.789.431.336 5.338 4.15a.875.875 0 001.073 0l5.341-4.149.431-.336-.614-.789-.667.519z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Envelope
