import * as Sentry from '@sentry/nextjs'
import { captureException, captureMessage, Severity } from '@sentry/nextjs'
import { Integrations } from '@sentry/tracing'

import { LOG_LEVEL, SENTRY_DSN, SENTRY_SAMPLE_RATE, SENTRY_TRACE_SAMPLE_RATE } from './config/env'

const SEVERITY_ARRAY = [
  Severity.Debug,
  Severity.Info,
  Severity.Log,
  Severity.Warning,
  Severity.Error,
  Severity.Critical,
  Severity.Fatal,
]

export const { addBreadcrumb, addGlobalEventProcessor, captureEvent } = Sentry

export const init = () => {
  if (SENTRY_DSN)
    Sentry.init({
      sampleRate: SENTRY_SAMPLE_RATE,
      tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
      dsn: SENTRY_DSN,
      // beforeSend to not proactively filter any potential PII data.
      // Reference: https://docs.sentry.io/platforms/node/data-management/sensitive-data/#scrubbing-data
      beforeSend: (event: Sentry.Event) => event,
      integrations: (process as any)['browser']
        ? [new Integrations.BrowserTracing()]
        : [new Integrations.Express()],
    })
}

export interface User extends Omit<Sentry.User, 'id'> {
  id?: string | number
  email?: string
  name?: string
}

type Meta = Record<string, unknown> | Error

export const identify = (user: User) => {
  if (!user.id && !user.email)
    throw new Error('No email or id provided while calling identify in sentry lib')

  Sentry.configureScope((scope) => {
    scope.setUser(user as Sentry.User)
  })
}

const logLevelIndex = SEVERITY_ARRAY.indexOf(LOG_LEVEL)
const logMessage = (msg: string, severity: Severity = Severity.Info, meta: Meta = {}) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(msg, meta)
  }
  const severityIndex = SEVERITY_ARRAY.indexOf(severity)
  if (meta instanceof Error) return captureException(meta)
  if (typeof meta.email === 'string') identify({ email: meta.email })
  if (logLevelIndex > severityIndex) {
    addBreadcrumb({ level: severity, message: msg, data: meta })
    return
  }
  return captureMessage(msg, {
    level: severity,
    extra: meta,
  })
}

export const logger = {
  info: (msg: string, meta?: Meta) => logMessage(msg, Severity.Info, meta),
  warn: (msg: string, meta?: Meta) => logMessage(msg, Severity.Warning, meta),
  error: (msg: string, meta?: Meta) => logMessage(msg, Severity.Error, meta),
  debug: (msg: string, meta?: Meta) => logMessage(msg, Severity.Debug, meta),
}
