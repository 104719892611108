import { SVGProps } from 'react'
function ParseCsv(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0)'>
        <path
          d='M28.5833 35.8989H18.9167C18.24 35.8989 17.7083 36.4348 17.7083 37.1169V46.8609C17.7083 47.543 18.24 48.0789 18.9167 48.0789H28.5833C29.26 48.0789 29.7917 47.543 29.7917 46.8609V37.1169C29.7917 36.4348 29.26 35.8989 28.5833 35.8989ZM27.375 45.6429H20.125V38.3349H27.375V45.6429Z'
          fill='currentColor'
        />
        <path
          d='M12.875 35.8989H3.20833C2.53167 35.8989 2 36.4348 2 37.1169V46.8609C2 47.543 2.53167 48.0789 3.20833 48.0789H12.875C13.5517 48.0789 14.0833 47.543 14.0833 46.8609V37.1169C14.0833 36.4348 13.5517 35.8989 12.875 35.8989ZM11.6667 45.6429H4.41667V38.3349H11.6667V45.6429Z'
          fill='currentColor'
        />
        <path
          d='M44.2917 35.8989H34.625C33.9483 35.8989 33.4167 36.4348 33.4167 37.1169V46.8609C33.4167 47.543 33.9483 48.0789 34.625 48.0789H44.2917C44.9683 48.0789 45.5 47.543 45.5 46.8609V37.1169C45.5 36.4348 44.9683 35.8989 44.2917 35.8989ZM43.0833 45.6429H35.8333V38.3349H43.0833V45.6429Z'
          fill='currentColor'
        />
        <path
          d='M8.04165 20.0649C7.36498 20.0649 6.83332 20.6008 6.83332 21.2829V28.1037L5.28665 26.5447C4.80332 26.0575 4.02998 26.0575 3.59498 26.5447C3.11165 27.0319 3.11165 27.8114 3.59498 28.2499L7.21998 31.9039C7.70332 32.3911 8.47665 32.3911 8.91165 31.9039L12.5366 28.2499C13.02 27.7627 13.02 26.9832 12.5366 26.5447C12.0533 26.0575 11.28 26.0575 10.845 26.5447L9.24998 28.1037V21.2829C9.24998 20.6008 8.71832 20.0649 8.04165 20.0649Z'
          fill='currentColor'
        />
        <path
          d='M23.75 20.0649C23.0733 20.0649 22.5417 20.6008 22.5417 21.2829V28.1037L20.995 26.5447C20.5117 26.0575 19.7383 26.0575 19.3033 26.5447C18.82 27.0319 18.82 27.8114 19.3033 28.2499L22.9283 31.9039C23.4117 32.3911 24.185 32.3911 24.62 31.9039L28.245 28.2499C28.7283 27.7627 28.7283 26.9832 28.245 26.5447C27.7617 26.0575 26.9883 26.0575 26.5533 26.5447L24.9583 28.1037V21.2829C24.9583 20.6008 24.4267 20.0649 23.75 20.0649Z'
          fill='currentColor'
        />
        <path
          d='M39.4583 20.0649C38.7817 20.0649 38.25 20.6008 38.25 21.2829V28.1037L36.7033 26.5447C36.22 26.0575 35.4467 26.0575 35.0117 26.5447C34.5283 27.0319 34.5283 27.8114 35.0117 28.2499L38.6367 31.9039C39.12 32.3911 39.8933 32.3911 40.3283 31.9039L43.9533 28.2499C44.4367 27.7627 44.4367 26.9832 43.9533 26.5447C43.47 26.0575 42.6967 26.0575 42.2617 26.5447L40.6667 28.1037V21.2829C40.6667 20.6008 40.135 20.0649 39.4583 20.0649Z'
          fill='currentColor'
        />
        <path
          d='M43.9583 0.642817H3.95833C3.28167 0.642817 2.75 1.17874 2.75 1.86082V17.944C2.75 18.6261 3.28167 19.162 3.95833 19.162H43.9583C44.635 19.162 45.1667 18.6261 45.1667 17.944L45.1667 1.86082C45.1667 1.17874 44.635 0.642817 43.9583 0.642817ZM42.75 16.726H5.16667V3.07882H42.75V16.726Z'
          fill='currentColor'
        />
        <path
          d='M15.0566 12.996C15.5207 12.996 15.8977 12.8684 16.1877 12.6132C16.4777 12.358 16.6285 12.0186 16.6401 11.5952H18.9979C18.9921 12.2333 18.8181 12.8191 18.4759 13.3527C18.1336 13.8805 17.6638 14.2923 17.0664 14.5881C16.4748 14.8781 15.8194 15.0231 15.1001 15.0231C13.7545 15.0231 12.6931 14.5968 11.9158 13.7442C11.1386 12.8858 10.75 11.7025 10.75 10.1945V10.0292C10.75 8.57914 11.1357 7.422 11.9071 6.55777C12.6786 5.69354 13.7371 5.26143 15.0827 5.26143C16.2602 5.26143 17.2027 5.59784 17.9103 6.27066C18.6238 6.93768 18.9863 7.82801 18.9979 8.94165H16.6401C16.6285 8.45443 16.4777 8.06002 16.1877 7.75841C15.8977 7.451 15.5149 7.29729 15.0392 7.29729C14.4534 7.29729 14.0097 7.5119 13.7081 7.94112C13.4123 8.36453 13.2644 9.05475 13.2644 10.0118V10.2728C13.2644 11.2414 13.4123 11.9374 13.7081 12.3609C14.0039 12.7843 14.4534 12.996 15.0566 12.996Z'
          fill='currentColor'
        />
        <path
          d='M25.5318 12.2478C25.5318 11.9403 25.3781 11.6996 25.0707 11.5256C24.7691 11.3458 24.2818 11.1863 23.609 11.0471C21.3701 10.5773 20.2507 9.62607 20.2507 8.19342C20.2507 7.3582 20.5958 6.66217 21.286 6.10536C21.9821 5.54274 22.8898 5.26143 24.0092 5.26143C25.2041 5.26143 26.1582 5.54274 26.8716 6.10536C27.5908 6.66797 27.9505 7.3988 27.9505 8.29783H25.4361C25.4361 7.93822 25.3201 7.64241 25.0881 7.4104C24.8561 7.17259 24.4935 7.05369 24.0005 7.05369C23.5771 7.05369 23.2494 7.14939 23.0174 7.3408C22.7854 7.5322 22.6694 7.77581 22.6694 8.07162C22.6694 8.35003 22.7999 8.57624 23.0609 8.75024C23.3277 8.91845 23.7743 9.06635 24.4007 9.19396C25.0272 9.31576 25.555 9.45496 25.9842 9.61157C27.3124 10.0988 27.9766 10.9427 27.9766 12.1434C27.9766 13.0018 27.6082 13.6978 26.8716 14.2314C26.135 14.7592 25.1838 15.0231 24.0179 15.0231C23.2291 15.0231 22.5273 14.8839 21.9125 14.6055C21.3034 14.3213 20.8249 13.9356 20.4769 13.4484C20.1289 12.9554 19.9549 12.4247 19.9549 11.8562H22.3388C22.362 12.3029 22.5273 12.6451 22.8347 12.8829C23.1421 13.1207 23.5539 13.2396 24.0701 13.2396C24.5515 13.2396 24.9141 13.1497 25.1577 12.9699C25.4071 12.7843 25.5318 12.5436 25.5318 12.2478Z'
          fill='currentColor'
        />
        <path
          d='M33.1271 11.7779L34.8759 5.43543H37.5034L34.3278 14.8491H31.9265L28.7509 5.43543H31.3784L33.1271 11.7779Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ParseCsv
