import { SVGProps } from 'react'
function ExportToFile(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M30.428 33.9869L38.0298 27.9869C38.081 27.9468 38.1224 27.8956 38.1509 27.8371C38.1794 27.7786 38.1942 27.7144 38.1942 27.6494C38.1942 27.5843 38.1794 27.5201 38.1509 27.4617C38.1224 27.4032 38.081 27.352 38.0298 27.3119L30.428 21.3119C30.144 21.0869 29.7315 21.2904 29.7315 21.6494V25.7208H12.3744C12.1387 25.7208 11.9458 25.9137 11.9458 26.1494V29.1494C11.9458 29.3851 12.1387 29.5779 12.3744 29.5779H29.7315V33.6494C29.7315 34.0083 30.1494 34.2065 30.428 33.9869ZM44.4262 12.2904L35.7155 3.57973C35.3137 3.17794 34.8208 2.8833 34.2851 2.72258V2.57794H4.28512C3.3369 2.57794 2.57083 3.34401 2.57083 4.29223V19.1851C2.57083 19.4208 2.76369 19.6137 2.9994 19.6137H5.9994C6.23512 19.6137 6.42797 19.4208 6.42797 19.1851V6.43509H13.7137V13.7208C13.7137 14.669 14.4798 15.4351 15.428 15.4351H32.5708C33.519 15.4351 34.2851 14.669 34.2851 13.7208V7.60294L41.5708 14.8887V19.1851C41.5708 19.4208 41.7637 19.6137 41.9994 19.6137H44.9994C45.2351 19.6137 45.428 19.4208 45.428 19.1851V14.7119C45.428 13.8012 45.069 12.9333 44.4262 12.2904V12.2904ZM30.8565 12.0065H17.1423V6.43509H30.8565V12.0065ZM44.9994 35.3637H41.9994C41.7637 35.3637 41.5708 35.5565 41.5708 35.7922V41.5779H6.42797V35.7922C6.42797 35.5565 6.23512 35.3637 5.9994 35.3637H2.9994C2.76369 35.3637 2.57083 35.5565 2.57083 35.7922V43.7208C2.57083 44.669 3.3369 45.4351 4.28512 45.4351H43.7137C44.6619 45.4351 45.428 44.669 45.428 43.7208V35.7922C45.428 35.5565 45.2351 35.3637 44.9994 35.3637Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ExportToFile
