import { SVGProps } from 'react'
function EnvelopeSearch(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M6.06126 4C3.83802 4 2 5.83366 2 8.04785V27.6182C2 29.8324 3.83802 31.6629 6.06126 31.6629H23.0595C23.3982 33.6177 24.3159 35.4898 25.8246 36.9923C29.3284 40.4818 34.8257 40.819 38.7271 38.0238L44.2572 43.5314C44.8847 44.1562 45.9019 44.1562 46.5295 43.5314C47.1568 42.9064 47.1568 41.8933 46.5295 41.2683L40.9962 35.7576C43.801 31.8719 43.4666 26.3995 39.9637 22.9108C39.181 22.1313 38.2947 21.517 37.3524 21.051V8.04785C37.3524 5.83366 35.5112 4 33.288 4H6.06126ZM6.31548 7.20077H33.0338L20.828 17.8471C20.0743 18.5045 19.275 18.5045 18.5212 17.8471L6.31548 7.20077ZM34.1385 10.4922V20.0914C33.7246 20.0401 33.3091 20.0039 32.8926 20.0039C30.33 20.0039 27.77 20.9733 25.8246 22.9108C24.2591 24.4699 23.3339 26.4277 23.0282 28.4621H6.06126C5.56291 28.4621 5.21385 28.1145 5.21385 27.6182V10.4953L16.4059 20.2539C18.2649 21.8754 21.0835 21.874 22.9434 20.2539L34.1385 10.4922ZM37.6914 25.1738C40.3541 27.8258 40.3511 32.0774 37.6881 34.7293C35.0254 37.3812 30.7595 37.3812 28.0968 34.7293C25.4341 32.0774 25.4341 27.8258 28.0968 25.1738C29.4282 23.8479 31.159 23.1859 32.8925 23.1859C34.626 23.1859 36.36 23.8479 37.6914 25.1738Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default EnvelopeSearch
