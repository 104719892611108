import { SVGProps } from 'react'
function CloseX(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 23 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M12 1l10 10M22 1L12 11' stroke='currentColor' />
    </svg>
  )
}

export default CloseX
