import { SVGProps } from 'react'
function SortRemovedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width ?? '30'}
      height={props.width ?? '30'}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 20.1027H14.5C14.7761 20.1027 15 20.3265 15 20.6027V23.6027C15 23.8788 14.7761 24.1027 14.5 24.1027H1.5C1.22386 24.1027 1 23.8788 1 23.6027V20.6027C1 20.3265 1.22386 20.1027 1.5 20.1027ZM1.5 3H24.5C24.7761 3 25 3.22386 25 3.5V6.5C25 6.77614 24.7761 7 24.5 7H1.5C1.22386 7 1 6.77614 1 6.5V3.5C1 3.22386 1.22386 3 1.5 3ZM1.5 11.5513H17.5C17.7761 11.5513 18 11.7752 18 12.0513V15.0513C18 15.3275 17.7761 15.5513 17.5 15.5513H1.5C1.22386 15.5513 1 15.3275 1 15.0513V12.0513C1 11.7752 1.22386 11.5513 1.5 11.5513Z'
        fill={props.fill ?? '#604CA5'}
      />
    </svg>
  )
}

export default SortRemovedIcon
