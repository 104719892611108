import { SVGProps } from 'react'
function Panel(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M5 13.25h7.75a.5.5 0 00.5-.5V4.312H5v8.938zM12.75.75H5v2.563h8.25V1.25a.5.5 0 00-.5-.5zm-12 .5v11.5a.5.5 0 00.5.5H4V.75H1.25a.5.5 0 00-.5.5z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Panel
