import { SVGProps } from 'react'
function DocInput(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={42}
      height={45}
      viewBox='0 0 42 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M41.5 32.272H29.651l5.134-5.61-2.462-2.28-8.761 9.574 8.761 9.578 2.462-2.28-5.134-5.614H41.5v-3.368z'
        fill='currentColor'
      />
      <path
        d='M4.324 4.206h13.493v10.906a2.88 2.88 0 002.867 2.887H31.52v4.377h3.824v-9.91L22.252.357H3.368A2.88 2.88 0 00.5 3.244V41.47a2.88 2.88 0 002.868 2.886h22.34v-3.849H4.324V4.206zm27.191 9.944H21.64V5.019l9.875 9.13z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DocInput
