import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fontSizes, gray, space, styled } from '@invisible/ui/themes'
import { motion } from 'framer-motion'
import { FC, useState } from 'react'
import { Box, Flex, FlexProps } from 'rebass'

export interface IMenu {
  right?: boolean
}

const Menu = styled(Flex as FC<FlexProps>)<IMenu>`
  align-items: center;
  justify-content: ${(props) => (props.right ? 'flex-end' : 'flex-start')};
  flex-grow: 1;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: normal;

  & a,
  & a:visited {
    color: white;
    font-size: 14px;
    font-weight: 400;
    margin: 0 19px;
    text-decoration: none;
    margin-top: 2px;
    padding: 0 4px;
    transition: border-bottom 0.3s ease;
    text-decoration: none;
    padding-bottom: 4px;
    &:hover,
    &:active,
    &[data-active='true'] {
      color: white;
      border-bottom: 2px solid #6300ff;
    }
  }
`

const MenuItem = styled.a<{ active?: boolean }>`
  border-bottom: ${(p) => (p.active ? '2px solid #6300ff;' : 'none')} !important;
  cursor: pointer;
`

const DropMenuContainer: typeof Flex = styled(motion(Flex))`
  &,
  &:hover,
  &:active {
    border: none !important;
  }
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  margin: -10px;
  width: 60px;

  > .label {
    color: white;
    display: block;
    font-size: 14px;
    margin: 0 19px;
  }
`

const DropMenuListContainer = styled(motion.div)`
  display: flex;
  padding: 5px 0;
  opacity: 0;
  display: 'none';
  flex-direction: column;
  position: absolute;
  top: 50px;
  background: ${gray(1)};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  max-height: 80vh;
  overflow-y: scroll;
  a,
  a:hover,
  a:active,
  a:visited {
    color: ${gray(13)};
    font-size: ${fontSizes(2)};
    line-height: ${(props) => `${space(3)}`};
    font-weight: 400;
    text-decoration: none;
    padding: 9px 10px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f4edff;
    }
  }
`

const Left = styled(Flex as FC<FlexProps>)`
  flex-growth: 0;
  align-items: center;
  justify-content: center;
`

const Right = styled(Left)`
  color: #000;
  font-size: 12px;
  justify-content: flex-end;
`

const Down = styled(FontAwesomeIcon)`
  position: relative;
  top: 2px;
  width: 14px;
  color: #999;
  margin: 0 -10px 0 10px;
`

export interface IDropMenu {
  name?: string
  rootComponent?: FC
}

const DropMenu: FC<IDropMenu> = ({ children, name, rootComponent, ...props }) => {
  const [isOpen, setOpen] = useState(false)

  const Root =
    rootComponent ??
    (() => (
      <Box className='label' mt='20px'>
        {name} <Down icon={faChevronDown} />
      </Box>
    ))

  const variants = {
    open: { opacity: 1, display: 'flex' },
    closed: { opacity: 0, transitionEnd: { display: 'none' } },
  }

  return (
    <DropMenuContainer onClick={() => setOpen(!isOpen)} data-cy='dropMenu' {...props}>
      <Root />
      <DropMenuListContainer animate={isOpen ? variants.open : variants.closed}>
        {children}
      </DropMenuListContainer>
    </DropMenuContainer>
  )
}

export { DropMenu, Left, Menu, MenuItem, Right }
