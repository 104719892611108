import { SVGProps } from 'react'
function Pause(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M3.312 1.75h1.25v10.5h-1.25V1.75zm6.375 0h-1a.125.125 0 00-.125.125v10.25c0 .068.056.124.125.124h1a.125.125 0 00.125-.125V1.874a.125.125 0 00-.125-.124z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Pause
