import { NEXT_PUBLIC_ROX_KEY } from '../config/env'
import Rox from './instance'

export default function RolloutService(container: any) {
  if (!Rox.hasStarted) {
    Rox.hasStarted = true
    Rox.register('', container)
    Rox.setup(NEXT_PUBLIC_ROX_KEY, {
      freeze: 'none',
    })
  }
}
