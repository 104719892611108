import { SVGProps } from 'react'
function TagsOutlined(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={36}
      height={36}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#tags-outlined_svg__clip0)'>
        <path
          d='M16.843 29.188l15.195-15.2c.068-.067.1-.16.093-.252l-1.025-12.11a.644.644 0 00-.587-.587L18.41.02a.313.313 0 00-.253.092L2.961 15.307a.323.323 0 000 .454l13.428 13.427c.124.129.329.129.454 0zm2.515-26.184l9.024.763.763 9.025-12.532 12.527-9.787-9.783L19.358 3.004zm2.417 7.482a1.928 1.928 0 102.727-2.727 1.928 1.928 0 00-2.727 2.727zm11.4 8.638l-1.591-1.587a.323.323 0 00-.454 0L16.585 32.053 7.04 22.531a.323.323 0 00-.454 0l-1.591 1.587a.323.323 0 000 .454l9.771 9.755 1.591 1.587c.125.125.33.125.454 0l16.365-16.336a.322.322 0 000-.454z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='tags-outlined_svg__clip0'>
          <path fill='currentColor' d='M0 0h36v36H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TagsOutlined
