import { SVGProps } from 'react'
function People(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={36}
      height={36}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M30.544 25.555a12.117 12.117 0 00-3.471-2.426 8.018 8.018 0 002.981-6.248c0-4.452-3.712-8.104-8.164-8.036-4.384.069-7.915 3.64-7.915 8.036a8.03 8.03 0 002.981 6.248 12.09 12.09 0 00-3.471 2.426 11.976 11.976 0 00-3.52 8.193.321.321 0 00.321.33h2.25a.32.32 0 00.322-.31 9.081 9.081 0 012.68-6.167 9.105 9.105 0 016.48-2.684c2.447 0 4.75.952 6.481 2.684a9.118 9.118 0 012.68 6.167.32.32 0 00.322.31h2.25a.323.323 0 00.321-.33 11.948 11.948 0 00-3.528-8.192zm-8.525-3.531a5.099 5.099 0 01-3.637-1.507 5.097 5.097 0 01-1.506-3.688 5.15 5.15 0 011.458-3.536 5.122 5.122 0 013.632-1.555 5.173 5.173 0 013.657 1.47 5.105 5.105 0 011.543 3.673 5.106 5.106 0 01-1.507 3.636 5.116 5.116 0 01-3.64 1.507zm-10.065-4.082a10.226 10.226 0 01.116-2.929.324.324 0 00-.18-.354 5.076 5.076 0 01-1.483-1.008 5.125 5.125 0 01-1.555-3.833 5.117 5.117 0 011.458-3.44 5.116 5.116 0 013.745-1.555 5.136 5.136 0 014.275 2.363.32.32 0 00.374.128 9.968 9.968 0 012.222-.498.323.323 0 00.253-.466 8.05 8.05 0 00-7.06-4.415c-4.455-.069-8.168 3.583-8.168 8.031a8.018 8.018 0 002.981 6.248 12.08 12.08 0 00-3.475 2.427 11.95 11.95 0 00-3.528 8.196.321.321 0 00.322.33h2.254a.32.32 0 00.321-.31 9.081 9.081 0 012.68-6.167 9.07 9.07 0 014.207-2.399.321.321 0 00.24-.35z'
        fill='currentColor'
      />
    </svg>
  )
}

export default People
