import { SVGProps } from 'react'
function SearchInbox(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M20.0864 1.34036e-07C14.9404 0.000591566 9.79336 1.95942 5.87669 5.87615C-1.95666 13.7096 -1.96001 26.4591 5.87334 34.2926C13.2979 41.7172 25.1346 42.094 33.0205 35.4444L45.0739 47.498C45.7434 48.1673 46.8286 48.1673 47.498 47.498C48.1673 46.8286 48.1673 45.7433 47.498 45.0739L35.4445 33.0203C42.0941 25.1343 41.7173 13.2974 34.2928 5.8728C30.3761 1.95607 25.2324 -0.000591298 20.0864 1.34036e-07ZM31.8687 8.29692C38.3918 14.8201 38.3918 25.3452 31.8687 31.8685C25.3455 38.3917 14.8206 38.3917 8.29743 31.8685C1.77429 25.3452 1.77763 14.8235 8.30077 8.30027C11.5623 5.03866 15.825 3.40574 20.0864 3.40515C24.3478 3.40456 28.6071 5.03531 31.8687 8.29692Z'
        fill='currentColor'
      />
      <path
        d='M11.7058 10.9922C9.96828 10.9922 8.51501 12.4455 8.51501 14.1831V25.989C8.51501 27.7266 9.96828 29.1765 11.7058 29.1765H28.4669C30.2044 29.1765 31.6543 27.7266 31.6543 25.989V14.1831C31.6543 12.4455 30.2044 10.9922 28.4669 10.9922H11.7058ZM13.9023 14.4208H26.2671L20.3241 19.5202C20.1161 19.6986 20.0533 19.6986 19.8453 19.5202L13.9023 14.4208ZM10.9999 16L17.612 22.1218C19.0071 23.3184 21.1623 23.3184 22.5573 22.1218L28.9996 16V25.989L10.9999 26V16Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SearchInbox
