import { SVGProps } from 'react'
function PersonSearch(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={48}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M27.914 22.145a15.897 15.897 0 00-4.19-2.226c2.959-1.79 4.938-5.029 4.938-8.723C28.662 5.574 24.072 1 18.43 1 12.788 1 8.194 5.574 8.194 11.196c0 3.694 1.983 6.934 4.938 8.723C6.948 22.1 2.5 27.983 2.5 34.887h2.619c0-7.314 5.97-13.264 13.31-13.264 2.552 0 4.94.72 6.967 1.968.778-.57 1.62-1.055 2.518-1.446zm-17.101-10.95c0-4.182 3.416-7.585 7.617-7.585 4.201 0 7.613 3.403 7.613 7.586s-3.415 7.586-7.613 7.586-7.617-3.403-7.617-7.586zM46.5 44.155l-4.254-4.239a10.525 10.525 0 001.643-5.655c0-5.857-4.782-10.621-10.659-10.621-5.877 0-10.659 4.764-10.659 10.62 0 5.857 4.782 10.622 10.659 10.622 2.847 0 5.432-1.119 7.348-2.938L44.648 46l1.852-1.845zm-13.27-1.883c-4.433 0-8.04-3.594-8.04-8.011 0-4.418 3.607-8.012 8.04-8.012 4.433 0 8.04 3.594 8.04 8.012 0 4.417-3.607 8.011-8.04 8.011z'
        fill='currentColor'
      />
    </svg>
  )
}

export default PersonSearch
