import { SVGProps } from 'react'
function Megaphone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M20.036 1.182a.745.745 0 00-.285.056L5.6 6.925H1.572a.4.4 0 00-.393.407v7.342a.4.4 0 00.393.407h2.497c-.09.285-.14.587-.14.894a2.943 2.943 0 002.946 2.934c1.36 0 2.507-.923 2.846-2.17l10.033 4.031a.795.795 0 00.285.057.803.803 0 00.785-.815V1.997a.805.805 0 00-.788-.815zM6.876 17.149c-.651 0-1.18-.526-1.18-1.174 0-.275.097-.538.27-.746l2.086.837a1.182 1.182 0 01-1.177 1.083zm12.178 1.434L6.257 13.44l-.317-.127H2.947V8.693H5.94l.317-.128 12.797-5.142v15.16z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Megaphone
