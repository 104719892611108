import { createGlobalStyle } from '@invisible/ui/themes'
import Head from 'next/head'

import { Header } from './header'

const GlobalStyle = createGlobalStyle`
main {
  margin-top: 50px;
}`
export const Layout = ({ children }) => (
  <>
    <Head>
      <title>Welcome to process builder!</title>
    </Head>
    <Header />
    <main>{children}</main>
    <GlobalStyle />
  </>
)
