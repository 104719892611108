import { SVGProps } from 'react'
function PlusCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={49}
      height={49}
      viewBox='0 0 49 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g filter='url(#plus-circle_svg__filter0_d)'>
        <rect x={8.801} y={4.323} width={31.887} height={31.887} rx={15.943} fill='#fff' />
        <path d='M24.745 14.323V26.21m5.943-5.943H18.801' stroke='currentColor' />
        <rect
          x={9.301}
          y={4.823}
          width={30.887}
          height={30.887}
          rx={15.443}
          stroke='currentColor'
        />
      </g>
      <defs>
        <filter
          id='plus-circle_svg__filter0_d'
          x={0.801}
          y={0.323}
          width={47.887}
          height={47.887}
          filterUnits='userSpaceOnUse'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default PlusCircle
