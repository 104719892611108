import { SVGProps } from 'react'
function Work(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path d='M18 9A9 9 0 1 1 0 9a9 9 0 0 1 18 0Z' id='WorkShape' fill='currentColor' />
      <path
        d='M16.65 9a7.65 7.65 0 1 1-15.3 0 7.65 7.65 0 0 1 15.3 0Z'
        id='WorkPath'
        fill='#FFFFFF'
      />
      <path
        d='M13.132 4.86a.535.535 0 0 0-.686-.06c-2.6 1.825-4.068 2.906-4.404 3.242a1.352 1.352 0 0 0 0 1.91 1.352 1.352 0 0 0 1.91 0c.196-.198 1.275-1.666 3.237-4.407.15-.21.127-.5-.057-.684Zm1.714 4.137a.9.9 0 1 0-1.8 0 .9.9 0 0 0 1.8 0Zm-9.899 0a.9.9 0 1 0-1.8 0 .9.9 0 0 0 1.8 0Zm1.186-2.864A.9.9 0 1 0 4.86 4.861a.9.9 0 0 0 1.274 1.272Zm3.764-2.086a.9.9 0 1 0-1.801 0 .9.9 0 0 0 1.8 0Z'
        id='WorkShape2'
        fill='currentColor'
      />
    </svg>
  )
}

export default Work
