import { SVGProps } from 'react'
function CircledDollar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M7 0.50293C3.13438 0.50293 0 3.6373 0 7.50293C0 11.3686 3.13438 14.5029 7 14.5029C10.8656 14.5029 14 11.3686 14 7.50293C14 3.6373 10.8656 0.50293 7 0.50293ZM7 13.3154C3.79063 13.3154 1.1875 10.7123 1.1875 7.50293C1.1875 4.29355 3.79063 1.69043 7 1.69043C10.2094 1.69043 12.8125 4.29355 12.8125 7.50293C12.8125 10.7123 10.2094 13.3154 7 13.3154ZM7.74531 7.14043L7.34844 7.04824V4.9498C7.94219 5.03105 8.30937 5.40293 8.37187 5.85918C8.37969 5.92168 8.43281 5.96699 8.49531 5.96699H9.19687C9.27031 5.96699 9.32812 5.90293 9.32187 5.82949C9.22656 4.85605 8.425 4.23105 7.35469 4.12324V3.6123C7.35469 3.54355 7.29844 3.4873 7.22969 3.4873H6.79063C6.72188 3.4873 6.66563 3.54355 6.66563 3.6123V4.12793C5.55937 4.23574 4.69375 4.84668 4.69375 5.9873C4.69375 7.04355 5.47188 7.55293 6.28906 7.74824L6.675 7.84668V10.0764C5.98438 9.98418 5.59688 9.61543 5.51719 9.11856C5.50781 9.05918 5.45469 9.01543 5.39375 9.01543H4.67188C4.59844 9.01543 4.54063 9.07793 4.54688 9.15137C4.61719 10.0107 5.26875 10.8014 6.65938 10.9029V11.3936C6.65938 11.4623 6.71563 11.5186 6.78438 11.5186H7.22812C7.29687 11.5186 7.35312 11.4623 7.35312 11.392L7.35 10.8967C8.57344 10.7889 9.44844 10.1342 9.44844 8.95918C9.44688 7.8748 8.75781 7.39043 7.74531 7.14043ZM6.67344 6.8873C6.58594 6.8623 6.5125 6.83887 6.43906 6.80918C5.91094 6.61855 5.66563 6.31074 5.66563 5.91387C5.66563 5.34668 6.09531 5.02324 6.67344 4.9498V6.8873ZM7.34844 10.0811V7.99199C7.39687 8.00605 7.44062 8.01699 7.48594 8.02637C8.225 8.25137 8.47344 8.56387 8.47344 9.04355C8.47344 9.65449 8.01406 10.0217 7.34844 10.0811Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CircledDollar
