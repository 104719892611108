import { SVGProps } from 'react'
function DocCSV(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M8 0V9.89691H0V27.7113H8V48H37L48 37V0H8ZM2 25.732V11.8763H32V25.732H2ZM35.5 45.0309V35.6289H45.172L35.5 45.0309ZM45.172 32.4654H32.5V45.0309L11 45V27.7113H34V9.89691L11 10V3H27.5H45.172V17.8105V32.4654Z'
        fill='currentColor'
      />
      <path
        d='M8.834 20.8112C8.834 21.2704 8.806 21.6406 8.75 21.9216C8.696 22.2027 8.62 22.4204 8.522 22.5748C8.426 22.7292 8.312 22.8322 8.18 22.8836C8.048 22.9351 7.906 22.9608 7.754 22.9608C7.57 22.9608 7.408 22.9212 7.268 22.846C7.128 22.7728 7.012 22.6263 6.92 22.4066C6.828 22.1869 6.758 21.8821 6.716 21.4862C6.672 21.0903 6.65 20.5717 6.65 19.9304C6.65 19.3287 6.664 18.82 6.698 18.4043C6.73 17.9866 6.786 17.6501 6.866 17.3928C6.946 17.1355 7.056 16.9514 7.196 16.8366C7.336 16.7198 7.514 16.6624 7.73 16.6624C8.122 16.6624 8.392 16.803 8.54 17.086C8.686 17.3651 8.76 17.8144 8.76 18.4379H10.486C10.486 18.1292 10.464 17.7986 10.42 17.4463C10.378 17.0939 10.264 16.7654 10.09 16.4605C9.916 16.1557 9.65 15.9043 9.294 15.7064C8.938 15.5085 8.454 15.4095 7.836 15.4095C7.19 15.4095 6.672 15.5203 6.29 15.7361C5.906 15.9538 5.614 16.2586 5.408 16.6446C5.206 17.0326 5.072 17.4997 5.012 18.0401C4.954 18.5824 4.922 19.1743 4.922 19.8156C4.922 20.4628 4.954 21.0586 5.012 21.595C5.072 22.1334 5.204 22.5966 5.408 22.9846C5.612 23.3725 5.906 23.6694 6.29 23.8753C6.672 24.0792 7.19 24.1841 7.836 24.1841C8.404 24.1841 8.866 24.095 9.222 23.9208C9.578 23.7466 9.852 23.5051 10.05 23.1983C10.244 22.8896 10.38 22.5293 10.452 22.1176C10.524 21.7059 10.56 21.2684 10.56 20.8112H8.834Z'
        fill='currentColor'
      />
      <path
        d='M18.034 20.9042C17.986 20.6885 17.9 20.4945 17.776 20.3164C17.654 20.1382 17.486 19.972 17.278 19.8176C17.072 19.6612 16.804 19.5127 16.476 19.3702L15.144 18.8002C14.776 18.6517 14.536 18.4874 14.424 18.3073C14.312 18.1292 14.256 17.9114 14.256 17.6482C14.256 17.5215 14.268 17.3988 14.298 17.28C14.326 17.1612 14.378 17.0524 14.454 16.9613C14.53 16.8703 14.634 16.797 14.76 16.7416C14.888 16.6882 15.048 16.6585 15.24 16.6585C15.576 16.6585 15.816 16.7693 15.96 16.9851C16.102 17.2048 16.174 17.5156 16.174 17.9193H17.83V17.6818C17.83 17.278 17.762 16.9316 17.632 16.6426C17.5 16.3556 17.316 16.1181 17.08 15.932C16.844 15.744 16.564 15.6114 16.24 15.5283C15.916 15.4451 15.562 15.4036 15.178 15.4036C14.354 15.4036 13.704 15.6094 13.234 16.0211C12.762 16.4328 12.526 17.0563 12.526 17.8956C12.526 18.2459 12.57 18.5508 12.658 18.818C12.746 19.0832 12.884 19.3168 13.072 19.5187C13.26 19.7226 13.494 19.8987 13.774 20.0472C14.056 20.1976 14.388 20.3381 14.77 20.4648C15.058 20.5598 15.302 20.6529 15.496 20.7439C15.69 20.835 15.848 20.9339 15.97 21.0468C16.09 21.1556 16.176 21.2803 16.228 21.4189C16.278 21.5574 16.306 21.7217 16.306 21.9118C16.306 22.2661 16.198 22.5313 15.988 22.7015C15.776 22.8718 15.54 22.9569 15.274 22.9569C15.052 22.9569 14.864 22.9232 14.718 22.8619C14.57 22.7985 14.45 22.7114 14.362 22.5986C14.274 22.4878 14.216 22.3512 14.182 22.1889C14.152 22.0285 14.134 21.8484 14.134 21.6505V21.4031H12.406V21.7475C12.406 22.5313 12.614 23.133 13.034 23.5527C13.456 23.9723 14.154 24.1821 15.13 24.1821C16.066 24.1821 16.796 23.9762 17.32 23.5685C17.844 23.1607 18.106 22.5095 18.106 21.6148C18.106 21.3555 18.082 21.116 18.034 20.9042Z'
        fill='currentColor'
      />
      <path
        d='M23.978 15.5698L22.706 21.8405H22.668L21.462 15.5698H19.614L21.58 24.0495H23.8L25.768 15.5698H23.978Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default DocCSV
