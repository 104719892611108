import { SVGProps } from 'react'
function RenameColumn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M46.3256 4.35883H1.67437C0.749719 4.35883 0 5.10854 0 6.0333V19.4319C0 20.3566 0.749719 21.1062 1.67437 21.1062H3.55106V41.9667C3.55106 42.8915 4.30078 43.6411 5.22544 43.6411H42.7745C43.6992 43.6411 44.4488 42.8914 44.4488 41.9667V21.1063H46.3254C47.2502 21.1063 47.9998 20.3566 47.9998 19.432V6.0333C48 5.10854 47.2503 4.35883 46.3256 4.35883ZM22.3398 40.2925H6.89991V32.3738H22.3397V40.2925H22.3398ZM22.3398 29.0249H6.89991V21.1062H22.3397V29.0249H22.3398ZM41.1002 40.2924H25.6886V32.3737H41.1002V40.2924ZM41.1002 29.0249H41.1001H25.6886V21.1062H41.1002V29.0249ZM44.6512 17.7575C44.1092 17.7575 3.96628 17.7575 3.34884 17.7575V7.70767H44.6512V17.7575Z'
        fill='currentColor'
      />
      <path
        d='M27.0732 12.6909H20.9268C20.002 12.6909 19.2524 13.4406 19.2524 14.3653C19.2524 15.29 20.0021 16.0397 20.9268 16.0397H27.0732C27.998 16.0397 28.7476 15.2899 28.7476 14.3653C28.7477 13.4406 27.998 12.6909 27.0732 12.6909Z'
        fill='currentColor'
      />
      <path
        d='M15.0299 12.6909H8.88347C7.95872 12.6909 7.20909 13.4406 7.20909 14.3653C7.20909 15.29 7.95881 16.0397 8.88347 16.0397H15.0299C15.9547 16.0397 16.7043 15.2899 16.7043 14.3653C16.7044 13.4406 15.9547 12.6909 15.0299 12.6909Z'
        fill='currentColor'
      />
      <path
        d='M39.145 12.6909H32.9986C32.0738 12.6909 31.3242 13.4406 31.3242 14.3653C31.3242 15.29 32.0739 16.0397 32.9986 16.0397H39.145C40.0698 16.0397 40.8194 15.2899 40.8194 14.3653C40.8194 13.4406 40.0697 12.6909 39.145 12.6909Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default RenameColumn
