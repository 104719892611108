import { SVGProps } from 'react'
function File(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={15}
      viewBox='0 0 12 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.353 4.016L7.991.654a.5.5 0 00-.353-.147H1a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V4.371a.503.503 0 00-.147-.355zm-1.006.585h-2.94V1.66l2.94 2.94zm.028 8.781h-8.75V1.632h4.719v3.375A.656.656 0 007 5.663h3.375v7.72zm-4.5-4.219H3a.125.125 0 00-.125.125v.75c0 .07.056.125.125.125h2.875A.125.125 0 006 10.038v-.75a.125.125 0 00-.125-.125zm-3-2v.75c0 .07.056.125.125.125h6a.125.125 0 00.125-.125v-.75A.125.125 0 009 7.038H3a.125.125 0 00-.125.125z'
        fill='currentColor'
      />
    </svg>
  )
}

export default File
