import { SVGProps } from 'react'
function ClickOn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath='url(#clip0)'>
        <path
          d='M47.5881 39.6332L39.478 31.523L43.4555 27.5456C43.8001 27.201 43.9418 26.7025 43.8297 26.2281C43.7177 25.7538 43.3682 25.3713 42.9058 25.2171L19.041 17.2622C18.5358 17.0937 17.9786 17.2252 17.602 17.6019C17.2253 17.9786 17.0937 18.5357 17.2622 19.041L25.2172 42.9059C25.3714 43.3682 25.7539 43.7178 26.2282 43.8298C26.7025 43.942 27.201 43.8003 27.5457 43.4555L31.5232 39.4781L39.6333 47.5881C39.9079 47.8627 40.2678 48.0001 40.6276 48.0001C40.9874 48.0001 41.3474 47.8628 41.6219 47.5881L47.5882 41.6219C48.1374 41.0727 48.1374 40.1823 47.5881 39.6332ZM40.6275 44.605L32.5174 36.495C31.9683 35.9458 31.0778 35.9458 30.5287 36.495L27.1658 39.8579L20.8197 20.8198L39.8578 27.1658L36.4949 30.5287C35.9462 31.0773 35.9457 31.9683 36.4949 32.5174L44.6051 40.6276L40.6275 44.605Z'
          fill='currentColor'
        />
        <path
          d='M18.2812 11.25C19.0579 11.25 19.6875 10.6204 19.6875 9.84375V1.40625C19.6875 0.629625 19.0579 0 18.2812 0C17.5046 0 16.875 0.629625 16.875 1.40625V9.84375C16.875 10.6204 17.5046 11.25 18.2812 11.25Z'
          fill='currentColor'
        />
        <path
          d='M7.34319 5.35446C6.7941 4.80527 5.90366 4.80527 5.35447 5.35446C4.80528 5.90364 4.80528 6.79399 5.35447 7.34318L11.3207 13.3094C11.8698 13.8586 12.7603 13.8586 13.3094 13.3094C13.8586 12.7602 13.8586 11.8699 13.3094 11.3207L7.34319 5.35446Z'
          fill='currentColor'
        />
        <path
          d='M11.3207 23.2531L5.35447 29.2193C4.80528 29.7685 4.80528 30.6589 5.35447 31.208C5.90357 31.7572 6.7941 31.7572 7.34319 31.208L13.3094 25.2418C13.8586 24.6926 13.8586 23.8023 13.3094 23.2531C12.7603 22.7039 11.8698 22.7039 11.3207 23.2531Z'
          fill='currentColor'
        />
        <path
          d='M25.2418 13.3094L31.208 7.34318C31.7572 6.79399 31.7572 5.90364 31.208 5.35446C30.6589 4.80527 29.7685 4.80527 29.2193 5.35446L23.2531 11.3207C22.7039 11.8699 22.7039 12.7602 23.2531 13.3094C23.8022 13.8586 24.6927 13.8586 25.2418 13.3094Z'
          fill='currentColor'
        />
        <path
          d='M11.25 18.2812C11.25 17.5046 10.6204 16.875 9.84375 16.875H1.40625C0.629625 16.875 0 17.5046 0 18.2812C0 19.0579 0.629625 19.6875 1.40625 19.6875H9.84375C10.6204 19.6875 11.25 19.0579 11.25 18.2812Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ClickOn
