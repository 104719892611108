import { SVGProps } from 'react'
function CheckSquare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={36}
      height={36}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M14.83 23.853a1.278 1.278 0 002.077 0l8.461-11.732a.323.323 0 00-.26-.51h-1.885c-.41 0-.8.196-1.04.534l-6.313 8.759-2.86-3.97a1.285 1.285 0 00-1.041-.534h-1.885a.323.323 0 00-.26.51l5.005 6.943z'
        fill='currentColor'
      />
      <path
        d='M32.786 1.925H3.215c-.711 0-1.286.574-1.286 1.286v29.57c0 .712.575 1.287 1.286 1.287h29.571c.712 0 1.286-.575 1.286-1.286V3.21c0-.712-.575-1.286-1.286-1.286zm-1.607 29.25H4.822V4.818H31.18v26.357z'
        fill='currentColor'
      />
    </svg>
  )
}

export default CheckSquare
