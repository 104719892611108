import { SVGProps } from 'react'
function List(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M15.143 2.287H4.715a.143.143 0 00-.143.143v1c0 .078.064.143.143.143h10.428a.143.143 0 00.143-.143v-1a.143.143 0 00-.143-.143zm0 5.071H4.715a.143.143 0 00-.143.143v1c0 .079.064.143.143.143h10.428a.143.143 0 00.143-.143v-1a.143.143 0 00-.143-.143zm0 5.072H4.715a.143.143 0 00-.143.143v1c0 .078.064.142.143.142h10.428a.143.143 0 00.143-.143v-1a.143.143 0 00-.143-.142zM.715 2.93a1 1 0 102 0 1 1 0 00-2 0zM.715 8a1 1 0 102 0 1 1 0 00-2 0zm0 5.072a1 1 0 102 0 1 1 0 00-2 0z'
        fill='currentColor'
      />
    </svg>
  )
}

export default List
