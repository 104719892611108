import { SVGProps } from 'react'
function RechargeDivider(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path stroke='currentColor' d='M8 2v12M2 8h12' />
    </svg>
  )
}

export default RechargeDivider
