import { SVGProps } from 'react'
function LoginNormal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={54}
      height={46}
      viewBox='0 0 54 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M11.546 23.603l1.965 1.833 8.321-9.157-8.322-9.161-1.964 1.833 5.425 5.974H0v2.707h16.971l-5.425 5.97z'
        fill='currentColor'
      />
      <path
        d='M53.874 42.559l-4.071-12.541V3.371c0-1.385-1.114-2.514-2.48-2.514H7.825c-1.365 0-2.48 1.13-2.48 2.514v7.533h2.671v-7.34h39.116v25.317H8.016v-6.6h-2.67v7.733L1.273 42.56a2.545 2.545 0 00.348 2.258 2.484 2.484 0 002.01 1.04H51.52c.794 0 1.545-.39 2.01-1.04.462-.65.592-1.497.344-2.258zm-35.564.591l1.24-4.4h16.048l1.24 4.4H18.31zm21.306 0l-2.003-7.107H17.536l-2.003 7.107H3.896L7.65 31.588h39.852l3.755 11.562H39.616z'
        fill='currentColor'
      />
      <path
        d='M26.369 22.818a6.496 6.496 0 01-4.16-1.49l-1.678 2.108a9.242 9.242 0 005.838 2.089c5.124 0 9.294-4.227 9.294-9.42 0-5.194-4.17-9.416-9.294-9.416-2.16 0-4.266.77-5.93 2.165l1.706 2.084a6.577 6.577 0 014.224-1.543c3.65 0 6.623 3.013 6.623 6.713 0 3.701-2.972 6.71-6.623 6.71z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LoginNormal
