import { useIsMounted } from '@invisible/hooks/use-is-mounted'
import { Avatar } from '@invisible/ui/avatar'
import { Text } from '@invisible/ui/text'
import { purple, styled } from '@invisible/ui/themes'
import { FC } from 'react'
import { Flex } from 'rebass'

import { DropMenu } from './Menu'

const Greeting = styled(Text)`
  margin-right: 20px;
  &,
  a,
  a:visited,
  a:hover {
    color: ${purple(1)};
  }
  a {
    text-decoration: underline;
  }
`

export interface ProfileMenuProps {
  sessionLoading: boolean
  session?: { user?: { image?: string | null; name?: string | null } } | null
  onLoginClick: VoidFunction
  onLogoutClick: VoidFunction
}
export const ProfileMenu: FC<ProfileMenuProps> = ({
  session,
  onLogoutClick,
  onLoginClick,
  sessionLoading,
}) => {
  const isMounted = useIsMounted()
  if (!isMounted || sessionLoading) return <Greeting>Loading...</Greeting>
  return (
    <Flex pr={2} alignItems='center'>
      {' '}
      {!session?.user ? (
        <Greeting>
          <a href='#' onClick={onLoginClick}>
            Sign in
          </a>
        </Greeting>
      ) : (
        <>
          <Greeting>Hello {session.user.name?.split(' ')[0]}</Greeting>
          <DropMenu
            rootComponent={() => <Avatar size={32} src={session?.user?.image ?? undefined} />}>
            <a onClick={onLogoutClick}>Log out</a>
          </DropMenu>
        </>
      )}
    </Flex>
  )
}
